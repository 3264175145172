import { Signup } from 'views/auth/signup';
import { SignupSpecial } from 'views/auth/signupspecial';
import { SignupUser } from 'views/auth/signupuser';
import { SignupT365 } from 'views/auth/signupt365';
import { Signin } from 'views/auth/signin';
import { ForgotPassword } from 'views/auth/forgotpassword';
import { ResetPassword } from 'views/auth/resetpassword';
import { MagicSignin } from 'views/auth/magic';

const Routes = [
  {
    path: '/master',
    view: Signin,
    layout: 'auth',
    title: 'Sign in to Optimise My Day'
  },
  {
    path: '/signup',
    view: Signup,
    layout: 'auth',
    title: 'Sign up to Optimise My Day'
  },
  {
    path: '/signupspecial',
    view: SignupSpecial,
    layout: 'auth',
    title: 'SPECIAL - Sign up to Optimise My Day'
  },
  {
    path: '/signupt365',
    view: SignupT365,
    layout: 'auth',
    title: 'Sign up to Optimise My Day - 1 Year Trial'
  },
  {
    path: '/signup/user',
    view: SignupUser,
    layout: 'auth',
    title: 'Sign up to Optimise My Day'
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'Welcome to Optimise My Day'
  },
  {
    path: '/magic',
    view: MagicSignin,
    layout: 'auth',
    title: 'Sign In'
  },
  {
    path: '/forgotpassword',
    view: ForgotPassword,
    layout: 'auth',
    title: 'Forgot Your Password?'
  },
  {
    path: '/resetpassword',
    view: ResetPassword,
    layout: 'auth',
    title: 'Reset Your Password'
  },
]

export default Routes;