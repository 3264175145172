/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useState, useContext, useEffect} from 'react';
import { Card, Table, Grid, Animate, Feedback, useAPI, Button } from 'components/lib';
import moment from 'moment';
import ReactGA from 'react-ga';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { makeStyles } from '@material-ui/core/styles';

import { data } from '../../lib/data_reference';

let current_date = moment();
let currentDay = current_date.format('YYYY-MM-DD');
let selectedDay = '';

export function TheQmMonthChart(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  // to validate...
  const user = useAPI('/api/user');

  if(selectedDay)
  {
    currentDay = selectedDay;
  }

  let [select_date, setCurrentDate] = useState(currentDay.replace('T',' '));

  const theqmchart = useAPI(`/api/theqmmonth/get/${select_date}`);

  const useStyles = makeStyles({
    root: {
      height: 48,
      fontSize: '2em'
    },
    qm_wood : {
      backgroundColor: '#00FF00'
    },
    qm_fire : {
      backgroundColor: '#FF0000'
    },
    qm_water : {
      backgroundColor: '#0000FF'
    },
    qm_earth : {
      backgroundColor: '#E1A95F'
    },
    qm_metal : {
      backgroundColor: '#46473E'
    },
    qm_wood_text : {
      color: '#00FF00'
    },
    qm_fire_text : {
      color: '#FF0000'
    },
    qm_water_text : {
      color: '#0000FF'
    },
    qm_earth_text : {
      color: '#E1A95F'
    },
    qm_metal_text : {
      color: '#46473E'
    },
    qm_table : {
      border: '1px solid #000',      
      width: '100%',
      borderSpacing: '0px',
      borderCollapse: 'separate'
    },
    qm_table_cell_outside : {
      borderTop: '0px solid #000',
      borderLeft: '0px solid #000',
      borderRight: '0px solid #000',
      padding: '10px',
      textAlign: 'center',
    },
    qm_table_cell : {
      border: '1px solid #000',
      width: '33%',
      padding: '10px',
      textAlign: 'center',
    },
    qm_table_cell_table : {
      border: '0px solid #000',      
      width: '100%',
      borderSpacing: '0px',
      borderCollapse: 'separate',
      textAlign: 'center',
      height: '120px',
      marginTop: '10px !important',
      marginBottom: '10px !important',
    },
  });
  const classes = useStyles();

  function setDate(event)
  {
    selectedDay = event.target.value;
  }

  function showQmChart()
  {
    if(selectedDay)
    {
      setCurrentDate(selectedDay);
    }
  }

  return (
    <Animate type='pop'>
      <Card title={'QM Month Chart for: ' + select_date} loading={ theqmchart.loading } float={true}>
        <Grid cols='3'>
          <div></div>
        </Grid>
          <TextField
            id="date"
            label="Select Date"
            type="date"
            defaultValue={currentDay}
            className={classes.root}
            variant="outlined"
            size="medium"
            onChange={ setDate }
          />
          &nbsp;&nbsp;&nbsp;
          <Button text='Display' action={ showQmChart } />&nbsp;&nbsp;&nbsp;
      </Card>
      <Card loading={theqmchart?.loading}>
        { (theqmchart?.data) && 
        <Table
          mobileDisable = { true }
          center={true}
          data={ 
            {
              header: [
                { name: 'month', title: 'Month', sort: false },
                { name: 'year', title: 'Year', sort: false }
              ],
              body:  [
                {
                  month: theqmchart.data.qm_month.gz_month,
                  year: theqmchart.data.qm_month.gz_year
                }
              ]
            }
          }/>
        }
        { (theqmchart?.data) && 
        <Table
          mobileDisable = { true }
          center={true}
          data={ 
            {
              header: [
                { name: 'envoy', title: 'Envoy', sort: false },
                { name: 'horse_star', title: 'Horse Star', sort: false },
                { name: 'lead_star', title: 'Lead Star', sort: false },
                { name: 'lead_stem', title: 'Lead Stem', sort: false },
                { name: 'ju_type', title: 'JU Type', sort: false },
                { name: 'ju_number', title: 'JU Number', sort: false }
              ],
              body:  [
                {
                  envoy: theqmchart.data.qm_month.envoy,
                  horse_star: theqmchart.data.qm_month.horse_star,
                  lead_star: theqmchart.data.qm_month.lead_star,
                  lead_stem: theqmchart.data.qm_month.lead_stem,
                  ju_type: theqmchart.data.qm_month.ju_type,
                  ju_number: theqmchart.data.qm_month.ju_number,
                }
              ]
            }
          }/>
        }
      </Card>
      { (theqmchart?.data) && 
        <Card>
      <table className={classes.qm_table}>
        <tbody>
          <tr>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_wood}`}>
              &nbsp;
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_wood}`}>
              巳
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_fire}`}>
              午
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_earth}`}>
              未
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_earth}`}>
             &nbsp;
            </td>
          </tr>
          <tr>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_wood}`}>
              辰
            </td>
            <td className={classes.qm_table_cell}>
              <table className={classes.qm_table_cell_table}>
                <tbody>
                  <tr>
                    <td>SE (巽)</td>
                    <td>{data.reference_interaction_icon[data.reference_element_interaction[data.reference_palace_element['se'] + data.reference_door_element[theqmchart.data.qm_month.se_door]]]}</td>
                    <td>{theqmchart.data.qm_month.se_loushu}</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.se_heaven_stem]]]}`}>{theqmchart.data.qm_month.se_heaven_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.se_heaven_stem_hidden]]]}`}>{theqmchart.data.qm_month.se_heaven_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.se_deity}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.se_star}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.se_earth_stem]]]}`}>{theqmchart.data.qm_month.se_earth_stem}</span></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.se_door}</td>
                  </tr>
                </tbody>
              </table>
              <div>{theqmchart.data.special_formation.se.join(',')}</div>
            </td>
            <td className={classes.qm_table_cell}>
              <table className={classes.qm_table_cell_table}>
                  <tbody>
                    <tr>
                      <td>S (离)</td>
                      <td>{data.reference_interaction_icon[data.reference_element_interaction[data.reference_palace_element['s'] + data.reference_door_element[theqmchart.data.qm_month.s_door]]]}</td>
                      <td>{theqmchart.data.qm_month.s_loushu}</td>
                    </tr>
                    <tr>
                      <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.s_heaven_stem]]]}`}>{theqmchart.data.qm_month.s_heaven_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.s_heaven_stem_hidden]]]}`}>{theqmchart.data.qm_month.s_heaven_stem_hidden}</small></td>
                      <td>&nbsp;</td>
                      <td>{theqmchart.data.qm_month.s_deity}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>{theqmchart.data.qm_month.s_star}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.s_earth_stem]]]}`}>{theqmchart.data.qm_month.s_earth_stem}</span></td>
                      <td>&nbsp;</td>
                      <td>{theqmchart.data.qm_month.s_door}</td>
                    </tr>
                  </tbody>
                </table>
                <div>{theqmchart.data.special_formation.s.join(',')}</div>
            </td>
            <td className={classes.qm_table_cell}>
              <table className={classes.qm_table_cell_table}>
                  <tbody>
                    <tr>
                      <td>SW (坤)</td>
                      <td>{data.reference_interaction_icon[data.reference_element_interaction[data.reference_palace_element['sw'] + data.reference_door_element[theqmchart.data.qm_month.sw_door]]]}</td>
                      <td>{theqmchart.data.qm_month.sw_loushu}</td>
                    </tr>
                    <tr>
                      <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.sw_heaven_stem]]]}`}>{theqmchart.data.qm_month.sw_heaven_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.sw_heaven_stem_hidden]]]}`}>{theqmchart.data.qm_month.sw_heaven_stem_hidden}</small></td>
                      <td>&nbsp;</td>
                      <td>{theqmchart.data.qm_month.sw_deity}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>{theqmchart.data.qm_month.sw_star}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.sw_earth_stem]]]}`}>{theqmchart.data.qm_month.sw_earth_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.sw_earth_stem_hidden]]]}`}>{theqmchart.data.qm_month.sw_earth_stem_hidden}</small></td>
                      <td>&nbsp;</td>
                      <td>{theqmchart.data.qm_month.sw_door}</td>
                    </tr>
                  </tbody>
                </table>
                <div>{theqmchart.data.special_formation.sw.join(',')}</div>
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_earth}`}>
              申
            </td>
          </tr>
          <tr>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_wood}`}>
              卯
            </td>
            <td className={classes.qm_table_cell}>
              <table className={classes.qm_table_cell_table}>
                <tbody>
                  <tr>
                    <td>E (震)</td>
                    <td>{data.reference_interaction_icon[data.reference_element_interaction[data.reference_palace_element['e'] + data.reference_door_element[theqmchart.data.qm_month.e_door]]]}</td>
                    <td>{theqmchart.data.qm_month.e_loushu}</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.e_heaven_stem]]]}`}>{theqmchart.data.qm_month.e_heaven_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.e_heaven_stem_hidden]]]}`}>{theqmchart.data.qm_month.e_heaven_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.e_deity}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.e_star}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.e_earth_stem]]]}`}>{theqmchart.data.qm_month.e_earth_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.e_earth_stem_hidden]]]}`}>{theqmchart.data.qm_month.e_earth_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.e_door}</td>
                  </tr>
                </tbody>
              </table>
              <div>{theqmchart.data.special_formation.e.join(',')}</div>
            </td>
            <td className={classes.qm_table_cell}>
              <div>&nbsp;</div>
            </td>
            <td className={classes.qm_table_cell}>
              <table className={classes.qm_table_cell_table}>
                <tbody>
                  <tr>
                    <td>W (兑)</td>
                    <td>{data.reference_interaction_icon[data.reference_element_interaction[data.reference_palace_element['w'] + data.reference_door_element[theqmchart.data.qm_month.w_door]]]}</td>
                    <td>{theqmchart.data.qm_month.w_loushu}</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.w_heaven_stem]]]}`}>{theqmchart.data.qm_month.w_heaven_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.w_heaven_stem_hidden]]]}`}>{theqmchart.data.qm_month.w_heaven_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.w_deity}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.w_star}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.w_earth_stem]]]}`}>{theqmchart.data.qm_month.w_earth_stem}</span></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.w_door}</td>
                  </tr>
                </tbody>
              </table>
              <div>{theqmchart.data.special_formation.w.join(',')}</div>
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_metal}`}>
              酉
            </td>
          </tr>
          <tr>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_earth}`}>
              寅
            </td>
            <td className={classes.qm_table_cell}>
              <table className={classes.qm_table_cell_table}>
                <tbody>
                  <tr>
                    <td>NE (艮)</td>
                    <td>{data.reference_interaction_icon[data.reference_element_interaction[data.reference_palace_element['ne'] + data.reference_door_element[theqmchart.data.qm_month.ne_door]]]}</td>
                    <td>{theqmchart.data.qm_month.ne_loushu}</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.ne_heaven_stem]]]}`}>{theqmchart.data.qm_month.ne_heaven_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.ne_heaven_stem_hidden]]]}`}>{theqmchart.data.qm_month.ne_heaven_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.ne_deity}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.ne_star}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.ne_earth_stem]]]}`}>{theqmchart.data.qm_month.ne_earth_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.ne_earth_stem_hidden]]]}`}>{theqmchart.data.qm_month.ne_earth_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.ne_door}</td>
                  </tr>
                </tbody>
              </table>
              <div>{theqmchart.data.special_formation.ne.join(',')}</div>
            </td>
            <td className={classes.qm_table_cell}>
              <table className={classes.qm_table_cell_table}>
                <tbody>
                  <tr>
                    <td>N (坎)</td>
                    <td>{data.reference_interaction_icon[data.reference_element_interaction[data.reference_palace_element['n'] + data.reference_door_element[theqmchart.data.qm_month.n_door]]]}</td>
                    <td>{theqmchart.data.qm_month.n_loushu}</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.n_heaven_stem]]]}`}>{theqmchart.data.qm_month.n_heaven_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.n_heaven_stem_hidden]]]}`}>{theqmchart.data.qm_month.n_heaven_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.n_deity}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.n_star}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.n_earth_stem]]]}`}>{theqmchart.data.qm_month.n_earth_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.n_earth_stem_hidden]]]}`}>{theqmchart.data.qm_month.n_earth_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.n_door}</td>
                  </tr>
                </tbody>
              </table>
              <div>{theqmchart.data.special_formation.n.join(',')}</div>
            </td>
            <td className={classes.qm_table_cell}>
              <table className={classes.qm_table_cell_table}>
                <tbody>
                  <tr>
                    <td>NW (乾)</td>
                    <td>{data.reference_interaction_icon[data.reference_element_interaction[data.reference_palace_element['nw'] + data.reference_door_element[theqmchart.data.qm_month.nw_door]]]}</td>
                    <td>{theqmchart.data.qm_month.nw_loushu}</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.nw_heaven_stem]]]}`}>{theqmchart.data.qm_month.nw_heaven_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.nw_heaven_stem_hidden]]]}`}>{theqmchart.data.qm_month.nw_heaven_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.nw_deity}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.nw_star}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><span className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.nw_earth_stem]]]}`}>{theqmchart.data.qm_month.nw_earth_stem}</span>&nbsp;<small className={`${classes[data.reference_element_css[data.reference_stem_element[theqmchart.data.qm_month.nw_earth_stem_hidden]]]}`}>{theqmchart.data.qm_month.nw_earth_stem_hidden}</small></td>
                    <td>&nbsp;</td>
                    <td>{theqmchart.data.qm_month.nw_door}</td>
                  </tr>
                </tbody>
              </table>
              <div>{theqmchart.data.special_formation.nw.join(',')}</div>
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_metal}`}>
              戌
            </td>
          </tr>
          <tr>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_earth}`}>
             &nbsp;
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_earth}`}>
              丑
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_water}`}>
              子
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_metal}`}>
              亥
            </td>
            <td className={`${classes.qm_table_cell_outside} ${classes.qm_metal}`}>
              &nbsp;
            </td>
          </tr>
        </tbody>
      </table>
      </Card>
      }
      <Feedback />
    </Animate>
  );
}
