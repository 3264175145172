/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Image, useAPI } from 'components/lib';
import Astronaut from './images/astronaut.jpg';
import ReactGA from 'react-ga';

export function OnboardingView(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const context = useContext(AuthContext);

  return (
    <Onboarding 
      save
      onFinish='/dashboard'
      views={[
      {
        name: 'Setup Profile',
        description: `Welcome, ${context.user.name}! Please complete your profile.`,
        component: <SetupProfile/>
      }
    ]}/>
  )
}

function SetupProfile(props){
  // fetch
  const authContext = useContext(AuthContext);
  const user = useAPI('/api/user');
  
  return (
    <div>
    { user?.data &&
      <Form 
      buttonText='Save'
      url={ authContext.user.permission === 'owner' ? '/api/account' : '/api/user' }
      method='PATCH'
      data={{
        name: {
          type: 'hidden',
          default: user.data.name,
          required: true,
        },
        email: {
          type: 'hidden',
          default: user.data.email,
          required: true,
        },
        gender: {
          label: 'Gender',
          type: 'select',
          options: [
          { value: 1, label: 'Male' },
          { value: 0, label: 'Female' }
          ],
          required: true,
        },
        birth_date: {
          label: 'Birth Date',
          type: 'date',
          required: true,
          errorMessage: 'Please enter your date of birth'
        },
        birth_time: {
          label: 'Birth Time',
          type: 'time',
          required: true,
          value: '00:00',
          errorMessage: 'Please enter your time of birth. 24Hr:Min'
          }
        }}
        callback={ res => authContext.update({ name: res.data.data.name })}
      />
    }
    </div>
  )
}

// function Welcome(){

//   return (
//     <Fragment>

//       <p>This is an example of the user-onboarding flow.
//         You can replace this with your own content, try out the action
//         in the next step or skip the intro entirely.</p>
        
//       <p>Have fun playing with Optimise My Day!</p>

//       <Image source={ Astronaut }/> 

//     </Fragment>
//   )
// }

// function InviteUsers(props){

//   return (
//     <Form 
//       data={{
//         email: {
//           label: 'Email',
//           type: 'email',
//           required: true,
//         }
//       }}
//       buttonText='Send Invite'
//       url='/api/invite'
//       method='POST'
//     />
//   )
// }
