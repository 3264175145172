/***
*
*   PASSWORD
*   Update the users password
*
**********/

import React, { Fragment } from 'react';
import { Card, Form, AccountNav, Animate } from 'components/lib';
import ReactGA from 'react-ga';

export function Password(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);
 
  return (
    <Fragment>
      <AccountNav/>
      <Animate>
        <Card title='Update Your Password' restrictWidth>
          <Form
            url='/api/user/password'
            method='PATCH'
            buttonText='Save Password'
            data={{
              oldpassword: {
                label: 'Old Password',
                type: 'password',
                required: true
              },
              newpassword: {
                label: 'New Password',
                type: 'password',
                required: true,
                complexPassword: true
              }
            }}
          />
        </Card>
      </Animate>
    </Fragment>
  );
}
