export const Features = [
  [
    { name: 'iCal Service', checked: true },
    { name: 'English, Chinese, Icon Tong Shu Calendar', checked: true },
    { name: 'Online Tong Shu Reference', checked: true },
    { name: 'Tong Shu Quick Daily Overview', checked: true },
    { name: 'Tong Shu Personalise Data', checked: true },
    { name: 'Simplified Calendar - Just Follow', checked: false },
    { name: 'Pro Calendar - Personalise Structure', checked: false },
    { name: 'Pro Calendar - Personalise Hour Structure', checked: false }
  ],
  [
    { name: 'iCal Service', checked: true },
    { name: 'English, Chinese, Icon Tong Shu Calendar', checked: true },
    { name: 'Online Tong Shu Reference', checked: true },
    { name: 'Tong Shu Quick Daily Overview', checked: true },
    { name: 'Tong Shu Personalise Data', checked: true },
    { name: 'Simplified Calendar - Just Follow', checked: true },
    { name: 'Pro Calendar - Personalise Structure', checked: false },
    { name: 'Pro Calendar - Personalise Hour Structure', checked: false }
  ],
  [
    { name: 'iCal Service', checked: true },
    { name: 'English, Chinese, Icon Tong Shu Calendar', checked: true },
    { name: 'Online Tong Shu Reference', checked: true },
    { name: 'Tong Shu Quick Daily Overview', checked: true },
    { name: 'Tong Shu Personalise Data', checked: true },
    { name: 'Simplified Calendar - Just Follow', checked: true },
    { name: 'Pro Calendar - Personalise Structure', checked: true },
    { name: 'Pro Calendar - Personalise Hour Structure', checked: true }
  ]
]
