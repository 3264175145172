/***
*
*   PRICING
*   Modify the PricePlans component with your own features
*
**********/

import React from 'react';
import { Animate, Row, PricePlans } from 'components/lib';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

export function Pricing(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let ref = params.get('ref');

  const cookies = new Cookies();
  if(ref)
  {
    cookies.set('referral_code', ref, { path: '/' });  
  }

  return (
    <Animate type='pop'>
      <Row color='tint' align='center' title='Simple, affordable pricing'>
        <PricePlans />
      </Row>
    </Animate>
  )
}
