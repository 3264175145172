import React, { Fragment, useState, useContext } from 'react';
import { ViewContext, Card, Form, Icon, Button } from 'components/lib';
import Style from './feedback.module.scss';

export function Feedback(props){

  // context & state
  const context = useContext(ViewContext);
  const [showForm, setShowForm] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [rating, setRating] = useState(null);

  // maps
  const icons = ['smile', 'meh', 'frown'];
  const ratings = ['positive', 'neutral', 'negative'];
  const colors = ['#8CC57D', '#F0AA61', '#D95565'];

  function saveRating(rating){

    setRating(rating);
    setShowComments(true);

  }

  return (
    <Fragment>

      { showForm ?
        <Card title='Enjoying Optimise My Day?' shadow className={ Style.feedback }>

          <Button 
            icon='x' 
            color='white' 
            className={ Style.close } 
            size={ 16 }
            action={ e => setShowForm(false) }
          />

          <Fragment>
            <section className={ Style.rating }>
              { icons.map((icon, index) => {

                 return (
                  <Button 
                    key={ index }
                    icon={ icon } 
                    size={ 32 } 
                    color={ index === rating ? colors[index] : 'white' }
                    action={ e => saveRating(index) }
                  />
                 );
               })}
            </section>
            { showComments && 
              <Form 
                data={{
                  rating: {
                  type: 'hidden',
                  value: ratings[rating]
                },
                  comment: {
                  label: `Tell us about your experience`,
                  type: 'textarea',
                }
                }}
                method='POST'
                url='/api/feedback'
                updateOnChange
                buttonText='Send Feedback'
                onChange={ e => { return false }}
                callback={ e => {

                   setShowForm(false);
                   context.notification.show('Thank you, your feedback is most appreciated.', 'success', true, 'toast', 'heart');

                }}
              />
            }
          </Fragment>         
        </Card> :

        <div className={ Style.button } onClick={ e => setShowForm(true) }>
          <Icon image='heart' color='white' />
        </div>

      }
   </Fragment>
  );
}
