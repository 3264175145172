/***
*
*   CONTACT FORM
*   Will send an email notification to you
*
**********/

import React from 'react';
import { Row, ContactForm } from 'components/lib';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

export function Contact(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let ref = params.get('ref');

  const cookies = new Cookies();
  if(ref)
  {
    cookies.set('referral_code', ref, { path: '/' });  
  }

  return(
    <Row title='Contact Us'>
      <ContactForm />
    </Row>
  )
}
