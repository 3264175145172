/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useState, useContext, useEffect} from 'react';
import { Card, Table, Grid, Animate, Feedback, useAPI, Button } from 'components/lib';
import moment from 'moment';
import ReactGA from 'react-ga';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { makeStyles } from '@material-ui/core/styles';

let current_date = moment();
let currentDay = current_date.format('YYYY-MM-DDTHH:mm');
let currentYear = 2023;
let currentGender = 0;
let selectedDay = '';
let selectedYear = '';
let selectedGender = '';

export function The3vvList(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  // to validate...
  const user = useAPI('/api/user');

  let [dob, setCurrentDate] = useState(currentDay.replace('T',' '));
  let [the3vvyear, setCurrentYear] = useState(currentYear);
  let [gender, setCurrentGender] = useState(currentGender);

  const the3vvlist = useAPI(`/api/the3vv/get/${dob}/${gender}/${the3vvyear}`);

  let today_ts = {};

  const useStyles = makeStyles({
    root: {
      height: 48,
      fontSize: '2em'
    },
  });
  const classes = useStyles();

  function setDate(event)
  {
    selectedDay = event.target.value;
  }

  function setGender(event)
  {
    selectedGender = event.target.value;
    gender = event.target.value;
    currentGender = event.target.value;
    console.log(gender);
  }

  function setYear(event)
  {
    selectedYear = event.target.value;
    the3vvyear = event.target.value;
    currentYear = event.target.value;
    console.log(the3vvyear);
  }

  function show3VV()
  {
    if(selectedDay && selectedGender != '' && selectedYear != '')
    {
      dob = selectedDay.replace('T',' ');
      setCurrentDate(dob);
      setCurrentGender(gender);
      setCurrentYear(the3vvyear);
    }
  }

  return (
    <Animate type='pop'>
      <Card title={the3vvyear + ' 3VD Day for: ' + dob + ' (' + (gender==1?'Male':'Female') + ')'} loading={ today_ts.loading } float={true}>
        <Grid cols='3'>
          <div></div>
        </Grid>
          <TextField
            id="date"
            label="Date of Birth & Time"
            type="datetime-local"
            defaultValue={currentDay}
            className={classes.root}
            variant="outlined"
            size="medium"
            onChange={ setDate }
          />
          &nbsp;&nbsp;&nbsp;
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Gender</InputLabel>
            <Select
              native
              onChange={setGender}
              label="Gender"
              inputProps={{
                name: 'gender',
                id: 'outlined-age-native-simple',
              }}
            >
              <option>Please Select</option>
              <option value={0}>Female</option>
              <option value={1}>Male</option>
            </Select>
          </FormControl>          
          &nbsp;&nbsp;&nbsp;
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Year</InputLabel>
            <Select
              native
              onChange={setYear}
              label="Year"
              inputProps={{
                name: 'year',
                id: 'outlined-age-native-simple',
              }}
            >
              <option>Please Select</option>
              <option value={2025}>2025</option>
              <option value={2024}>2024</option>
              <option value={2023}>2023</option>
              <option value={2022}>2022</option>
              <option value={2021}>2021</option>
              <option value={2020}>2020</option>
            </Select>
          </FormControl>          
          &nbsp;&nbsp;&nbsp;
          <Button text='Display' action={ show3VV } />&nbsp;&nbsp;&nbsp;
      </Card>
      <Card loading={the3vvlist?.loading}>
        <Table
          data={ 
            {
              header: [
              { name: 'the3vv', title: '3 Victory Day', sort: false },
              { name: 'day', title: 'Day', sort: false },
              { name: 'direction', title: 'Direction', sort: false }
            ],
            body: the3vvlist.data
          }
        }
        />
      </Card>
      <Feedback />
    </Animate>
  );
}
