/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment, useState } from 'react';
import { Row, Link, Grid, List, Hero, Features, Testimonial } from 'components/lib';
import ImgDashboard from './images/dashboard.gif';
import ImgKyleGawley from './images/sh.jpg';
import ReactGA from 'react-ga';
import ModalVideo from 'react-modal-video'
import Cookies from 'universal-cookie';

export function Home(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);
  
  const [isIntroOpen, setIntroOpen] = useState(false)

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let ref = params.get('ref');

  const cookies = new Cookies();
  if(ref)
  {
    cookies.set('referral_code', ref, { path: '/' });  
  }

  return(
    <Fragment>
      <ModalVideo channel='youtube' autoplay="1" isOpen={isIntroOpen} videoId="IAYbPjRqc8s" onClose={() => setIntroOpen(false)} />
      <Hero
        title='Make full use of your day while still using your favourite Calendar App.'
        tagline='Optimise My Day is a Metaphysic Tong Shu iCal system that you will find the information you need to plan your day regardless you are a professional Date Selection user or a newbie.'
        image={ ImgDashboard }
      />

      <center>
        <br/><br/>
        <button className="btn-primary" onClick={()=> setIntroOpen(true)}>View Introduction Video</button>
      </center>

      <Row title='Features' color='white'>
        <Features />
      </Row>

      <Row color='brand'>
        <Testimonial
          text="OMD saved me trouble of flipping through my tong shu and my planner! I won't miss any powerful dates for my important meeting any more!"
          author='Lim Siong Huat, Business Owner'
          image={ ImgKyleGawley }
        />
      </Row>

      <Row title='Why Use Optimise My Day?' align='center'>
        <div>
          <p>As a Metaphysic student for years, the main problem I have is to constantly flipping between the Tong Shu, QiMen apps to find my 3 Victory days, QiMen structure or best day for my major activities.</p>
          <p>However, there are times where you are not able to pull out so many thing at one go, especially in front of customers. I have a good story to share. I was suppose to have a liquidation meeting and when my laywer ask if I am ok with 1 October. I take a quick look at my phone calendar and say "Yes! I am Free that day!". But when I reach home, I realised 2 Oct is my Divine Force Day, a day where my personal qi is the most powerful! However, the date can't be changed any more. If I have all the structure on my finger tips, I would have planned it differently instantly!</p>
          <p>And this is the reason how "Optimise My Day" is created! It aims to work with any iCal system so that you can plan with your favourite calendar system. With Widgets on iPhone and Android, you can have a special screen to see the schedule easily.</p>
        </div>
      </Row>

      <Row title='Future Plan' align='center' color="brand">
        <div>
          <p>More features will be added to the system. Sign up for the PRO plan and get access to them and future new features as they roll out over this year.</p>
        </div>
        <br/>
        <div style={{color:"#fff"}} align='left'>
        <Grid cols='2'>
          <List items={[
            'Dong Gong Day Rating',
            'Daily Fying Star',
            'Feng Shui Activation Dates',
            'Online QiMen Chart Generation',
            'And More ...'
            ]}/>
          <List items={[
            'Major Astrology Events',
            'Personal Astrology Transit',
            'Online Bazi Chart Generation',
            'Online Astro Chart'
            ]}/>
        </Grid>
        </div>
        <br/>
        <div>
          <p>Most imporant for PRO plan user, you can request for the feature that you need! It will be gathered and put into consideration to roll out.</p>
        </div>
      </Row>

      <Row title='Start Optimising Your Day Today' align='center'>
        <div>
          Maximise your day with the power of Metaphysic at your finger tips!
        </div><br/><br/>
        <Link btn url='/signup' text='Sign Up Now'/>
      </Row>
    </Fragment>
  );
}
