import { Dashboard } from 'views/dashboard/dashboard';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';
import { TongShu } from 'views/calendar/tongshu';
import { The3vvList } from 'views/tool/the3vvlist';
import { TheQmYearChart } from 'views/tool/theqmyearchart';
import { TheQmMonthChart } from 'views/tool/theqmmonthchart';
import { TheQmDayChart } from 'views/tool/theqmdaychart';
import { TheQmHourChart } from 'views/tool/theqmhourchart';
import { ReferralDashboard } from 'views/referral/referraldashboard';

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/the3vvlist',
    view: The3vvList,
    layout: 'app',
    permission: 'user',
    title: 'The 3VV List'
  },
  {
    path: '/theqmhourchart',
    view: TheQmHourChart,
    layout: 'app',
    permission: 'user',
    title: 'The QM Hour Chart'
  },
  {
    path: '/theqmdaychart',
    view: TheQmDayChart,
    layout: 'app',
    permission: 'user',
    title: 'The QM Day Chart'
  },
  {
    path: '/theqmmonthchart',
    view: TheQmMonthChart,
    layout: 'app',
    permission: 'user',
    title: 'The QM Month Chart'
  },
  {
    path: '/theqmyearchart',
    view: TheQmYearChart,
    layout: 'app',
    permission: 'user',
    title: 'The QM Year Chart'
  },
  {
    path: '/referraldashboard',
    view: ReferralDashboard,
    layout: 'app',
    permission: 'user',
    title: 'Referral List'
  },
  {
    path: '/tongshu',
    view: TongShu,
    layout: 'app',
    permission: 'user',
    title: 'Tong Shu'
  },
  {
    path: '/tongshu/:date',
    view: TongShu,
    layout: 'app',
    permission: 'user',
    title: 'Tong Shu'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
]

export default Routes;
