/***
*
*   FEATURES
*   Feature list for use on home/landing pages
*
**********/

import React from 'react';
import { Grid, Icon } from 'components/lib';
import Style from './features.module.scss';

export function Features(props){

  return(
    <div className={ Style.features }>
      <Grid cols={ 3 }>

        <div>
        <Icon image='mail' size={ 16 } />
        <h3>Any Calendar System With iCal</h3>
        <p>Use your favourite calendar or work calendar as long as it supports iCal, example: Outlook Online/Desktop, Gmail, Yahoo</p>
        </div>

        <div>
        <Icon image='calendar' size={ 16 } />
        <h3>Multiple Calendar</h3>
        <p>There are multiple calander for you to choose from, Tong Shu, Professional Day, Professional Hour or Simplfied. Just add those needed.</p>
        </div>

        <div>
        <Icon image='globe' size={ 16 } />
        <h3>Online Reference</h3>
        <p>Beside access to the data in your calendar, you can also refer to the full data from the dashboard.</p>
        </div>

        <div>
        <Icon image='tag' size={ 16 } />
        <h3>Tong Shu - Icon, English and Chinese</h3>
        <p>Select the language you are looking for and load it into your calendar.</p>
        </div>

        <div>
        <Icon image='eye' size={ 16 } />
        <h3>Tong Shu - Quick Daily Overview</h3>
        <p>A summary of each day allows you to have a quick view of the day. Details can be found by clicking the event details and full details is in the link to the dashboard page.</p>
        </div>

        <div>
        <Icon image='eye' size={ 16 } />
        <h3>Tong Shu - Personalise Data</h3>
        <p>Quick way to find out what this day means to you. Is it a FAN YIN, FU YIN, 7 Killing Day or Rob Money Day?</p>
        </div>

        <div>
        <Icon image='edit-3' size={ 16 } />
        <h3>Professional Calendar - Personalise Structure</h3>
        <p>Based on personal data, this calendar will show you the struture important to you or in general, such as 3 Victory days, Green Dragon Return, Personal Lucky Day and more.</p>
        </div>

        <div>
        <Icon image='clock' size={ 16 } />
        <h3>Professional Calendar - Personalise Hour Structure</h3>
        <p>By adding this calendar, you can see all the stucture important to you or in general and select a suitable timing for your activity at the same place.</p>
        </div>

        <div>
        <Icon image='navigation' size={ 16 } />
        <h3>Simplifed Calendar - Just Follow</h3>
        <p>Not familar with Metaphysics? We have summaries to simple instruction to help you in your daily decisions when planning your activities. Know when is your lucky day, sales day or just stay put day.</p>
        </div>

      </Grid>
    </div>
  );
}
