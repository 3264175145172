/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import React from 'react';
import { Animate, Row, Content, Link } from 'components/lib'
import Style from './footer.module.scss';

export function Footer(props){

  return (
    <Animate type='slideup'>
      <footer className={ Style.footer }>
        <Row>
          <Content>

            <nav>
            <Link url='/' text='Home'/>
            <Link url='/pricing' text='Pricing'/>
            <Link url='/signin' text='Sign in'/>
            <Link url='/signup' text='Sign up'/>
            <Link url='/terms' text='Terms'/>
            <Link url='/privacy' text='Privacy'/>
            <Link url='/contact' text='Contact'/>
            </nav>

            <span>{ `Copyright © Optimise My Day ${new Date().getFullYear()}`}</span>
            <span>DISCLAIMER: All the translated terms are not own by ODM. Some are from various Masters such as Dato Joey Yap, Master Jo Ching and so on.</span>
            <address></address>

          </Content>
        </Row>
      </footer>
    </Animate>
  );
}
