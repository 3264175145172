export const data = {

  reference_60_jz_vs_hexagram : {
    "甲午" : {"image_url":"/img/yijing/yijing_a01.png", "element":"金", "name":"Heaven", "name_ch":"乾为天", "description":"", "description_ch":"自强不息"},
    "癸巳" : {"image_url":"/img/yijing/yijing_a43.png", "element":"金", "name":"Elimiating", "name_ch":"泽天夬", "description":"", "description_ch":"决而能和"},
    "辛巳" : {"image_url":"/img/yijing/yijing_a14.png", "element":"木", "name":"Great Reward", "name_ch":"火天大有", "description":"", "description_ch":"顺天依时"},
    "己巳" : {"image_url":"/img/yijing/yijing_a34.png", "element":"木", "name":"Great Strength", "name_ch":"雷天大壮", "description":"", "description_ch":"壮勿妄动"},
    "丁巳" : {"image_url":"/img/yijing/yijing_a09.png", "element":"火", "name":"Small Livestock", "name_ch":"风天小畜", "description":"", "description_ch":"蓄养待进"},
    "乙巳" : {"image_url":"/img/yijing/yijing_a05.png", "element":"火", "name":"Waiting", "name_ch":"水天需", "description":"", "description_ch":"守正待机"},
    "壬辰" : {"image_url":"/img/yijing/yijing_a26.png", "element":"水", "name":"Big Livestock", "name_ch":"山天大畜", "description":"", "description_ch":"止而不止"},
    "庚辰" : {"image_url":"/img/yijing/yijing_a11.png", "element":"水", "name":"Unity", "name_ch":"地天泰", "description":"", "description_ch":"应时而变"},
    "戊辰" : {"image_url":"/img/yijing/yijing_a10.png", "element":"金", "name":"Tread", "name_ch":"天泽履", "description":"", "description_ch":"脚踏实地"},
    "丙辰" : {"image_url":"/img/yijing/yijing_a58.png", "element":"金", "name":"Marsh", "name_ch":"兑为泽", "description":"", "description_ch":"刚内柔外"},
    "甲辰" : {"image_url":"/img/yijing/yijing_a38.png", "element":"木", "name":"Opposition", "name_ch":"火泽睽", "description":"", "description_ch":"异中求同"},
    "癸卯" : {"image_url":"/img/yijing/yijing_a54.png", "element":"木", "name":"Marrying Maiden", "name_ch":"雷泽归妹", "description":"", "description_ch":"立家兴业"},
    "辛卯" : {"image_url":"/img/yijing/yijing_a61.png", "element":"火", "name":"Sincerity", "name_ch":"风泽中孚", "description":"", "description_ch":"诚信立身"},
    "己卯" : {"image_url":"/img/yijing/yijing_a60.png", "element":"火", "name":"Regulate", "name_ch":"水泽节", "description":"", "description_ch":"万物有节"},
    "丁卯" : {"image_url":"/img/yijing/yijing_a41.png", "element":"水", "name":"Decreasing", "name_ch":"山泽损", "description":"", "description_ch":"损益制衡"},
    "乙卯" : {"image_url":"/img/yijing/yijing_a19.png", "element":"水", "name":"Arriving", "name_ch":"地泽临", "description":"", "description_ch":"教民保民"},
    "壬寅" : {"image_url":"/img/yijing/yijing_a13.png", "element":"金", "name":"Fellowship", "name_ch":"天火同人", "description":"", "description_ch":"上下和同"},
    "庚寅2" : {"image_url":"/img/yijing/yijing_a49.png", "element":"金", "name":"Reform", "name_ch":"泽火革", "description":"", "description_ch":"顺天应人"},
    "庚寅" : {"image_url":"/img/yijing/yijing_a30.png", "element":"木", "name":"Fire", "name_ch":"离为火", "description":"", "description_ch":"附和依托"},
    "戊寅" : {"image_url":"/img/yijing/yijing_a55.png", "element":"木", "name":"Abundance", "name_ch":"雷火丰", "description":"", "description_ch":"日中则斜"},
    "丙寅" : {"image_url":"/img/yijing/yijing_a37.png", "element":"火", "name":"Family", "name_ch":"风火家人", "description":"", "description_ch":"诚威治业"},
    "甲寅" : {"image_url":"/img/yijing/yijing_a63.png", "element":"火", "name":"Accompliashed", "name_ch":"水火既济", "description":"", "description_ch":"盛极将衰"},
    "癸丑" : {"image_url":"/img/yijing/yijing_a22.png", "element":"水", "name":"Beauty", "name_ch":"山火贲", "description":"", "description_ch":"饰外扬质"},
    "辛丑" : {"image_url":"/img/yijing/yijing_a36.png", "element":"水", "name":"Dimming Light", "name_ch":"地火明夷", "description":"", "description_ch":"晦而转明"},
    "己丑" : {"image_url":"/img/yijing/yijing_a25.png", "element":"金", "name":"Without Wrongdoing", "name_ch":"天雷无妄", "description":"", "description_ch":"无妄而得"},
    "丁丑" : {"image_url":"/img/yijing/yijing_a17.png", "element":"金", "name":"Following", "name_ch":"泽雷随", "description":"", "description_ch":"随时变通"},
    "乙丑" : {"image_url":"/img/yijing/yijing_a21.png", "element":"木", "name":"Biting", "name_ch":"火雷噬嗑", "description":"", "description_ch":"刚柔相济"},
    "壬子" : {"image_url":"/img/yijing/yijing_a51.png", "element":"木", "name":"Thunder", "name_ch":"震为雷", "description":"", "description_ch":"临危不乱"},
    "庚子" : {"image_url":"/img/yijing/yijing_a42.png", "element":"火", "name":"Increasing", "name_ch":"风雷益", "description":"", "description_ch":"损上益下"},
    "戊子" : {"image_url":"/img/yijing/yijing_a03.png", "element":"火", "name":"Beginning", "name_ch":"水雷屯", "description":"", "description_ch":"起始维艰"},
    "丙子" : {"image_url":"/img/yijing/yijing_a27.png", "element":"水", "name":"Nourish", "name_ch":"山雷颐", "description":"", "description_ch":"纯正以养"},
    "甲子2" : {"image_url":"/img/yijing/yijing_a24.png", "element":"水", "name":"Returning", "name_ch":"地雷复", "description":"", "description_ch":"寓动于顺"},
    "甲午2" : {"image_url":"/img/yijing/yijing_a44.png", "element":"金", "name":"Meeting", "name_ch":"天风姤", "description":"", "description_ch":"天下有风"},
    "丙午" : {"image_url":"/img/yijing/yijing_a28.png", "element":"金", "name":"Great Exeeding", "name_ch":"泽风大过", "description":"", "description_ch":"非常行动"},
    "戊午" : {"image_url":"/img/yijing/yijing_a50.png", "element":"木", "name":"The Cauldron", "name_ch":"火风鼎", "description":"", "description_ch":"稳重图变"},
    "庚午" : {"image_url":"/img/yijing/yijing_a32.png", "element":"木", "name":"Consistency", "name_ch":"雷风恒", "description":"", "description_ch":"恒心有成"},
    "壬午" : {"image_url":"/img/yijing/yijing_a57.png", "element":"火", "name":"Wind", "name_ch":"巽为风", "description":"", "description_ch":"谦逊受益"},
    "乙未" : {"image_url":"/img/yijing/yijing_a48.png", "element":"火", "name":"Well", "name_ch":"水风井", "description":"", "description_ch":"求贤若渴"},
    "丁未" : {"image_url":"/img/yijing/yijing_a18.png", "element":"水", "name":"Poison", "name_ch":"山风蛊", "description":"", "description_ch":"振疲起衰"},
    "己未" : {"image_url":"/img/yijing/yijing_a46.png", "element":"水", "name":"Rising", "name_ch":"地风升", "description":"", "description_ch":"柔顺谦虚"},
    "辛未" : {"image_url":"/img/yijing/yijing_a06.png", "element":"金", "name":"Litigation", "name_ch":"天水讼", "description":"", "description_ch":"慎争戒讼"},
    "癸未" : {"image_url":"/img/yijing/yijing_a47.png", "element":"金", "name":"Trap", "name_ch":"泽水困", "description":"", "description_ch":"困境求通"},
    "甲申" : {"image_url":"/img/yijing/yijing_a64.png", "element":"木", "name":"Not Yet Accomplished", "name_ch":"火水未济", "description":"", "description_ch":"事业未竟"},
    "丙申" : {"image_url":"/img/yijing/yijing_a40.png", "element":"木", "name":"Relief", "name_ch":"雷水解", "description":"", "description_ch":"柔道致治"},
    "戊申" : {"image_url":"/img/yijing/yijing_a59.png", "element":"火", "name":"Dispersing", "name_ch":"风水涣", "description":"", "description_ch":"拯救涣散"},
    "庚申" : {"image_url":"/img/yijing/yijing_a29.png", "element":"火", "name":"Water", "name_ch":"坎为水", "description":"", "description_ch":"行险用险"},
    "庚申2" : {"image_url":"/img/yijing/yijing_a04.png", "element":"水", "name":"Bliss", "name_ch":"山水蒙", "description":"", "description_ch":"启蒙奋发"},
    "壬申" : {"image_url":"/img/yijing/yijing_a15.png", "element":"水", "name":"Officer", "name_ch":"地山谦", "description":"", "description_ch":"内高外低"},
    "乙酉" : {"image_url":"/img/yijing/yijing_a33.png", "element":"金", "name":"Retreat", "name_ch":"天山遁", "description":"", "description_ch":"遁世救世"},
    "丁酉" : {"image_url":"/img/yijing/yijing_a31.png", "element":"金", "name":"Influence", "name_ch":"泽山咸", "description":"", "description_ch":"相互感应"},
    "己酉" : {"image_url":"/img/yijing/yijing_a56.png", "element":"木", "name":"Travelling", "name_ch":"火山旅", "description":"", "description_ch":"依义顺时"},
    "辛酉" : {"image_url":"/img/yijing/yijing_a62.png", "element":"木", "name":"Lessing Exceeding", "name_ch":"雷山小过", "description":"", "description_ch":"行动有度"},
    "癸酉" : {"image_url":"/img/yijing/yijing_a53.png", "element":"火", "name":"Gradual Progress", "name_ch":"风山渐", "description":"", "description_ch":"渐进蓄德"},
    "甲戌" : {"image_url":"/img/yijing/yijing_a39.png", "element":"火", "name":"Obstruction", "name_ch":"水山蹇", "description":"", "description_ch":"险阻在前"},
    "丙戌" : {"image_url":"/img/yijing/yijing_a52.png", "element":"水", "name":"Mountain", "name_ch":"艮为山", "description":"", "description_ch":"动静适时"},
    "戊戌" : {"image_url":"/img/yijing/yijing_a15.png", "element":"水", "name":"Humility", "name_ch":"地山谦", "description":"", "description_ch":"内高外低"},
    "庚戌" : {"image_url":"/img/yijing/yijing_a12.png", "element":"金", "name":"Stagnation", "name_ch":"天地否", "description":"", "description_ch":"不交不通"},
    "壬戌" : {"image_url":"/img/yijing/yijing_a45.png", "element":"金", "name":"Gathering", "name_ch":"泽地萃", "description":"", "description_ch":"荟萃聚集"},
    "乙亥" : {"image_url":"/img/yijing/yijing_a35.png", "element":"木", "name":"Advancement", "name_ch":"火地晋", "description":"", "description_ch":"求进发展"},
    "丁亥" : {"image_url":"/img/yijing/yijing_a16.png", "element":"木", "name":"Delight", "name_ch":"雷地豫", "description":"", "description_ch":"顺时依势"},
    "己亥" : {"image_url":"/img/yijing/yijing_a20.png", "element":"火", "name":"Observation", "name_ch":"风地观", "description":"", "description_ch":"观下瞻上"},
    "辛亥" : {"image_url":"/img/yijing/yijing_a08.png", "element":"火", "name":"Alliance", "name_ch":"水地比", "description":"", "description_ch":"诚信团结"},
    "癸亥" : {"image_url":"/img/yijing/yijing_a23.png", "element":"水", "name":"Peel", "name_ch":"山地剥", "description":"", "description_ch":"顺势而止"},
    "甲子" : {"image_url":"/img/yijing/yijing_a02.png", "element":"水", "name":"Earth", "name_ch":"坤为地", "description":"", "description_ch":"厚德载物"}
  },

  reference_bz_10_god:{ 
    "甲" :{"甲":"比肩","乙":"劫财","丙":"食神","丁":"伤官","戊":"偏财","己":"正财","庚":"七杀","辛":"正官","壬":"偏印","癸":"正印","  ":"  "}, 
    "乙" :{"甲":"劫财","乙":"比肩","丙":"伤官","丁":"食神","戊":"正财","己":"偏财","庚":"正官","辛":"七杀","壬":"正印","癸":"偏印","  ":"  "}, 
    "丙" :{"甲":"偏印","乙":"正印","丙":"比肩","丁":"劫财","戊":"食神","己":"伤官","庚":"偏财","辛":"正财","壬":"七杀","癸":"正官","  ":"  "}, 
    "丁" :{"甲":"正印","乙":"偏印","丙":"劫财","丁":"比肩","戊":"伤官","己":"食神","庚":"正财","辛":"偏财","壬":"正官","癸":"七杀","  ":"  "}, 
    "戊" :{"甲":"七杀","乙":"正官","丙":"偏印","丁":"正印","戊":"比肩","己":"劫财","庚":"食神","辛":"伤官","壬":"偏财","癸":"正财","  ":"  "}, 
    "己" :{"甲":"正官","乙":"七杀","丙":"正印","丁":"偏印","戊":"劫财","己":"比肩","庚":"伤官","辛":"食神","壬":"正财","癸":"偏财","  ":"  "}, 
    "庚" :{"甲":"偏财","乙":"正财","丙":"七杀","丁":"正官","戊":"偏印","己":"正印","庚":"比肩","辛":"劫财","壬":"食神","癸":"伤官","  ":"  "}, 
    "辛" :{"甲":"正财","乙":"偏财","丙":"正官","丁":"七杀","戊":"正印","己":"偏印","庚":"劫财","辛":"比肩","壬":"伤官","癸":"食神","  ":"  "}, 
    "壬" :{"甲":"食神","乙":"伤官","丙":"偏财","丁":"正财","戊":"七杀","己":"正官","庚":"偏印","辛":"正印","壬":"比肩","癸":"劫财","  ":"  "}, 
    "癸" :{"甲":"伤官","乙":"食神","丙":"正财","丁":"偏财","戊":"正官","己":"七杀","庚":"正印","辛":"偏印","壬":"劫财","癸":"比肩","  ":"  "},
  },

  reference_earthly_branch_element : {
    "子" : "壬",
    "丑" : "己",
    "寅" : "甲",
    "卯" : "乙",
    "辰" : "戊",
    "巳" : "丁",
    "午" : "丙",
    "未" : "己",
    "申" : "庚",
    "酉" : "辛",
    "戌" : "戊",
    "亥" : "癸",
  },

  reference_hidden_branch : {
    "子" : ["癸","  ","  "],
    "丑" : ["己","癸","辛"],
    "寅" : ["甲","丙","戊"],
    "卯" : ["乙","  ","  "],
    "辰" : ["戊","乙","癸"],
    "巳" : ["丙","戊","庚"],
    "午" : ["丁","己","  "],
    "未" : ["己","乙","丁"],
    "申" : ["庚","戊","壬"],
    "酉" : ["辛","  ","  "],
    "戌" : ["戊","辛","丁"],
    "亥" : ["壬","甲","  "],
    "  " : ["  ","  ","  "],
  },

  reference_element_english:{
    "甲": "Jia",
    "乙": "Yi",
    "丙": "Bing",
    "丁": "Ding",
    "戊": "Wu",
    "己": "Ji",
    "庚": "Geng",
    "辛": "Xing",
    "壬": "Ren",
    "癸": "Gui",
    "  ": " "
  },

  reference_animal_english:{
    "子":"Rat",
    "丑":"Ox",
    "寅":"Tiger",
    "卯":"Rabbit",
    "辰":"Dragon",
    "巳":"Snake",
    "午":"Horse",
    "未":"Goat",
    "申":"Monkey",
    "酉":"Rooster",
    "戌":"Dog",
    "亥":"Pig",
    "  ":" "
  },

  reference_horoscope_english:{
    "鼠":"Rat",
    "牛":"Ox",
    "虎":"Tiger",
    "兔":"Rabbit",
    "龙":"Dragon",
    "蛇":"Snake",
    "马":"Horse",
    "羊":"Goat",
    "猴":"Monkey",
    "鸡":"Rooster",
    "狗":"Dog",
    "猪":"Pig"
  },

  reference_nayin_english:{
    "海中金":"Sea Metal",
    "炉中火":"Furnace Fire",
    "大林木":"Forest Wood",
    "路旁土":"Road Earth",
    "剑锋金":"Sword Metal",
    "山头火":"Volcanic Fire",
    "涧下水":"Cave Water",
    "城头土":"Fortress Earth",
    "白蜡金":"Wax Metal",
    "杨柳木":"Willow Wood",
    "泉中水":"Stream Water",
    "屋上土":"Roof Tiles Earth",
    "霹雳火":"Lightning Fire",
    "松柏木":"Conifer Wood",
    "长流水":"River Water",
    "沙中金":"Sand Metal",
    "山下火":"Forest Fire",
    "平地木":"Meadow Wood",
    "壁上土":"Adobe Earth",
    "金箔金":"Precious Metal",
    "覆灯火":"Lamp Fire",
    "天河水":"Sky Water",
    "大驿土":"Highway Earth",
    "钗钏金":"Jewellery Metal",
    "桑柘木":"Mulberry Wood",
    "大溪水":"Rapids Water ",
    "沙中土":"Desert Earth",
    "天上火":"Sun Fire",
    "石榴木":"Pomegranate Wood",
    "大海水":"Ocean Water"
  },

  reference_day_officers_english:{
    "建" : "Establish",
    "除" : "Remove",
    "满" : "Full",
    "平" : "Balance",
    "定" : "Stable",
    "执" : "Initiate",
    "破" : "Destruction",
    "危" : "Danger",
    "成" : "Sucess",
    "收" : "Receive",
    "开" : "Open",
    "闭" : "Close"
  },

  reference_yellow_belt_star_english:{
    "司命" : "Life Governor",
    "勾陈" : "Grappling Hook",
    "青龙" : "Green Dragon",
    "明堂" : "Bright Hall",
    "朱雀" : "Red Phoenix",
    "金匮" : "Golden Lock",
    "天德" : "Heavenly Moral",
    "白虎" : "White Tiger",
    "玉堂" : "Jade Hall",
    "天牢" : "Heavenly Jail",
    "玄武" : "Black Tortoise",
    "天刑" : "Heavenly Punishment"
  },

  reference_yellow_belt_english:{
    "黑道" : "Black Belt",
    "黄道" : "Yellow Belt"
  },

  reference_luck_english:{
    "凶" : "Inauspicious",
    "吉" : "Auspicious"
  },

  reference_28_constellations_english:{
    "角" : "Horn",
    "亢" : "Neck",
    "氐" : "Root",
    "房" : "House",
    "心" : "Heart",
    "尾" : "Tail",
    "箕" : "Basket",
    "斗" : "Dipper",
    "牛" : "Ox",
    "女" : "Girl",
    "虚" : "Emptiness",
    "危" : "Danger",
    "室" : "Camp",
    "壁" : "Wall",
    "奎" : "Legs",
    "娄" : "Bond",
    "胃" : "Stomach",
    "昴" : "Pleiades",
    "毕" : "Net",
    "觜" : "Beak",
    "参" : "Reference",
    "井" : "Well",
    "鬼" : "Ghost",
    "柳" : "Willow",
    "星" : "Star",
    "张" : "Extend",
    "翼" : "Wings",
    "轸" : "Chariot",
  },

  reference_28_constellations_animal_english:{
    "蛟" : "Water Dragon",
    "龙" : "Dragon",
    "貉" : "Raccoon",
    "兔" : "Rabbit",
    "狐" : "Fox",
    "虎" : "Tiger",
    "豹" : "Leopard",
    "犴" : "Moose",
    "羊" : "Sheep",
    "獐" : "Roe",
    "马" : "Horse",
    "鹿" : "Deer",
    "蛇" : "Snake",
    "蚓" : "Worm",
    "狼" : "Wolf",
    "狗" : "Dog",
    "彘" : "Pheasant", // Got conflict. 胃土彘 or 胃土雉
    "鸡" : "Chicken",
    "乌" : "Bird",
    "猴" : "Monkey",
    "猿" : "Ape",
    "獬" : "Unicorn",
    "牛" : "Ox",
    "蝠" : "Bat",
    "鼠" : "Rat",
    "燕" : "Swallow",
    "猪" : "Pig",
    "獝" : "Otter" // need confirmation
  },

  reference_24_solar_terms_english:{
    "立春" : "Spring Commences",
    "雨水" : "Spring Showers",
    "惊蛰" : "Insects Waken",
    "春分" : "Vernal Equinox",
    "清明" : "Bright and Clear",
    "谷雨" : "Corn Rain",
    "立夏" : "Summer Commences",
    "小满" : "Corn Forms",
    "芒种" : "Corn on Ear",
    "夏至" : "Summer Solstice",
    "小暑" : "Moderate Heat",
    "大暑" : "Great Heat",
    "立秋" : "Autumn Commences",
    "处暑" : "End of Heat",
    "白露" : "White Dew",
    "秋分" : "Autumnal Equinox",
    "寒露" : "Cold Dew",
    "霜降" : "Frost",
    "立冬" : "Winter Solstice",
    "小雪" : "Light Snow",
    "大雪" : "Heavy Snow",
    "冬至" : "Winter Solstice",
    "小寒" : "Moderate Cold",
    "大寒" : "Severe Cold"
  },

  reference_yi_jing_english:{
    "乾":"Qian",
    "兑":"Dui",
    "离":"Li",
    "震":"Zhen",
    "巽":"Xun",
    "坎":"Kan",
    "艮":"Gen",
    "坤":"Kun"
  },

  reference_7_zheng_english:{
    "日":"Sun",
    "月":"Moon",
    "火":"Fire",
    "水":"Water",
    "木":"Wood",
    "金":"Metal",
    "土":"Earth"
  },

  reference_direction_english:{
    "n": "NORTH",
    "ne": "NORTHEAST",
    "e": "EAST",
    "se": "SOUTHEAST",
    "s": "SOUTH",
    "sw": "SOUTHWEST",
    "w": "WEST",
    "nw": "NORTHWEST",
    "c": "CENTER"
  },

  reference_direction_index:["n", "ne", "e", "se", "s", "sw", "w", "nw", "c"],

  reference_direction_chinese_to_english:{
    "正北": "NORTH",
    "北": "NORTH",
    "东北": "NORTHEAST",
    "正东": "EAST",
    "东": "EAST",
    "东南": "SOUTHEAST",
    "正南": "SOUTH",
    "南": "SOUTH",
    "西南": "SOUTHWEST",
    "正西": "WEST",
    "西": "WEST",
    "西北": "NORTHWEST",
    "正中": "CENTER"
  },

  reference_lunar_phase_english:{
    "朔":"New Moon", // Main
    "既朔":"Waxing Crescent",
    "蛾眉新":"Waxing Crescent",
    "蛾眉":"Waxing Crescent", // Main
    "夕月":"Waxing Crescent",
    "上弦":"First Quarter", // Main
    "九夜":"Waxing Gibbous",
    "宵":"Waxing Gibbous",
    "渐盈凸":"Waxing Gibbous", // Main
    "小望":"Waxing Gibbous",
    "望":"Full Moon", // Main
    "既望":"Waning Gibbous",
    "立待":"Waning Gibbous",
    "居待":"Waning Gibbous",
    "寝待":"Waning Gibbous",
    "更待":"Waning Gibbous",
    "渐亏凸":"Waning Gibbous", // Main
    "下弦":"Third Quarter", // Main
    "有明":"Waning Crescent",
    "蛾眉残":"Waning Crescent",
    "残":"Waning Crescent", // Main
    "晓":"Waning Crescent",
    "晦":"Dark Moon" // Main
  },

  reference_direction_chinese:{
    "n": "正北",
    "ne": "东北",
    "e": "正东",
    "se": "东南",
    "s": "正南",
    "sw": "西南",
    "w": "正西",
    "nw": "西北",
    "c": "正中"
  },

  reference_direction_chinese_to_index:{
    "正北": "n",
    "北": "n",
    "东北": "ne",
    "正东": "e",
    "东": "e",
    "东南": "se",
    "正南": "s",
    "南": "s",
    "西南": "sw",
    "正西": "w",
    "西": "w",
    "西北": "nw",
    "正中": "c",
    "中": "c"
  },

  reference_element_clash:{
    "甲":"庚",
    "乙":"辛",
    "丙":"壬",
    "丁":"癸",
    "戊":"甲",
    "己":"乙",
    "庚":"丙",
    "辛":"丁",
    "壬":"戊",
    "癸":"己",
  },

  reference_animal_clash:{
    "子":"午",
    "丑":"未",
    "寅":"申",
    "卯":"酉",
    "辰":"戌",
    "巳":"亥",
    "午":"子",
    "未":"丑",
    "申":"寅",
    "酉":"卯",
    "戌":"辰",
    "亥":"巳"
  },

  reference_10_god_english:{
    "比肩" : "F",
    "劫财" : "RW",
    "食神" : "EG",
    "伤官" : "HO",
    "偏财" : "IW",
    "正财" : "DW",
    "七杀" : "7K",
    "正官" : "DO",
    "偏印" : "IR",
    "正印" : "DR",
    "  " : "  "
  },

  reference_10_god_icon:{
    "比肩" : "👥",
    "劫财" : "🔪",
    "食神" : "🍜",
    "伤官" : "⛑",
    "偏财" : "🎰",
    "正财" : "💵",
    "七杀" : "7️⃣",
    "正官" : "🎩",
    "偏印" : "💎",
    "正印" : "🎓",
    "  " : "  "
  },

  reference_10_god_from_lunar:{
    "比":"比肩",
    "劫":"劫财",
    "食":"食神",
    "傷":"伤官",
    "才":"偏财",
    "財":"正财",
    "殺":"七杀",
    "官":"正官",
    "梟":"偏印",
    "印":"正印",
    "  ":"  "
  },

  reference_animal_icon:{
    "子":"🐁",
    "丑":"🐂",
    "寅":"🐅",
    "卯":"🐇",
    "辰":"🐉",
    "巳":"🐍",
    "午":"🐎",
    "未":"🐑",
    "申":"🐒",
    "酉":"🐓",
    "戌":"🐕",
    "亥":"🐖",
    "  ":"  "
  },

  reference_direction_icon:{
    "n": "⬆️",
    "ne": "↗️",
    "e": "➡️",
    "se": "↘️",
    "s": "⬇️",
    "sw": "↙️",
    "w": "⬅️",
    "nw": "↖️",
    "c": "⚫️"
  },

  reference_element_icon:{
    "甲":"🌳",
    "乙":"🌱",
    "丙":"🔆",
    "丁":"🕯",
    "戊":"⛰",
    "己":"🏜",
    "庚":"🪓",
    "辛":"💍",
    "壬":"🌊",
    "癸":"💧",
    "  ":"  ",
  },

  reference_yellow_belt_icon:{
    "黑道" : "⬛️",
    "黄道" : "🟨"
  },

  reference_lunar_phase_icon:{
    "朔":"🌑", // Main
    "既朔":"🌒",
    "蛾眉新":"🌒",
    "蛾眉":"🌒", // Main
    "夕月":"🌒",
    "上弦":"🌓", // Main
    "九夜":"🌔",
    "宵":"🌔",
    "渐盈凸":"🌔", // Main
    "小望":"🌔",
    "望":"🌕", // Main
    "既望":"🌖",
    "立待":"🌖",
    "居待":"🌖",
    "寝待":"🌖",
    "更待":"🌖",
    "渐亏凸":"🌖", // Main
    "下弦":"🌗", // Main
    "有明":"🌘",
    "蛾眉残":"🌘",
    "残":"🌘", // Main
    "晓":"🌘",
    "晦":"🌘" // Main // should be 🌑 but to differentiate chu 1 and last day.
  },

  reference_day_officers_icon:{
    "建" : "🏗",
    "除" : "🧹",
    "满" : "🈵",
    "平" : "⚖️",
    "定" : "🧘‍♀️",
    "执" : "🚀",
    "破" : "🧨",
    "危" : "🚨",
    "成" : "🎉",
    "收" : "📥",
    "开" : "⭕️",
    "闭" : "🚫"
  },

  reference_luck_icon:{
    "凶" : "❌",
    "吉" : "✅"
  },

  reference_7_zheng_icon:{
    "日":"🌞",
    "月":"🌙",
    "火":"🔥",
    "水":"💦",
    "木":"🌴",
    "金":"🔩",
    "土":"🏕"
  },

  reference_fy_icon:{
    "FanYin" : "🔀",
    "FuYin" : "🔂",
    "":""
  },

  reference_yinyang_english:{
    "阴" : "Yin",
    "阳" : "Yang",
    "":""
  },

  reference_yellow_belt_star_icon:{
    "司命" : "👨‍⚖️",
    "勾陈" : "勾",
    "青龙" : "🐲",
    "明堂" : "🌟",
    "朱雀" : "雀",
    "金匮" : "🔒",
    "天德" : "德",
    "白虎" : "🐯",
    "玉堂" : "玉",
    "天牢" : "牢",
    "玄武" : "🐢",
    "天刑" : "刑"
  },

  suit_and_avoid_icon:{
    "1" : "🚀", // commerce new  business
    "嫁娶" : "💍", // get married
    "破土" : "⛏", // break ground
    "2" : "🤝", // meeting
    "3" : "🛠", // renovation
    "4" : "📝", // sign contract
    "出行" : "🛫", // travelling
    "移徙" : "🚚", // move house
  },

  reference_28_constellations_icon:{
    "角" : "🦄",
    "亢" : "亢",
    "氐" : "🥕",
    "房" : "🏡",
    "心" : "❤",
    "尾" : "尾",
    "箕" : "🧺",
    "斗" : "斗",
    "牛" : "🐮",
    "女" : "👩",
    "虚" : "虚",
    "危" : "⚠️",
    "室" : "⛺️",
    "壁" : "壁",
    "奎" : "🦵",
    "娄" : "娄",
    "胃" : "胃",
    "昴" : "✨",
    "毕" : "毕",
    "觜" : "觜",
    "参" : "参",
    "井" : "井",
    "鬼" : "👻",
    "柳" : "柳",
    "星" : "⭐️",
    "张" : "张",
    "翼" : "🕊",
    "轸" : "轸"
  },

  reference_28_constellations_animal_icon:{
    "蛟" : "蛟",
    "龙" : "🐉",
    "貉" : "🦝",
    "兔" : "🐇",
    "狐" : "🦊",
    "虎" : "🐅",
    "豹" : "🐆",
    "犴" : "犴",
    "羊" : "🐑",
    "獐" : "獐",
    "马" : "🐎",
    "鹿" : "🦌",
    "蛇" : "🐍",
    "蚓" : "🐛",
    "狼" : "🐺",
    "狗" : "🦮",
    "彘" : "彘", // Got conflict. 胃土彘 or 胃土雉
    "鸡" : "🐓",
    "乌" : "🦜",
    "猴" : "🐒",
    "猿" : "🦧",
    "獬" : "🦄",
    "牛" : "🐂",
    "蝠" : "🦇",
    "鼠" : "🐀",
    "燕" : "燕",
    "猪" : "🐖",
    "獝" : "🦦" // need confirmation
  },

  reference_month:[
    '', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ],

  reference_week_day:[
    'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'
  ],

  reference_month_full:[
    '', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],

  reference_week_day_full:[
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  ],

  reference_number_chinese:[
    '零', '一', '二', '三', '四', '五', '六', '七', '八', '九',
    '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九',
    '二十', '二十一', '二十二', '二十三', '二十四', '二十五', '二十六', '二十七', '二十八', '二十九',
    '三十', '三十一', '三十二', '三十三', '三十四', '三十五', '三十六', '三十七', '三十八', '三十九',
  ],

  reference_week_day_chinese : [
    '日', '一', '二', '三', '四', '五', '六', '日'
  ],

  reference_palace_element : {
    'sw' : '土',
    'ne' : '土',
    'e' : '木',
    'se' : '木',
    'n' : '水',
    's' : '火',
    'w' : '金',
    'nw' : '金',
  },

  reference_stem_element : {
    "甲":"木",
    "乙":"木",
    "丙":"火",
    "丁":"火",
    "戊":"土",
    "己":"土",
    "庚":"金",
    "辛":"金",
    "壬":"水",
    "癸":"水",
  },

  reference_door_element : {
    '生' : '土',
    '傷' : '木',
    '杜' : '木',
    '休' : '水',
    '景' : '火',
    '開' : '金',
    '驚' : '金',
    '死' : '土',
  },

  reference_star_element : {
    '輔' : '木',
    '英' : '火',
    '芮' : '土',
    '沖' : '木',
    '柱' : '金',
    '任' : '土',
    '蓬' : '水',
    '心' : '金',
  },

  reference_deity_element : {
    '符' : '土',
    '天' : '金',
    '地' : '土',
    '陰' : '金',
    '合' : '木',
    '陳' : '土',
    '虎' : '金',
    '雀' : '火',
    '玄' : '水',
    '蛇' : '火',
  },

  reference_element_interaction : {
    '金金' : 's',
    '木木' : 's',
    '水水' : 's',
    '火火' : 's',
    '土土' : 's',
    '金水' : 'p',
    '木火' : 'p',
    '水木' : 'p',
    '火土' : 'p',
    '土金' : 'p',
    '水金' : 'w',
    '火木' : 'w',
    '木水' : 'w',
    '土火' : 'w',
    '金土' : 'w',
    '金木' : 'co',
    '木土' : 'co',
    '水火' : 'co',
    '火金' : 'co',
    '土水' : 'co',
    '金火' : 'ci',
    '木金' : 'ci',
    '水土' : 'ci',
    '火水' : 'ci',
    '土木' : 'ci',
  },

  reference_interaction_icon : {
    's' : '=',
    'p' : '➡️',
    'w' : '⬅️',
    'co' : '❌➡️',
    'ci' : '❌⬅️',
  },
  
  reference_element_css : {
    '金' : 'qm_metal_text',
    '木' : 'qm_wood_text',
    '水' : 'qm_water_text',
    '火' : 'qm_fire_text',
    '土' : 'qm_earth_text',
  },

  displayTime : ((h, mi, s)=>
  {
    return ((h < 10) ? "0" : "") + h + ":" + ((mi < 10) ? "0" : "") + mi + ":" + ((s < 10) ? "0" : "") + s;
  }),

  convertSuitsToEnglish:((suits)=>{
      let suitsEnglish = [];
      let suitsArray = suits.split(',');
      suitsArray.forEach(s => {
        switch (s) {
          case '纳采':
            suitsEnglish.push('Betrothal');
            break;
          case '订盟':
            suitsEnglish.push('Make An Alliance');
            break;
          case '祭祀':
            suitsEnglish.push('Praying Worshiping');
            break;
          case '求嗣':
            suitsEnglish.push('Ask for Heir');
            break;
          case '出火':
            suitsEnglish.push('Move Deity Statue/Shrine');
            break;
          case '塑绘':
            suitsEnglish.push('Start a Painting in a Temple or Carve Deity or Human Statue');
            break;
          case '裁衣':
            suitsEnglish.push('Groom Deliver Wine/Blue Clothe and Silver to Bridge via matchmaker/1 month before Wedding');
            break;
          case '会亲友':
            suitsEnglish.push('Social Events');
            break;
          case '入学':
            suitsEnglish.push('Start School');
            break;
          case '拆卸':
            suitsEnglish.push('Demolition');
            break;
          case '扫舍':
            suitsEnglish.push('Sweep away Dust on Roof or Dead Corner of House');
            break;
          case '造仓':
            suitsEnglish.push('Build or Repair Warehouse');
            break;
          case '挂匾':
            suitsEnglish.push('Hang Signboard or Plaque');
            break;
          case '掘井':
            suitsEnglish.push('Dig for Sewer/Fish Pond/Well');
            break;
          case '开池':
            suitsEnglish.push('Dig for Well/Pond');
            break;
          case '结网':
            suitsEnglish.push('Netting');
            break;
          case '栽种':
            suitsEnglish.push('Planting/Seeding');
            break;
          case '纳畜':
            suitsEnglish.push('Herding, Buying of Livestock/Poultry/Pets');
            break;
          case '破土':
            suitsEnglish.push('Ground Breaking for Tomb/Grave');
            break;
          case '修坟':
            suitsEnglish.push('Repair Tomb/Grave');
            break;
          case '立碑':
            suitsEnglish.push('Monument');
            break;
          case '安葬':
            suitsEnglish.push('Burial');
            break;        
          case '入殓':
            suitsEnglish.push('Enshrined');
            break;
          case '祈福':
            suitsEnglish.push('Praying for Blessing');
            break;        
          case '嫁娶':
            suitsEnglish.push('Weddings/Marriage');
            break;
          case '造庙':
            suitsEnglish.push('Construction of Temple');
            break;        
          case '安床':
            suitsEnglish.push('Bed Placement');
            break;
          case '谢土':
            suitsEnglish.push('Ceremony Marked End of Construction');
            break;
          case '开仓':
            suitsEnglish.push('Open Warehouse');
            break;
          case '移徙':
            suitsEnglish.push('Relocate/Move House (Not New)');
            break;
          case '入宅':
            suitsEnglish.push('Move into a New House');
            break;
          case '诸事不宜':
            suitsEnglish.push('Not good for any activities');
            break;
          case '解除':
            suitsEnglish.push('Removal/Cleaning House/Resolve Disasters');
            break;
          case '馀事勿取':
            suitsEnglish.push('Do not do anything other than stated');
            break;
          case '沐浴':
            suitsEnglish.push('Bath, Cleansing Body');
            break;
          case '冠笄':
            suitsEnglish.push('Coming-of-Age Ceremony');
            break;
          case '修造':
            suitsEnglish.push('Renovation');
            break;
          case '动土':
            suitsEnglish.push('Ground Breaking for House');
            break;
          case '除服':
            suitsEnglish.push('Taking off mourning clothes');
            break;
          case '成服':
            suitsEnglish.push('Wearing mourning clothes');
            break;
          case '移柩':
            suitsEnglish.push('Moving coffin out');
            break;
          case '启钻':
            suitsEnglish.push('Picking Bone');
            break;
          case '伐木':
            suitsEnglish.push('Cut Tree to Make Roof Beams');
            break;
          case '纳财':
            suitsEnglish.push('Make Money');
            break;
          case '交易':
            suitsEnglish.push('Business Deal/Transaction');
            break;
          case '立券':
            suitsEnglish.push('Enter into Contracts');
            break;
          case '作灶':
            suitsEnglish.push('Installation/Shift of Kitchen Stove');
            break;
          case '上梁':
            suitsEnglish.push('Installing of Roof');
            break;
          case '出行':
            suitsEnglish.push('Travelling');
            break;
          case '斋醮':
            suitsEnglish.push('Fasting Ceremony Before Build Temple');
            break;
          case '竖柱':
            suitsEnglish.push('Erection of Building Pillar');
            break;
          case '合帐':
            suitsEnglish.push('Making of Mosquito Nets/Placement of Curtains');
            break;
          case '造畜稠':
            suitsEnglish.push('Construction of Huts for Poultry and Livestock');
            break;
          case '起基':
            suitsEnglish.push('First Time Excavate Soil for Construction');
            break;
          case '进人口':
            suitsEnglish.push('Adopting Children or Godsons/Goddaughters');
            break;
          case '修饰垣墙':
            suitsEnglish.push('Repair Wall/House/Restructure House/Renovation');
            break;
          case '平治道涂':
            suitsEnglish.push('Paving Road');
            break;
          case '开光':
            suitsEnglish.push('Consecrate');
            break;
          case '安香':
            suitsEnglish.push('Placing God of Land or Ancester');
            break;
          case '治病':
            suitsEnglish.push('Illness Treatment');
            break;
          case '开市':
            suitsEnglish.push('Start Trading');
            break;
          case '赴任':
            suitsEnglish.push('Taking Office');
            break;
          case '捕捉':
            suitsEnglish.push('Pest Control');
            break;
          case '安门':
            suitsEnglish.push('Install Door for House or Room');
            break;
          case '盖屋':
            suitsEnglish.push('Install Roof for House');
            break;
          case '破屋':
            suitsEnglish.push('Demolition/Demolishing Houses or Walls');
            break;
          case '坏垣':
            suitsEnglish.push('Demolishing of Houses or Walls');
            break;
          case '纳婿':
            suitsEnglish.push('Groom Marry into Bride Family');
            break;
          case '畋猎':
            suitsEnglish.push('Hunting');
            break;
          case '安碓磑':
            suitsEnglish.push('Install Stone Mill');
            break;
          case '安机械':
            suitsEnglish.push('Install and Test Machinery/Spinning Wheel');
            break;
          case '造车器':
            suitsEnglish.push('Delivery of New Vehicles');
            break;
          case '经络':
            suitsEnglish.push('Weaving/Collect Silkworm/Install Spinning Wheel/Install Machinery');
            break;
          case '教牛马':
            suitsEnglish.push('Training Cattle and Horses');
            break;
          case '置产':
            suitsEnglish.push('Purchasing Property/Personal Goods');
            break;
          case '作梁':
            suitsEnglish.push('Chop Tree to Make Roof Beams');
            break;
          case '取渔':
            suitsEnglish.push('Selling Raised Fish');
            break;
          case '补垣':
            suitsEnglish.push('Plugging Holes/Pits/Covering Walls');
            break;
          case '探病':
            suitsEnglish.push('Visit the Sick');
            break;
          case '无':
            suitsEnglish.push('None');
            break;
          case '牧养':
            suitsEnglish.push('Shed Livestock');
            break;
          case '开厕':
            suitsEnglish.push('Construction of Toilet');
            break;
          case '习艺':
            suitsEnglish.push('Learn a New Skill/Enter of Apprenticeship');
            break;
          case '求医':
            suitsEnglish.push('Seeking Treatment for Chronic Illness or Surgery');
            break;
          case '塞穴':
            suitsEnglish.push('Blocking a Cave or Ant Den/etc');
            break;
          case '放水':
            suitsEnglish.push('Pour/Release Washed Pond/Aquarium Water ');
            break;
          case '酬神':
            suitsEnglish.push('Votive/Thank God');
            break;
          case '问名':
            suitsEnglish.push('The Second Ceremony of the Six Wedding Ceremony in Ancient Times');
            break;
          case '针灸':
            suitsEnglish.push('Acupuncture');
            break;
          case '开渠':
            suitsEnglish.push('Open Rivers or Ditches');
            break;
          case '词讼':
            suitsEnglish.push('Litigation');
            break;
          case '分居':
            suitsEnglish.push('Separation');
            break;
          case '行丧':
            suitsEnglish.push('Attend Funeral and Give Condolence');
            break;
          case '理发':
            suitsEnglish.push('Haircut');
            break;
          case '雇庸':
            suitsEnglish.push('Maid/Helper Hiring');
            break;
          case '架马':
            suitsEnglish.push('Install Beam in Roof Top');
            break;
          case '开柱眼':
            suitsEnglish.push('Making of Pillars');
            break;
          case '合脊':
            suitsEnglish.push('Closing Roof and other Roof Work');
            break;
          case '合寿木':
            suitsEnglish.push('Making of Coffin');
            break;
          case '定磉':
            suitsEnglish.push('Lay Foundations Below a Column');
            break;
          case '出货财':
            suitsEnglish.push('Shippment/Sales/Money Lending');
            break;
          case '筑堤':
            suitsEnglish.push('Construction or Reinforcement of River or Seawall');
            break;
          case '雕刻':
            suitsEnglish.push('Sculpture');
            break;
          case '开生坟':
            suitsEnglish.push('Groundbreaking for Construction of a Tomb');
            break;
          case '造船':
            suitsEnglish.push('Constrution of Ship/Boat');
            break;
          case '断蚁':
            suitsEnglish.push('Block/Close Ant Den');
            break;
          case '造桥':
            suitsEnglish.push('Construction of Bridge');
            break;
          case '整手足甲':
            suitsEnglish.push('First Nail Cutting for Newborn');
            break;
          case '割蜜':
            suitsEnglish.push('Harvest Honey/Make Wine/Keep Bee');
            break;
          case '普渡':
            suitsEnglish.push('Deliverance');
            break;
          case '乘船':
            suitsEnglish.push('Travel by Boat');
            break;
          case '订婚':
            suitsEnglish.push('Wedding Engagement');
            break;
          case '求财':
            suitsEnglish.push('Worship for Wealth');
            break;
          case '见贵':
            suitsEnglish.push('Meet Nobleman');
            break;
          case '':
            suitsEnglish.push('');
            break;
        
          default:
            suitsEnglish.push(s);
            break;
        }
      });

      return suitsEnglish.join(',\n');
    }
  ),
}
