/***
*
*   BILLING
*   Change a subscription plan or update card details
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Card, AccountNav, TabView,
  Message, Form, PaymentForm, Animate, useAPI, usePlans } from 'components/lib';
import ReactGA from 'react-ga';

const Messages = require('./messages.json');

export function Billing(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  // state/context
  const context = useContext(AuthContext);

  // fetch plans and subscription
  const plans = usePlans();
  const subscription = useAPI('/api/account/subscription');
  const isPaid = context.user.plan === 'free' ? false : true;

  const data = plans?.data?.raw;
  let displayPlan = [];

  if(!plans.loading)
  {
    console.log(plans);
    const showPlan = [];
    const hiddenPlan = [];
    let activePlan = null;
    let i = 0;
    data?.plans?.map((p, index) => 
    {
      if(p.hidden)
      {
        hiddenPlan.push(plans.data.list[i]);
      } 
      else
      {
        showPlan.push(plans.data.list[i]);
      }
      i++;

      if(p.id == plans.data.active)
      {
        activePlan = p;
      }
    });

    if(activePlan)
    {
      displayPlan = activePlan.hidden?hiddenPlan:showPlan;
    }
    else
    {
      displayPlan = showPlan;
    }
   } 

  return (
    <Fragment>

      <AccountNav/>
      <Animate>

        { !subscription?.loading && (subscription?.data !== 'active' && subscription?.data !== 'trialing') &&
          <Message { ...Messages[subscription.data] } />
        }

        <TabView name='Billing' labels={['Plan', isPaid ? 'Card' : undefined]}>
          <Card loading={ plans.loading } restrictWidth>
            <Form
              data={{
                plan: {
                  label: 'Your subscription plan',
                  type: 'select',
                  required: true,
                  default: plans?.data?.active,
                  options: displayPlan
                }
              }}
              url='/api/account/plan'
              method='PATCH'
              buttonText='Update Plan'
              callback={(res) => {

                context.update({ plan: res.data.data.plan });

              }}
            />
          </Card>

          { isPaid &&
            <Card restrictWidth>
              <PaymentForm
                className='restrict-width'
                data={{
                  token: {
                    label: 'Update Your Card',
                    type: 'creditcard',
                    required: true
                  }
                }}
                url='/api/account/card'
                method='PATCH'
                buttonText='Save Card'
              />
            </Card>
          }
        </TabView>
      </Animate>
    </Fragment>
  );
}
