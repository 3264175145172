import React from 'react';
import ClassNames from 'classnames';
import { Label, Error, Icon } from 'components/lib';
import Style from './input.module.scss';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  root: {
    paddingBottom: '30px',
    width: '100%',
  },
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#d7ebd2',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#d7ebd2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#d7ebd2',
      },
      '&:hover fieldset': {
        borderColor: '#d7ebd2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d7ebd2',
      },
    },
  },
})(TextField);

export function TimeInput(props){

  const classes = useStyles();

  // init default value
  let time = props.value;
  let display_time  = props.value;

  const inputCss = ClassNames([
    Style.textbox,
    props.smallwidth && Style.small,
  ]);

  if (time && time.indexOf(':') && time.length >= 5){

    time = time.split(':');
    time = {

      hour: parseInt(time[0]),
      min: parseInt(time[1])

    }
  }
  else {

    const now = new Date();
    time = {

      hour: now.getHours(),
      min: now.getMinutes()

    }
  }

  function formatTimeString(t){

    return `${ t.hour < 10 ? '0' + t.hour : t.hour }:${ t.min < 10 ? '0' + t.min : t.min }`

  }

  function change(newtime){

    let newtimearray = newtime.target.value.split(':');

    let time = { hour: newtimearray[0], min: newtimearray[1] };

    let valid;

    if (props.required)
      valid = time.hour && time.min ? true : false;

    props.onChange(props.name, formatTimeString(time), valid);

  }
/// ----------------------

/*
  let error = props.errorMessage || 'Please enter a valid time';

  function validate(e){

    let value = e ? e.target.value : '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!props.required && value === '')
      valid = true;

    // now test for a valid email
    var date_split = value.split(":");
    if(date_split.length !== 3 && date_split.length !== 2)
    {
      valid = false;
    } else 
    {
      if(date_split[0] > 23 || date_split[0] < 0)
      {
        valid = false;
      } else if(date_split[1] > 59 || date_split[1] < 0)
      {
        valid = false;
      } else if(date_split[2] > 59 || date_split[2] < 0)
      {
        value = date_split[0] + ":" + date_split[1] + ":00";
        e.target.value = value;
        valid = true;
      } else
      {
        valid = true;
      }
    }

    // update the parent form
    props.onChange(props.name, value, valid);

  }

  // style
  const css = ClassNames([

    Style.textbox,
    props.className,
    props.valid === false && Style.error,
    props.valid === true && Style.success

  ]);
*/

//   <input
//   type='text'
//   id={ props.name }
//   name={ props.name }
//   className={ css }
//   value={ props.value || '' }
//   placeholder={ props.placeholder }
//   onChange={ e => props.onChange(props.name, e.target.value, undefined) }
//   onBlur={ e => validate(e) }
// />

  return(
    <div className={ Style.input }>

      <Label
        text={ props.label }
        required={ props.required }
        for={ props.name }
      />

      <CssTextField
        type="time"
        defaultValue={ formatTimeString(time) }
        className={ classes.root }
        variant="outlined"
        onChange={ change }
      />

    </div>
  );
}
