import React from 'react';
import { Row, Content, Message } from 'components/lib';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

export function NotFound(props) {

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let ref = params.get('ref');

  const cookies = new Cookies();
  if(ref)
  {
    cookies.set('referral_code', ref, { path: '/' });  
  }

  return(
    <Row>
      <Content>
        <Message
          type='error'
          title='404'
          text="Oops, the page you're looking for doesn't exist."
          buttonText='Go Home'
          buttonLink='/'
        />
      </Content>
    </Row>
  );
}
