/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, ViewContext, Form, Card, Grid,
  AccountNav, Button, History, Animate, useAPI, Table } from 'components/lib';
import { data } from '../../lib/data_reference';
import ReactGA from 'react-ga';

export function Profile(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI('/api/user');

  var bz_chart = null;
  if(!user.loading && user.data && !bz_chart)
  {
    bz_chart = JSON.parse(user.data.bz_chart);
  }

  function refreshPage(){
    // No Choice. Can't find a working solution.
    window.location = '/account';
  }

  function closeAccount(){
    viewContext.modal.show({

      title: 'Close Your Account',
      form: closeAccount,
      buttonText: 'Close Account',
      url: '/api/account',
      method: 'DELETE',
      text: 'Are you sure you want to delete your account? ' +
      'You will lose all of your data and this can not be undone.',

    }, () => {

      // destory user
      localStorage.clear();
      History.push('/signup');

    });
  }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Grid cols="2">
          <Card
            title='Edit Your Profile Information'
            loading={ user.loading } restrictWidth>

            { user?.data &&
              <Form
                buttonText='Save'
                url={ authContext.user.permission === 'owner' ? '/api/account' : '/api/user' }
                method='PATCH'
                data={{
                  name: {
                    label: 'Name',
                    type: 'text',
                    required: true,
                    value: user.data.name,
                    errorMessage: 'Please enter your name',
                  },
                  email: {
                    label: 'Email address',
                    type: 'email',
                    required: true,
                    value: user.data.email,
                    errorMessage: 'Please enter your email address'
                  },
                  gender: {
                    label: 'Gender',
                    type: 'select',
                    options: [
                    { value: 1, label: 'Male' },
                    { value: 0, label: 'Female' }
                    ],
                    default: user.data.gender,
                    required: true,
                  },
                  birth_date: {
                    label: 'Birth Date',
                    type: 'date',
                    required: true,
                    value: user.data.birth_date,
                    errorMessage: 'Please enter your date of birth'
                  },
                  birth_time: {
                    label: 'Birth Time',
                    type: 'time',
                    required: true,
                    value: user.data.birth_time,
                    errorMessage: 'Please enter your time of birth. 24Hr:Min'
                  }
                }}
                callback={ res => {
                  authContext.update({ name: res.data.data.name });
                  refreshPage();
                }}
              />
            }

            { authContext.permission.owner &&
              <Fragment>
                <br/>
                <Button
                  textOnly
                  action={ closeAccount }
                  text='Close Your Account'
                />
              </Fragment>
            }
          </Card>

          { bz_chart &&
          <Card
            title='Bazi Chart'
            loading={ user.loading }>
            <Table
              center= { true }
              data={ 
                {
                  header: [
                  { name: 'hour', title: 'HOUR 时', sort: false },
                  { name: 'day', title: 'DAY 日', sort: false },
                  { name: 'month', title: 'MONTH 月', sort: false },
                  { name: 'year', title: 'YEAR 年', sort: false }
                ],
                body:  [
                  {
                    hour:bz_chart.tg[2] + ' (' + data.reference_10_god_from_lunar[bz_chart.tg_10god[2]] + ')\n' + 
                    data.reference_element_icon[bz_chart.tg[2]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.tg_10god[2]]] + ")\n" + 
                    data.reference_element_english[bz_chart.tg[2]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.tg_10god[2]]] + ')', 
                    day:bz_chart.tg[3] + '\n' + 
                    data.reference_element_icon[bz_chart.tg[3]] + "\n" + 
                    data.reference_element_english[bz_chart.tg[3]], 
                    month:bz_chart.tg[4] + ' (' + data.reference_10_god_from_lunar[bz_chart.tg_10god[4]] + ')\n' + 
                    data.reference_element_icon[bz_chart.tg[4]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.tg_10god[4]]] + ")\n" + 
                    data.reference_element_english[bz_chart.tg[4]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.tg_10god[4]]] + ')', 
                    year:bz_chart.tg[5] + ' (' + data.reference_10_god_from_lunar[bz_chart.tg_10god[5]] + ')\n' + 
                    data.reference_element_icon[bz_chart.tg[5]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.tg_10god[5]]] + ")\n" + 
                    data.reference_element_english[bz_chart.tg[5]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.tg_10god[5]]] + ')', 
                  },
                  {
                    hour:bz_chart.eb[2] + ' (' + bz_chart.eb_element[2] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[2] || bz_chart.kongwang_element[3] === bz_chart.eb[2])?"(空)":"") + '\n ' + 
                    data.reference_animal_icon[bz_chart.eb[2]] + ' (' + data.reference_element_icon[bz_chart.eb_element[2]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[2] || bz_chart.kongwang_element[3] === bz_chart.eb[2])?"(🈳️)":"") + '\n ' + 
                    data.reference_animal_english[bz_chart.eb[2]] + ' (' + data.reference_element_english[bz_chart.eb_element[2]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[2] || bz_chart.kongwang_element[3] === bz_chart.eb[2])?"(DE)":"") + '', 
                    day:bz_chart.eb[3] + ' (' + bz_chart.eb_element[3] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[3] || bz_chart.kongwang_element[3] === bz_chart.eb[3])?"(空)":"") + '\n ' + 
                    data.reference_animal_icon[bz_chart.eb[3]] + ' (' + data.reference_element_icon[bz_chart.eb_element[3]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[3] || bz_chart.kongwang_element[3] === bz_chart.eb[3])?"(🈳️)":"") + '\n ' + 
                    data.reference_animal_english[bz_chart.eb[3]] + ' (' + data.reference_element_english[bz_chart.eb_element[3]] + ')' + ((bz_chart.kongwang[3] === bz_chart.eb[3] || bz_chart.kongwang_element[3] === bz_chart.eb[3])?"(DE)":"") + '', 
                    month:bz_chart.eb[4] + ' (' + bz_chart.eb_element[4] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[4] || bz_chart.kongwang_element[3] === bz_chart.eb[4])?"(空)":"") + '\n' + 
                    data.reference_animal_icon[bz_chart.eb[4]] + ' (' + data.reference_element_icon[bz_chart.eb_element[4]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[4] || bz_chart.kongwang_element[3] === bz_chart.eb[4])?"(🈳️)":"") + '\n ' + 
                    data.reference_animal_english[bz_chart.eb[4]] + ' (' + data.reference_element_english[bz_chart.eb_element[4]] + ')' + ((bz_chart.kongwang[3] === bz_chart.eb[4] || bz_chart.kongwang_element[3] === bz_chart.eb[4])?"(DE)":"") + '', 
                    year:bz_chart.eb[5] + ' (' + bz_chart.eb_element[5] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[5] || bz_chart.kongwang_element[3] === bz_chart.eb[5])?"(空)":"") + '\n' + 
                    data.reference_animal_icon[bz_chart.eb[5]] + ' (' + data.reference_element_icon[bz_chart.eb_element[5]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[5] || bz_chart.kongwang_element[3] === bz_chart.eb[5])?"(🈳️)":"") + '\n ' + 
                    data.reference_animal_english[bz_chart.eb[5]] + ' (' + data.reference_element_english[bz_chart.eb_element[5]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.eb[5] || bz_chart.kongwang_element[3] === bz_chart.eb[5])?"(DE)":"") + '', 
                  },
                  {
                    hour:bz_chart.hs[2][1] + " [" + bz_chart.hs[2][0] + "] " + bz_chart.hs[2][2] + "\n" + 
                    data.reference_element_icon[bz_chart.hs[2][1]]+ " [" + data.reference_element_icon[bz_chart.hs[2][0]]+ "] " + data.reference_element_icon[bz_chart.hs[2][2]] + "\n" + 
                    data.reference_element_english[bz_chart.hs[2][1]] + " [" + data.reference_element_english[bz_chart.hs[2][0]] + "] " + data.reference_element_english[bz_chart.hs[2][2]], 
                    day:bz_chart.hs[3][1] + " [" + bz_chart.hs[3][0] + "] " + bz_chart.hs[3][2] + "\n" + 
                    data.reference_element_icon[bz_chart.hs[3][1]]+ " [" + data.reference_element_icon[bz_chart.hs[3][0]]+ "] " + data.reference_element_icon[bz_chart.hs[3][2]] + "\n" + 
                    data.reference_element_english[bz_chart.hs[3][1]] + " [" + data.reference_element_english[bz_chart.hs[3][0]] + "] " + data.reference_element_english[bz_chart.hs[3][2]], 
                    month:bz_chart.hs[4][1] + " [" + bz_chart.hs[4][0] + "] " + bz_chart.hs[4][2] + "\n" + 
                    data.reference_element_icon[bz_chart.hs[4][1]]+ " [" + data.reference_element_icon[bz_chart.hs[4][0]]+ "] " + data.reference_element_icon[bz_chart.hs[4][2]] + "\n" + 
                    data.reference_element_english[bz_chart.hs[4][1]] + " [" + data.reference_element_english[bz_chart.hs[4][0]] + "] " + data.reference_element_english[bz_chart.hs[4][2]], 
                    year:bz_chart.hs[5][1] + " [" + bz_chart.hs[5][0] + "] " + bz_chart.hs[5][2] + "\n" + 
                    data.reference_element_icon[bz_chart.hs[5][1]]+ " [" + data.reference_element_icon[bz_chart.hs[5][0]]+ "] " + data.reference_element_icon[bz_chart.hs[5][2]] + "\n" + 
                    data.reference_element_english[bz_chart.hs[5][1]] + " [" + data.reference_element_english[bz_chart.hs[5][0]] + "] " + data.reference_element_english[bz_chart.hs[5][2]], 
                  },
                  {
                    hour:data.reference_10_god_from_lunar[bz_chart.hs_element[2][1]] + " [" + data.reference_10_god_from_lunar[bz_chart.hs_element[2][0]] + "] " + data.reference_10_god_from_lunar[bz_chart.hs_element[2][2]] + "\n" + 
                    data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[2][1]]]+ " [" + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[2][0]]]+ "] " + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[2][2]]] + "\n" + 
                    data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[2][1]]] + " [" + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[2][0]]] + "] " + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[2][2]]], 
                    day:data.reference_10_god_from_lunar[bz_chart.hs_element[3][1]] + " [" + data.reference_10_god_from_lunar[bz_chart.hs_element[3][0]] + "] " + data.reference_10_god_from_lunar[bz_chart.hs_element[3][2]] + "\n" + 
                    data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[3][1]]]+ " [" + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[3][0]]]+ "] " + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[3][2]]] + "\n" + 
                    data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[3][1]]] + " [" + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[3][0]]] + "] " + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[3][2]]], 
                    month:data.reference_10_god_from_lunar[bz_chart.hs_element[4][1]] + " [" + data.reference_10_god_from_lunar[bz_chart.hs_element[4][0]] + "] " + data.reference_10_god_from_lunar[bz_chart.hs_element[4][2]] + "\n" + 
                    data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[4][1]]]+ " [" + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[4][0]]]+ "] " + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[4][2]]] + "\n" + 
                    data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[4][1]]] + " [" + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[4][0]]] + "] " + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[4][2]]], 
                    year:data.reference_10_god_from_lunar[bz_chart.hs_element[5][1]] + " [" + data.reference_10_god_from_lunar[bz_chart.hs_element[5][0]] + "] " + data.reference_10_god_from_lunar[bz_chart.hs_element[5][2]] + "\n" + 
                    data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[5][1]]]+ " [" + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[5][0]]]+ "] " + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.hs_element[5][2]]] + "\n" + 
                    data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[5][1]]] + " [" + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[5][0]]] + "] " + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[5][2]]], 
                  },
                ]
              }
            }
            />
            <br/>
            <Card title='10 Year Luck Pillar'>
              <Table center= { true }
                data={ 
                  {
                    header: [
                    { name: 'dy0', title: bz_chart.dayun_age[0], sort: false },
                    { name: 'dy1', title: bz_chart.dayun_age[1], sort: false },
                    { name: 'dy2', title: bz_chart.dayun_age[2], sort: false },
                    { name: 'dy3', title: bz_chart.dayun_age[3], sort: false },
                    { name: 'dy4', title: bz_chart.dayun_age[4], sort: false },
                  ],
                  body:  [
                    {
                      dy0: bz_chart.dayun_tg[0] + ' (' + data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[0]] + ')\n' + 
                            data.reference_element_icon[bz_chart.dayun_tg[0]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[0]]] + ")\n" + 
                            data.reference_element_english[bz_chart.dayun_tg[0]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[0]]] + ')', 
                      dy1: bz_chart.dayun_tg[1] + ' (' + data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[1]] + ')\n' + 
                            data.reference_element_icon[bz_chart.dayun_tg[1]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[1]]] + ")\n" + 
                            data.reference_element_english[bz_chart.dayun_tg[1]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[1]]] + ')', 
                      dy2: bz_chart.dayun_tg[2] + ' (' + data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[2]] + ')\n' + 
                            data.reference_element_icon[bz_chart.dayun_tg[2]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[2]]] + ")\n" + 
                            data.reference_element_english[bz_chart.dayun_tg[2]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[2]]] + ')', 
                      dy3: bz_chart.dayun_tg[3] + ' (' + data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[3]] + ')\n' + 
                            data.reference_element_icon[bz_chart.dayun_tg[3]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[3]]] + ")\n" + 
                            data.reference_element_english[bz_chart.dayun_tg[3]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[3]]] + ')', 
                      dy4: bz_chart.dayun_tg[4] + ' (' + data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[4]] + ')\n' + 
                            data.reference_element_icon[bz_chart.dayun_tg[4]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[4]]] + ")\n" + 
                            data.reference_element_english[bz_chart.dayun_tg[4]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[4]]] + ')', 
                    },
                    {
                      dy0: bz_chart.dayun_eb[0] + ' (' + bz_chart.dayun_eb_element[0] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[0] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[0])?"(空)":"") + '\n ' + 
                      data.reference_animal_icon[bz_chart.dayun_eb[0]] + ' (' + data.reference_element_icon[bz_chart.dayun_eb_element[0]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[0] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[0])?"(🈳️)":"") + '\n ' + 
                      data.reference_animal_english[bz_chart.dayun_eb[0]] + ' (' + data.reference_element_english[bz_chart.dayun_eb_element[0]] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[0] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[0])?"(DE)":"") + '',
                      dy1: bz_chart.dayun_eb[1] + ' (' + bz_chart.dayun_eb_element[1] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[1] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[1])?"(空)":"") + '\n ' + 
                      data.reference_animal_icon[bz_chart.dayun_eb[1]] + ' (' + data.reference_element_icon[bz_chart.dayun_eb_element[1]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[1] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[1])?"(🈳️)":"") + '\n ' + 
                      data.reference_animal_english[bz_chart.dayun_eb[1]] + ' (' + data.reference_element_english[bz_chart.dayun_eb_element[1]] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[1] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[1])?"(DE)":"") + '',
                      dy2: bz_chart.dayun_eb[2] + ' (' + bz_chart.dayun_eb_element[2] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[2] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[2])?"(空)":"") + '\n ' + 
                      data.reference_animal_icon[bz_chart.dayun_eb[2]] + ' (' + data.reference_element_icon[bz_chart.dayun_eb_element[2]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[2] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[2])?"(🈳️)":"") + '\n ' + 
                      data.reference_animal_english[bz_chart.dayun_eb[2]] + ' (' + data.reference_element_english[bz_chart.dayun_eb_element[2]] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[2] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[2])?"(DE)":"") + '',
                      dy3: bz_chart.dayun_eb[3] + ' (' + bz_chart.dayun_eb_element[3] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[3] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[3])?"(空)":"") + '\n ' + 
                      data.reference_animal_icon[bz_chart.dayun_eb[3]] + ' (' + data.reference_element_icon[bz_chart.dayun_eb_element[3]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[3] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[3])?"(🈳️)":"") + '\n ' + 
                      data.reference_animal_english[bz_chart.dayun_eb[3]] + ' (' + data.reference_element_english[bz_chart.dayun_eb_element[3]] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[3] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[3])?"(DE)":"") + '',
                      dy4: bz_chart.dayun_eb[4] + ' (' + bz_chart.dayun_eb_element[4] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[4] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[4])?"(空)":"") + '\n ' + 
                      data.reference_animal_icon[bz_chart.dayun_eb[4]] + ' (' + data.reference_element_icon[bz_chart.dayun_eb_element[4]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[4] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[4])?"(🈳️)":"") + '\n ' + 
                      data.reference_animal_english[bz_chart.dayun_eb[4]] + ' (' + data.reference_element_english[bz_chart.dayun_eb_element[4]] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[4] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[4])?"(DE)":"") + '',
                    },
                    {
                      dy0:bz_chart.dayun_hs[0][1] + " " + bz_chart.dayun_hs[0][0] + " " + bz_chart.dayun_hs[0][2] + "\n" + 
                      data.reference_element_icon[bz_chart.dayun_hs[0][1]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[0][0]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[0][2]] + "\n" + 
                      data.reference_element_english[bz_chart.dayun_hs[0][1]] + " " + data.reference_element_english[bz_chart.dayun_hs[0][0]] + " " + data.reference_element_english[bz_chart.dayun_hs[0][2]], 
                      dy1:bz_chart.dayun_hs[1][1] + " " + bz_chart.dayun_hs[1][0] + " " + bz_chart.dayun_hs[1][2] + "\n" + 
                      data.reference_element_icon[bz_chart.dayun_hs[1][1]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[1][0]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[1][2]] + "\n" + 
                      data.reference_element_english[bz_chart.dayun_hs[1][1]] + " " + data.reference_element_english[bz_chart.dayun_hs[1][0]] + " " + data.reference_element_english[bz_chart.dayun_hs[1][2]], 
                      dy2:bz_chart.dayun_hs[2][1] + " " + bz_chart.dayun_hs[2][0] + " " + bz_chart.dayun_hs[2][2] + "\n" + 
                      data.reference_element_icon[bz_chart.dayun_hs[2][1]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[2][0]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[2][2]] + "\n" + 
                      data.reference_element_english[bz_chart.dayun_hs[2][1]] + " " + data.reference_element_english[bz_chart.dayun_hs[2][0]] + " " + data.reference_element_english[bz_chart.dayun_hs[2][2]], 
                      dy3:bz_chart.dayun_hs[3][1] + " " + bz_chart.dayun_hs[3][0] + " " + bz_chart.dayun_hs[3][2] + "\n" + 
                      data.reference_element_icon[bz_chart.dayun_hs[3][1]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[3][0]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[3][2]] + "\n" + 
                      data.reference_element_english[bz_chart.dayun_hs[3][1]] + " " + data.reference_element_english[bz_chart.dayun_hs[3][0]] + " " + data.reference_element_english[bz_chart.dayun_hs[3][2]], 
                      dy4:bz_chart.dayun_hs[4][1] + " " + bz_chart.dayun_hs[4][0] + " " + bz_chart.dayun_hs[4][2] + "\n" + 
                      data.reference_element_icon[bz_chart.dayun_hs[4][1]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[4][0]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[4][2]] + "\n" + 
                      data.reference_element_english[bz_chart.dayun_hs[4][1]] + " " + data.reference_element_english[bz_chart.dayun_hs[4][0]] + " " + data.reference_element_english[bz_chart.dayun_hs[4][2]], 
                    }
                  ]
                }
              }
              />
              <br/><br/>
              <Table center= { true }
                data={ 
                  {
                    header: [
                    { name: 'dy5', title: bz_chart.dayun_age[5], sort: false },
                    { name: 'dy6', title: bz_chart.dayun_age[6], sort: false },
                    { name: 'dy7', title: bz_chart.dayun_age[7], sort: false },
                    { name: 'dy8', title: bz_chart.dayun_age[8], sort: false },
                  ],
                  body:  [
                    {
                      dy5: bz_chart.dayun_tg[5] + ' (' + data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[5]] + ')\n' + 
                            data.reference_element_icon[bz_chart.dayun_tg[5]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[5]]] + ")\n" + 
                            data.reference_element_english[bz_chart.dayun_tg[5]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[5]]] + ')', 
                      dy6: bz_chart.dayun_tg[6] + ' (' + data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[6]] + ')\n' + 
                            data.reference_element_icon[bz_chart.dayun_tg[6]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[6]]] + ")\n" + 
                            data.reference_element_english[bz_chart.dayun_tg[6]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[6]]] + ')', 
                      dy7: bz_chart.dayun_tg[7] + ' (' + data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[7]] + ')\n' + 
                            data.reference_element_icon[bz_chart.dayun_tg[7]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[7]]] + ")\n" + 
                            data.reference_element_english[bz_chart.dayun_tg[7]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[7]]] + ')', 
                      dy8: bz_chart.dayun_tg[8] + ' (' + data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[8]] + ')\n' + 
                            data.reference_element_icon[bz_chart.dayun_tg[8]] + ' (' + data.reference_10_god_icon[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[8]]] + ")\n" + 
                            data.reference_element_english[bz_chart.dayun_tg[8]] + ' (' + data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[8]]] + ')', 
                    },
                    {
                      dy5: bz_chart.dayun_eb[5] + ' (' + bz_chart.dayun_eb_element[5] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[5] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[5])?"(空)":"") + '\n ' + 
                      data.reference_animal_icon[bz_chart.dayun_eb[5]] + ' (' + data.reference_element_icon[bz_chart.dayun_eb_element[5]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[5] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[5])?"(🈳️)":"") + '\n ' + 
                      data.reference_animal_english[bz_chart.dayun_eb[5]] + ' (' + data.reference_element_english[bz_chart.dayun_eb_element[5]] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[5] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[5])?"(DE)":"") + '',
                      dy6: bz_chart.dayun_eb[6] + ' (' + bz_chart.dayun_eb_element[6] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[6] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[6])?"(空)":"") + '\n ' + 
                      data.reference_animal_icon[bz_chart.dayun_eb[6]] + ' (' + data.reference_element_icon[bz_chart.dayun_eb_element[6]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[6] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[6])?"(🈳️)":"") + '\n ' + 
                      data.reference_animal_english[bz_chart.dayun_eb[6]] + ' (' + data.reference_element_english[bz_chart.dayun_eb_element[6]] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[6] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[6])?"(DE)":"") + '',
                      dy7: bz_chart.dayun_eb[7] + ' (' + bz_chart.dayun_eb_element[7] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[7] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[7])?"(空)":"") + '\n ' + 
                      data.reference_animal_icon[bz_chart.dayun_eb[7]] + ' (' + data.reference_element_icon[bz_chart.dayun_eb_element[7]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[7] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[7])?"(🈳️)":"") + '\n ' + 
                      data.reference_animal_english[bz_chart.dayun_eb[7]] + ' (' + data.reference_element_english[bz_chart.dayun_eb_element[7]] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[7] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[7])?"(DE)":"") + '',
                      dy8: bz_chart.dayun_eb[8] + ' (' + bz_chart.dayun_eb_element[8] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[8] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[8])?"(空)":"") + '\n ' + 
                      data.reference_animal_icon[bz_chart.dayun_eb[8]] + ' (' + data.reference_element_icon[bz_chart.dayun_eb_element[8]] + ') ' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[8] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[8])?"(🈳️)":"") + '\n ' + 
                      data.reference_animal_english[bz_chart.dayun_eb[8]] + ' (' + data.reference_element_english[bz_chart.dayun_eb_element[8]] + ')' + ((bz_chart.kongwang[3] === bz_chart.dayun_eb[8] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[8])?"(DE)":"") + '',
                    },
                    {
                      dy5:bz_chart.dayun_hs[5][1] + " " + bz_chart.dayun_hs[5][0] + " " + bz_chart.dayun_hs[5][2] + "\n" + 
                      data.reference_element_icon[bz_chart.dayun_hs[5][1]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[5][0]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[5][2]] + "\n" + 
                      data.reference_element_english[bz_chart.dayun_hs[5][1]] + " " + data.reference_element_english[bz_chart.dayun_hs[5][0]] + " " + data.reference_element_english[bz_chart.dayun_hs[5][2]], 
                      dy6:bz_chart.dayun_hs[6][1] + " " + bz_chart.dayun_hs[6][0] + " " + bz_chart.dayun_hs[6][2] + "\n" + 
                      data.reference_element_icon[bz_chart.dayun_hs[6][1]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[6][0]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[6][2]] + "\n" + 
                      data.reference_element_english[bz_chart.dayun_hs[6][1]] + " " + data.reference_element_english[bz_chart.dayun_hs[6][0]] + " " + data.reference_element_english[bz_chart.dayun_hs[6][2]], 
                      dy7:bz_chart.dayun_hs[7][1] + " " + bz_chart.dayun_hs[7][0] + " " + bz_chart.dayun_hs[7][2] + "\n" + 
                      data.reference_element_icon[bz_chart.dayun_hs[7][1]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[7][0]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[7][2]] + "\n" + 
                      data.reference_element_english[bz_chart.dayun_hs[7][1]] + " " + data.reference_element_english[bz_chart.dayun_hs[7][0]] + " " + data.reference_element_english[bz_chart.dayun_hs[7][2]], 
                      dy8:bz_chart.dayun_hs[8][1] + " " + bz_chart.dayun_hs[8][0] + " " + bz_chart.dayun_hs[8][2] + "\n" + 
                      data.reference_element_icon[bz_chart.dayun_hs[8][1]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[8][0]]+ " " + data.reference_element_icon[bz_chart.dayun_hs[8][2]] + "\n" + 
                      data.reference_element_english[bz_chart.dayun_hs[8][1]] + " " + data.reference_element_english[bz_chart.dayun_hs[8][0]] + " " + data.reference_element_english[bz_chart.dayun_hs[8][2]], 
                    }
                  ]
                }
              }
              />
            </Card>
            <br/>
            <Card title='10 Year Luck Pillar Start Time'>
              <Table
                data={ 
                  {
                    header: [
                    { name: 'age', title: 'Age', sort: false },
                    { name: 'date', title: 'Start Time', sort: false },
                    ],
                    body:  [
                      {
                        age: bz_chart.dayun_age[0],
                        date: bz_chart.dayun_starttime[0][2] + " " + data.reference_month[bz_chart.dayun_starttime[0][1]] + " " + bz_chart.dayun_starttime[0][0] + " " + data.displayTime(bz_chart.dayun_starttime[0][3], bz_chart.dayun_starttime[0][4], bz_chart.dayun_starttime[0][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[0][6]] 
                      },
                      {
                        age: bz_chart.dayun_age[1],
                        date: bz_chart.dayun_starttime[1][2] + " " + data.reference_month[bz_chart.dayun_starttime[1][1]] + " " + bz_chart.dayun_starttime[1][0] + " " + data.displayTime(bz_chart.dayun_starttime[1][3], bz_chart.dayun_starttime[1][4], bz_chart.dayun_starttime[1][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[1][6]] 
                      },
                      {
                        age: bz_chart.dayun_age[2],
                        date: bz_chart.dayun_starttime[2][2] + " " + data.reference_month[bz_chart.dayun_starttime[2][1]] + " " + bz_chart.dayun_starttime[2][0] + " " + data.displayTime(bz_chart.dayun_starttime[2][3], bz_chart.dayun_starttime[2][4], bz_chart.dayun_starttime[2][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[2][6]] 
                      },
                      {
                        age: bz_chart.dayun_age[3],
                        date: bz_chart.dayun_starttime[3][2] + " " + data.reference_month[bz_chart.dayun_starttime[3][1]] + " " + bz_chart.dayun_starttime[3][0] + " " + data.displayTime(bz_chart.dayun_starttime[3][3], bz_chart.dayun_starttime[3][4], bz_chart.dayun_starttime[3][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[3][6]] 
                      },
                      {
                        age: bz_chart.dayun_age[4],
                        date: bz_chart.dayun_starttime[4][2] + " " + data.reference_month[bz_chart.dayun_starttime[4][1]] + " " + bz_chart.dayun_starttime[4][0] + " " + data.displayTime(bz_chart.dayun_starttime[4][3], bz_chart.dayun_starttime[4][4], bz_chart.dayun_starttime[4][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[4][6]] 
                      },
                      {
                        age: bz_chart.dayun_age[5],
                        date: bz_chart.dayun_starttime[5][2] + " " + data.reference_month[bz_chart.dayun_starttime[5][1]] + " " + bz_chart.dayun_starttime[5][0] + " " + data.displayTime(bz_chart.dayun_starttime[5][3], bz_chart.dayun_starttime[5][4], bz_chart.dayun_starttime[5][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[5][6]] 
                      },
                      {
                        age: bz_chart.dayun_age[6],
                        date: bz_chart.dayun_starttime[6][2] + " " + data.reference_month[bz_chart.dayun_starttime[6][1]] + " " + bz_chart.dayun_starttime[6][0] + " " + data.displayTime(bz_chart.dayun_starttime[6][3], bz_chart.dayun_starttime[6][4], bz_chart.dayun_starttime[6][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[6][6]] 
                      },
                      {
                        age: bz_chart.dayun_age[7],
                        date: bz_chart.dayun_starttime[7][2] + " " + data.reference_month[bz_chart.dayun_starttime[7][1]] + " " + bz_chart.dayun_starttime[7][0] + " " + data.displayTime(bz_chart.dayun_starttime[7][3], bz_chart.dayun_starttime[7][4], bz_chart.dayun_starttime[7][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[7][6]] 
                      },
                      {
                        age: bz_chart.dayun_age[8],
                        date: bz_chart.dayun_starttime[8][2] + " " + data.reference_month[bz_chart.dayun_starttime[8][1]] + " " + bz_chart.dayun_starttime[8][0] + " " + data.displayTime(bz_chart.dayun_starttime[8][3], bz_chart.dayun_starttime[8][4], bz_chart.dayun_starttime[8][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[8][3]] 
                      },
                    ]
                  }
                }
              />
            </Card>

          </Card>        
          }
        </Grid>
      </Animate>
    </Fragment>
  );
}
