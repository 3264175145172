/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, { useContext, useState } from 'react';
import { Animate, AuthContext, Form, Card, Link, Row } from 'components/lib';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

export function Signin(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let ref = params.get('ref');

  const cookies = new Cookies();
  if(ref)
  {
    cookies.set('referral_code', ref, { path: '/' });  
  }

  const context = useContext(AuthContext);
  const [useMagic, setUseMagic] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [form, setForm] = useState({

    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?'
    }
  });

  function toggleMagicLink(useMagic){

    const f = {...form };
    f.password.type = useMagic ? 'hidden' : 'password';
    f.password.required = useMagic ? false : true;
    f.forgotpassword.type = useMagic ? 'hidden' : 'link';

    setForm(f);
    setUseMagic(useMagic);

  }

  return(
    <Animate type='pop'>
      <Row title='Sign in to start optimising'>
          <Card restrictWidth center>
            <Form
              data={ form }
              method='POST'
              url={ useMagic ? '/api/user/auth/magic' : '/api/user/auth' }
              buttonText={ useMagic ? 'Send Magic Link' : 'Sign In' }
              callback={ (res) => {
                
                useMagic ? setMagicLinkSent(true) : context.signin(res);
              
              }}
            />

            <span>Don't have an account? <Link url='/signup' text='Sign Up'/></span>

          </Card>
      </Row>
    </Animate>
  );
}
