import React, { Fragment } from 'react';
import ClassNames from 'classnames';
import { Label } from 'components/lib';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import Style from './input.module.scss';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  root: {
    paddingBottom: '30px',
    width: '100%',
  },
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#d7ebd2',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#d7ebd2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#d7ebd2',
      },
      '&:hover fieldset': {
        borderColor: '#d7ebd2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d7ebd2',
      },
    },
  },
})(TextField);

export function DateInput(props){

  const classes = useStyles();

  // init default value
  let date = props.value;
  let display_date  = props.value;

  const inputCss = ClassNames([
    Style.textbox,
    props.smallwidth && Style.small,
  ]);

  if (date && date.indexOf('-') && date.length >= 10){

    date = date.substring(0, 10).split('-');
    date = {

      year: parseInt(date[0]),
      month: parseInt(date[1]),
      day: parseInt(date[2])

    }
  }
  else {

    const now = new Date();
    date = {

      year: now.getFullYear(),
      month: now.getMonth()+1,
      day: now.getDate()

    }
  }

  function formatDateString(d){

    return `${d.year}-${ d.month < 10 ? '0' + d.month : d.month }-${ d.day < 10 ? '0' + d.day : d.day }`

  }

  function change(newdate){

    let newdatearray = newdate.target.value.split('-');

    let date = { year: newdatearray[0], month: newdatearray[1], day: newdatearray[2] };

    let valid;

    if (props.required)
      valid = date.year && date.day && date.month ? true : false;

    props.onChange(props.name, formatDateString(date), valid);

  }

  function customInput({ ref }){

    return (
      <input 
        id={ props.name }
        name={ props.name }
        ref={ ref } 
        value={ display_date }
        placeholder={ props.placeholder || 'Please select a date' }
        className={ inputCss }
      />
    );
  }

//   <DatePicker
//   value={ date }
//   onChange={ change }
//   minimumDate={ props.min }
//   maximumDate={ props.max }
//   colorPrimary='#73B0F4'
//   wrapperClassName={ Style.dateWrapper }
//   renderInput={ customInput }
//   inputClassName={ Style.textbox }
// />
  
  return (
    <Fragment>

      { props.label && 
        <Label text={ props.label } />
      }

      <CssTextField
        type="date"
        defaultValue={ formatDateString(date) }
        className={ classes.root }
        variant="outlined"
        onChange={ change }
      />      

    </Fragment>
  )
}