/***
*
*   SOCIAL SHARING BUTTONS
*   A sharing wiget for Facebook, Twitter, Linkedin and email
*
*   PROPS
*   url: url of page to share
*   description: text for the socal media post.
*
**********/

import React from 'react';
import { Icon } from 'components/lib';
import Style from './social.module.scss';

export function Social(props){

  return (
    <ul className={ Style.social }>

      <li className={ Style.facebook }>
        <Icon color='light' image='facebook' className={ Style.icon }/>
        <a href={ 'http://www.facebook.com/share.php?u=' + props.url }>
        Share on Facebook
        </a>
      </li>

      <li className={ Style.twitter }>
        <Icon color='light' image='twitter' className={ Style.icon }/>
        <a href={ 'https://twitter.com/intent/tweet?original_referer=&amp;text=' +
        props.description + ':%20' + props.url }>
        Share this on Twitter
        </a>
      </li>

      <li className={ Style.linkedin }>
        <Icon color='light' image='linkedin' className={ Style.icon }/>
        <a href={ 'http://www.linkedin.com/shareArticle?mini=true&url=' +
        props.url + '&title=' + props.description }>
        Share this on LinkedIn
        </a>
      </li>

      <li className={ Style.mail }>
        <Icon color='light' image='mail' className={ Style.icon }/>
        <a href={ 'mailto:?subject=You must see this!&body=' +
        props.description + '%0D%0A%0D%0A' + props.url }>
        Email to a friend
        </a>
      </li>

    </ul>
  );
}
