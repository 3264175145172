/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useContext} from 'react';
import { Card, Table, Message, Grid, Animate, Feedback, useAPI, ViewContext, Button } from 'components/lib';
import { data } from '../../lib/data_reference';
import ReactGA from 'react-ga';

export function ReferralDashboard(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const context = useContext(ViewContext);

  // fetch  
  const user = useAPI('/api/user');

  if(!user.loading)
  {
    if((user.data != null) && (user?.data?.dm == null))
    {
      window.location = '/welcome';
    }
  }

  const referenceBreakdown = useAPI('/api/referral/breakdown');

  function copyURL(data, callback){
    var copyText = document.getElementById("ical_url_copy");
    copyText.value = 'https://optimisemyday.com/?ref=' + user.data.reference_code;
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    copyText.value = '';

    context.notification.show('Referral Link Copied!', 'success', true);
  }

  return (
    <Animate type='pop'>
      <Message
        title='Referral Module'
        text='Here is your REFERRAL Link. Copy the link and share it.'
        type='info'
      />
      { (user?.data) &&
        <input
          type='text'
          id='ical_url_copy'
          name='ical_url_copy'
          style={{border: '1px solid #000', width: '100%', textAlign: 'center', padding: '20px', fontSize: '1.2em', marginBottom: '20px'}}
          value={'https://optimisemyday.com/?ref=' + user.data.reference_code}
        />
      }
      <Grid cols='3'>
        <div>&nbsp;</div>
        <Button text='Copy Link' action={ copyURL }/>
        <div>&nbsp;</div>
      </Grid>

      <Card loading={referenceBreakdown?.loading}>
        <Table
          data={ 
            {
              header: [
              { name: 'year', title: 'YEAR', sort: false },
              { name: 'month', title: 'MONTH', sort: false },
              { name: 'count', title: 'TOTAL', sort: false }
            ],
            body: referenceBreakdown.data
          }
        }
        />
      </Card>      

      <Feedback />

    </Animate>
  );
}
