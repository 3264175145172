/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useState, useContext, useEffect} from 'react';
import { Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Loader, DateInput, Form, Animate, Feedback, useAPI, ViewContext, Tongshu, usePermissions, Button, Image } from 'components/lib';
import moment from 'moment';
import { data } from '../../lib/data_reference';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import Style from './tongshu.module.scss';

export function TongShu(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let ref = params.get('ref');

  const cookies = new Cookies();
  if(ref)
  {
    cookies.set('referral_code', ref, { path: '/' });  
  }

  const current_set_date = props.computedMatch.params.date?props.computedMatch.params.date:moment().format('YYYYMMDD');
  const [la_date, setDate] = useState(current_set_date);

  let current_date = la_date?moment(la_date):moment();
  let la_date_input = current_date.format('YYYY-MM-DD');

  const today_ts = useAPI(`/api/tongshu/get/${la_date}`);
  const user = useAPI('/api/user');

  function getTongShu(something, value)
  {
    current_date = moment(value);
    setDate(current_date.format('YYYYMMDD'));
    la_date_input = value;
  }

  function numberText(number)
  {
    var last_digit = (number<21)?number:number % 10;
    return (last_digit===1)?(number+"st"):((last_digit===2)?(number+"nd"):((last_digit===3)?(number+"rd"):(number+"th")));
  }

  function nextDay()
  {
    getTongShu(null, current_date.add(1, 'days').format('YYYY-MM-DD'));
  }

  function previousDay()
  {
    getTongShu(null, current_date.subtract(1, 'days').format('YYYY-MM-DD'));
  }

  function toDay()
  {
    getTongShu(null, moment().format('YYYY-MM-DD'));
  }


  return (
    <Animate type='pop'>
      <Card title='Date: ' loading={ today_ts.loading } float={true}>
        <DateInput
            label={''}
            key={'chart_date'}
            name={'chart_date'}
            type={'date'}
            required = {true}
            errorMessage = {'Please Date'}        
            value={la_date_input}
            onChange={ getTongShu }
            readonly={ false }
          />
        <Grid cols='3'>
          <Button omdBtnSize={true} text='← Yesterday' action={ previousDay }/>
          <Button omdBtnSize={true} text='Today' action={ toDay }/>
          <Button omdBtnSize={true} text='Tomorrow →' action={ nextDay }/>
        </Grid>
      </Card>
      <Grid cols='2'>
        <Stat
          loading={ today_ts?.loading }
          value={ today_ts?.data?.ts.day + " " + data.reference_month_full[today_ts?.data?.ts.month] + " " + today_ts?.data?.ts.year }
          label={ data.reference_week_day_full[today_ts?.data?.ts.day_no] + " (" + today_ts?.data?.ts.qi_zheng + ")" }
          icon='calendar'
        />
        <Stat
          loading={ today_ts?.loading }
          value={
            today_ts?.data?.ts.year_gan + "" + today_ts?.data?.ts.year_zhi + "年" + data.reference_number_chinese[today_ts?.data?.ts.lmonth] + "月" + data.reference_number_chinese[today_ts?.data?.ts.lday] + "日"}
            label={ data.reference_element_english[today_ts?.data?.ts.year_gan] + " " + data.reference_animal_english[today_ts?.data?.ts.year_zhi] + " Year " + numberText(today_ts?.data?.ts.lmonth) + " Month " + numberText(today_ts?.data?.ts.lday) + " Day"}
            icon='heart'
        />
      </Grid>
      <Grid cols='3'>
        <Card loading={today_ts?.loading}>
          { (today_ts?.data && user?.data) && 
            <Table
              center= { true }
              data={ 
                {
                  header: [
                  { name: 'day', title: 'DAY 日', sort: false },
                  { name: 'month', title: 'MONTH 月', sort: false },
                  { name: 'year', title: 'YEAR 年', sort: false }
                ],
                body:  [
                  {
                    day:today_ts?.data?.ts.day_gan + ' (' + data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.day_gan] + ')\n' + 
                    data.reference_element_icon[today_ts?.data?.ts.day_gan] + ' (' + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.day_gan]] + ")\n" + 
                    data.reference_element_english[today_ts?.data?.ts.day_gan] + ' (' + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.day_gan]] + ')', 
                    month:today_ts?.data?.ts.month_gan + ' (' + data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.month_gan] + ')\n' + 
                    data.reference_element_icon[today_ts?.data?.ts.month_gan] + ' (' + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.month_gan]] + ")\n" + 
                    data.reference_element_english[today_ts?.data?.ts.month_gan] + ' (' + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.month_gan]] + ')', 
                    year:today_ts?.data?.ts.year_gan + ' (' + data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.year_gan] + ')\n' + 
                    data.reference_element_icon[today_ts?.data?.ts.year_gan] + ' (' + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.year_gan]] + ")\n" + 
                    data.reference_element_english[today_ts?.data?.ts.year_gan] + ' (' + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.year_gan]] + ')', 
                  },
                  {
                    day:today_ts?.data?.ts.day_zhi + ' (' + data.reference_earthly_branch_element[today_ts?.data?.ts.day_zhi] + ') ' + '\n ' + 
                    data.reference_animal_icon[today_ts?.data?.ts.day_zhi] + ' (' + data.reference_element_icon[data.reference_earthly_branch_element[today_ts?.data?.ts.day_zhi]] + ') ' + '\n ' + 
                    data.reference_animal_english[today_ts?.data?.ts.day_zhi] + ' (' + data.reference_element_english[data.reference_earthly_branch_element[today_ts?.data?.ts.day_zhi]] + ')' + '', 
                    month:today_ts?.data?.ts.month_zhi + ' (' + data.reference_earthly_branch_element[today_ts?.data?.ts.month_zhi] + ') ' + '\n' + 
                    data.reference_animal_icon[today_ts?.data?.ts.month_zhi] + ' (' + data.reference_element_icon[data.reference_earthly_branch_element[today_ts?.data?.ts.month_zhi]] + ') ' + '\n ' + 
                    data.reference_animal_english[today_ts?.data?.ts.month_zhi] + ' (' + data.reference_element_english[data.reference_earthly_branch_element[today_ts?.data?.ts.month_zhi]] + ')' + '', 
                    year:today_ts?.data?.ts.year_zhi + ' (' + data.reference_earthly_branch_element[today_ts?.data?.ts.year_zhi] + ') ' + '\n' + 
                    data.reference_animal_icon[today_ts?.data?.ts.year_zhi] + ' (' + data.reference_element_icon[data.reference_earthly_branch_element[today_ts?.data?.ts.year_zhi]] + ') ' + '\n ' + 
                    data.reference_animal_english[today_ts?.data?.ts.year_zhi] + ' (' + data.reference_element_english[data.reference_earthly_branch_element[today_ts?.data?.ts.year_zhi]] + ') ' + '', 
                  },
                  {
                    day:data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][1] + " [" + data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][0] + "] " + data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][2] + "\n" + 
                    data.reference_element_icon[data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][1]]+ " [" + data.reference_element_icon[data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][0]]+ "] " + data.reference_element_icon[data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][2]] + "\n" + 
                    data.reference_element_english[data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][1]] + " [" + data.reference_element_english[data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][0]] + "] " + data.reference_element_english[data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][2]], 
                    month:data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][1] + " [" + data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][0] + "] " + data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][2] + "\n" + 
                    data.reference_element_icon[data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][1]]+ " [" + data.reference_element_icon[data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][0]]+ "] " + data.reference_element_icon[data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][2]] + "\n" + 
                    data.reference_element_english[data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][1]] + " [" + data.reference_element_english[data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][0]] + "] " + data.reference_element_english[data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][2]], 
                    year:data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][1] + " [" + data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][0] + "] " + data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][2] + "\n" + 
                    data.reference_element_icon[data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][1]]+ " [" + data.reference_element_icon[data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][0]]+ "] " + data.reference_element_icon[data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][2]] + "\n" + 
                    data.reference_element_english[data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][1]] + " [" + data.reference_element_english[data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][0]] + "] " + data.reference_element_english[data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][2]], 
                  },
                  {
                    day:data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][1]] + " [" + data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][0]] + "] " + data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][2]] + "\n" + 
                    data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][1]]]+ " [" + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][0]]]+ "] " + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][2]]] + "\n" + 
                    data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][1]]] + " [" + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][0]]] + "] " + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][2]]], 
                    month:data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][1]] + " [" + data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][0]] + "] " + data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][2]] + "\n" + 
                    data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][1]]]+ " [" + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][0]]]+ "] " + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][2]]] + "\n" + 
                    data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][1]]] + " [" + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][0]]] + "] " + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.month_zhi][2]]], 
                    year:data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][1]] + " [" + data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][0]] + "] " + data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][2]] + "\n" + 
                    data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][1]]]+ " [" + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][0]]]+ "] " + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][2]]] + "\n" + 
                    data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][1]]] + " [" + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][0]]] + "] " + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.year_zhi][2]]], 
                  },
                ]
              }
            }
            />
          }
          <br/>
          <small>* 10 God is based on your own Day Master, instead of current day master *</small>
        </Card>
        <Card title='Personalise Data' loading={today_ts?.loading}>
          { (today_ts?.data && user?.data) && 
            <Table
              data={ 
                {
                  body:  [
                    {
                      title: "Today 10 HS God", 
                      infor: (data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.day_gan]) + " " + data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.day_gan]] + " " + data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.day_gan]]
                    },
                    {
                      title: "Today 10 EB God", 
                      infor: (
                        data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][0]] + 
                        data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][0]]] + 
                        data.reference_10_god_english[data.reference_bz_10_god[user?.data?.dm][data.reference_hidden_branch[today_ts?.data?.ts.day_zhi][0]]] 
                      )
                    },
                    {
                      title: "Other Information", 
                      infor: (
                        (today_ts?.data?.current_fan_yin?(today_ts?.data?.current_fan_yin + ((today_ts?.data?.current_fan_yin==="反吟")?" FanYin ":"") + data.reference_fy_icon[(today_ts?.data?.current_fan_yin==="反吟")?"FanYin":""] + ","):"") +
                        (today_ts?.data?.current_fu_yin?(today_ts?.data?.current_fu_yin + ((today_ts?.data?.current_fu_yin==="伏吟")?" FuYin ":"") + data.reference_fy_icon[(today_ts?.data?.current_fu_yin==="伏吟")?"FuYin":""] + ","):"") +
                      ((today_ts?.data?.ts.month_breaker)?"日破 Day Breaker [M💔],":"") +
                      ((today_ts?.data?.ts.year_breaker)?"年破 Year Breaker [Y💔],":"") + 
                      (today_ts?.data?.pro.length > 0?'\n':'') +
                      today_ts?.data?.pro.map(e => e.title).join(',\n') + (today_ts?.data?.pro.length > 0?',' + (today_ts?.data?.sim.length > 0?'\n':''):'') +
                      today_ts?.data?.sim.map(e => e.title).join(',\n') + (today_ts?.data?.sim.length > 0?',':'')
                      ).slice(0,-1)
                    },
                  ]
                }
              }
            />
          }
        </Card>
        { (today_ts?.data && user?.data) && 
          <Card title='Suit and Avoid' loading={today_ts?.loading}>
            <div style={{color:'#ff0000'}}>{today_ts?.data?.ts.day_yi}</div><br/>
            <div style={{color:'#000000'}}>{today_ts?.data?.ts.day_ji}</div><br/>
            <div style={{color:'#ff0000'}}>{data.convertSuitsToEnglish(today_ts?.data?.ts.day_yi)}</div><br/>
            <div style={{color:'#000000'}}>{data.convertSuitsToEnglish(today_ts?.data?.ts.day_ji)}</div>
          </Card>
        }
      </Grid>
      <Grid cols='6'>
        <Card title='Day Officers' loading={today_ts?.loading}>
          <div>{today_ts?.data?.ts.zhi_xing + " " + data.reference_day_officers_icon[today_ts?.data?.ts.zhi_xing]}</div>
          <div>{data.reference_day_officers_english[today_ts?.data?.ts.zhi_xing]}</div>
        </Card>
        <Card title='Today Breaker' loading={today_ts?.loading}>
          <div>{data.reference_animal_clash[today_ts?.data?.ts.day_zhi] + " "  + data.reference_animal_icon[data.reference_animal_clash[today_ts?.data?.ts.day_zhi]]}</div>
          <div>{data.reference_animal_english[data.reference_animal_clash[today_ts?.data?.ts.day_zhi]]}</div>
        </Card>
        <Card title='Today Hexagram' loading={today_ts?.loading}>
          <div><Image className={ Style.tongshu_image } source={ data.reference_60_jz_vs_hexagram[today_ts?.data?.ts.day_gan + today_ts?.data?.ts.day_zhi]?.image_url } /></div>
          <div>{data.reference_60_jz_vs_hexagram[today_ts?.data?.ts.day_gan + today_ts?.data?.ts.day_zhi]?.name_ch}</div>
          <div>{data.reference_60_jz_vs_hexagram[today_ts?.data?.ts.day_gan + today_ts?.data?.ts.day_zhi]?.name}</div>
        </Card>
        <Card title='Yellow/Black Belt' loading={today_ts?.loading}>
          <div>{today_ts?.data?.ts.day_tian_shen + "(" + today_ts?.data?.ts.day_tian_shen_type +  ")" + today_ts?.data?.ts.day_tian_shen_luck + " " + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.day_tian_shen] + " " + data.reference_yellow_belt_icon[today_ts?.data?.ts.day_tian_shen_type] +  " " + data.reference_luck_icon[today_ts?.data?.ts.day_tian_shen_luck]}</div>
          <div>{data.reference_yellow_belt_star_english[today_ts?.data?.ts.day_tian_shen] + "(" + data.reference_yellow_belt_english[today_ts?.data?.ts.day_tian_shen_type] +  ")" + data.reference_luck_english[today_ts?.data?.ts.day_tian_shen_luck]}</div>
        </Card>
        <Card title='Stars' loading={today_ts?.loading}>
          <div style={{color:'#ff0000'}}>{today_ts?.data?.ts.day_ji_shen}</div>
          <div style={{color:'#000000'}}>{today_ts?.data?.ts.day_xiong_sha}</div>
        </Card>
        <Card title='28 Constellation' loading={today_ts?.loading}>
          <div>{today_ts?.data?.ts.constellation_28_xiu + " " + today_ts?.data?.ts.constellation_28_xiu_animal +  " " + today_ts?.data?.ts.constellation_28_xiu_luck + " " + data.reference_28_constellations_icon[today_ts?.data?.ts.constellation_28_xiu] + " " + data.reference_28_constellations_animal_icon[today_ts?.data?.ts.constellation_28_xiu_animal] +  " " + data.reference_luck_icon[today_ts?.data?.ts.constellation_28_xiu_luck]}</div>
          <div>{data.reference_28_constellations_english[today_ts?.data?.ts.constellation_28_xiu] + " " + data.reference_28_constellations_animal_english[today_ts?.data?.ts.constellation_28_xiu_animal] +  " " + data.reference_luck_english[today_ts?.data?.ts.constellation_28_xiu_luck]}</div>
        </Card>
      </Grid>
      <Grid cols='2'>
        <Card title='Qi Men' loading={today_ts?.loading}>
        { (today_ts?.data && user?.data) && 
            <Table
              data={ 
                {
                  header: [
                    { name: 'day', title: '', sort: false },
                    { name: 'noble', title: 'Noble', sort: false },
                    { name: 'wealth', title: 'Wealth', sort: false },
                    { name: 'health', title: 'Health', sort: false },
                    { name: 'relationship', title: 'Relationship', sort: false }
                  ],
                  body:  [
                    {
                      day: "Today", 
                      noble: today_ts?.data?.current_chief_box + " " + data.reference_direction_chinese_to_index[today_ts?.data?.current_chief_box].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.current_chief_box]],
                      wealth: today_ts?.data?.current_life_box+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.current_life_box].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.current_life_box]],
                      health: today_ts?.data?.current_rest_box+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.current_rest_box].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.current_rest_box]],
                      relationship: today_ts?.data?.current_harmony_box+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.current_harmony_box].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.current_harmony_box]]
                    },
                    {
                      title: "Destiny", 
                      noble: today_ts?.data?.destiny_chief_box + " " + data.reference_direction_chinese_to_index[today_ts?.data?.destiny_chief_box].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.destiny_chief_box]],
                      wealth: today_ts?.data?.destiny_life_box+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.destiny_life_box].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.destiny_life_box]],
                      health: today_ts?.data?.destiny_rest_box+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.destiny_rest_box].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.destiny_rest_box]],
                      relationship: today_ts?.data?.destiny_harmony_box+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.destiny_harmony_box].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.destiny_harmony_box]]
                    },
                  ]
                }
              }
            />
          }
        </Card>

        <Card title='Important Directions' loading={today_ts?.loading}>
        { (today_ts?.data && user?.data) && 
            <Table
              data={ 
                {
                  header: [
                    { name: 'noble', title: 'Nobleman', sort: false },
                    { name: 'wealth', title: 'Happiness', sort: false },
                    { name: 'health', title: 'Fortune', sort: false },
                    { name: 'relationship', title: 'Wealth', sort: false }
                  ],
                  body:  [
                    {
                      noble: "YANG:"+today_ts?.data?.ts.day_position_yang_gui_desc + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_yang_gui_desc].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_yang_gui_desc]],
                      wealth: today_ts?.data?.ts.day_position_xi_desc+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_xi_desc].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_xi_desc]],
                      health: today_ts?.data?.ts.day_position_fu_desc+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_fu_desc].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_fu_desc]],
                      relationship: today_ts?.data?.ts.day_position_cai_desc+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_cai_desc].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_cai_desc]]
                    },
                    {
                      noble: "YIN:"+today_ts?.data?.ts.day_position_yin_gui_desc + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_yin_gui_desc].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.day_position_yin_gui_desc]],
                      wealth: "",
                      health: "",
                      relationship: ""
                    }
                  ]
                }
              }
            />
          }
        </Card>
      </Grid>
      <Grid cols="1">
        <Card title='Hour Forecast' loading={today_ts?.loading} mobileDisable = { true }>
          { (today_ts?.data && user?.data) && 
            <Table
              mobileDisable = { true }
              center={true}
              data={ 
                {
                  header: [
                    { name: 'title', title: '', sort: false },
                    { name: 'hr_zhi', title: '23:00', sort: false },
                    { name: 'hr_chou', title: '01:00', sort: false },
                    { name: 'hr_yin', title: '03:00', sort: false },
                    { name: 'hr_mao', title: '05:00', sort: false },
                    { name: 'hr_chen', title: '07:00', sort: false },
                    { name: 'hr_si', title: '09:00', sort: false }
                  ],
                  body:  [
                    {
                      title: "Hour",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_actual + '\n' 
                              + data.reference_element_english[today_ts?.data?.ts.hour_zhi_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_zhi_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_zhi_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_zhi_star_actual[1]],
                      hr_chou: today_ts?.data?.ts.hour_chou_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_chou_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_chou_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_chou_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_chou_star_actual[1]],
                      hr_yin: today_ts?.data?.ts.hour_yin_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_yin_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_yin_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_yin_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_yin_star_actual[1]],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_mao_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_mao_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_mao_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_mao_star_actual[1]],
                      hr_chen: today_ts?.data?.ts.hour_chen_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_chen_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_chen_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_chen_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_chen_star_actual[1]],
                      hr_si: today_ts?.data?.ts.hour_si_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_si_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_si_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_si_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_si_star_actual[1]],
                    },
                    {
                      title: "Star",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_stars,
                      hr_chou: today_ts?.data?.ts.hour_chou_star_stars,
                      hr_yin: today_ts?.data?.ts.hour_yin_star_stars,
                      hr_mao: today_ts?.data?.ts.hour_mao_star_stars,
                      hr_chen: today_ts?.data?.ts.hour_chen_star_stars,
                      hr_si: today_ts?.data?.ts.hour_si_star_stars,
                    },
                    {
                      title: "Raising Nobleman",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_zhi_star_raising_nobleman],
                      hr_chou: today_ts?.data?.ts.hour_chou_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_chou_star_raising_nobleman],
                      hr_yin: today_ts?.data?.ts.hour_yin_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_yin_star_raising_nobleman],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_mao_star_raising_nobleman],
                      hr_chen: today_ts?.data?.ts.hour_chen_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_chen_star_raising_nobleman],
                      hr_si: today_ts?.data?.ts.hour_si_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_si_star_raising_nobleman],
                    },
                    {
                      title: "Other Information",
                      hr_zhi: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['23:00'].map(e => e.title).join(',\n'):'',
                      hr_chou: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['01:00'].map(e => e.title).join(',\n'):'',
                      hr_yin: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['03:00'].map(e => e.title).join(',\n'):'',
                      hr_mao: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['05:00'].map(e => e.title).join(',\n'):'',
                      hr_chen: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['07:00'].map(e => e.title).join(',\n'):'',
                      hr_si: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['09:00'].map(e => e.title).join(',\n'):'',
                    },
                    {
                      title: "Yellow / Black Belt",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_zhi_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_zhi_star_time_tian_shen],
                      hr_chou: today_ts?.data?.ts.hour_chou_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_chou_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_chou_star_time_tian_shen],
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_yin_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_yin_star_time_tian_shen],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_mao_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_mao_star_time_tian_shen],
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_chen_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_chen_star_time_tian_shen],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_si_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_si_star_time_tian_shen],
                    },
                    {
                      title: "",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_zhi_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_zhi_star_time_tian_shen_type],
                      hr_chou: today_ts?.data?.ts.hour_chou_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_chou_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_chou_star_time_tian_shen_type],
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_yin_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_yin_star_time_tian_shen_type],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_mao_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_mao_star_time_tian_shen_type],
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_chen_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_chen_star_time_tian_shen_type],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_si_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_si_star_time_tian_shen_type],
                    },
                    {
                      title: "",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_zhi_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_zhi_star_time_tian_shen_luck],
                      hr_chou: today_ts?.data?.ts.hour_chou_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_chou_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_chou_star_time_tian_shen_luck],
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_yin_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_yin_star_time_tian_shen_luck],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_mao_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_mao_star_time_tian_shen_luck],
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_chen_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_chen_star_time_tian_shen_luck],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_si_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_si_star_time_tian_shen_luck],
                    },
                    {
                      title: "NaYin",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_na_yin,
                      hr_chou: today_ts?.data?.ts.hour_chou_star_time_na_yin,
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_na_yin,
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_na_yin,
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_na_yin,
                      hr_si: today_ts?.data?.ts.hour_si_star_time_na_yin,
                    },
                    {
                      title: "Clash",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_chong_gan + today_ts?.data?.ts.hour_zhi_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_zhi_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_zhi_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_zhi_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_zhi_star_time_chong],
                      hr_chou: today_ts?.data?.ts.hour_chou_star_time_chong_gan + today_ts?.data?.ts.hour_chou_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_chou_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_chou_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_chou_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_chou_star_time_chong],
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_chong_gan + today_ts?.data?.ts.hour_yin_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_yin_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_yin_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_yin_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_yin_star_time_chong],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_chong_gan + today_ts?.data?.ts.hour_mao_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_mao_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_mao_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_mao_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_mao_star_time_chong],
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_chong_gan + today_ts?.data?.ts.hour_chen_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_chen_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_chen_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_chen_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_chen_star_time_chong],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_chong_gan + today_ts?.data?.ts.hour_si_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_si_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_si_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_si_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_si_star_time_chong],
                    },
                    {
                      title: "Halting (煞)",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_zhi_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_zhi_star_time_sha]],
                      hr_chou: today_ts?.data?.ts.hour_chou_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_chou_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_chou_star_time_sha]],
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_yin_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_yin_star_time_sha]],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_mao_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_mao_star_time_sha]],
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_chen_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_chen_star_time_sha]],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_si_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_si_star_time_sha]],
                    },
                    {
                      title: "Suits",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_yi,
                      hr_chou: today_ts?.data?.ts.hour_chou_star_time_yi,
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_yi,
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_yi,
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_yi,
                      hr_si: today_ts?.data?.ts.hour_si_star_time_yi,
                    },
                    {
                      title: "",
                      hr_zhi: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_zhi_star_time_yi),
                      hr_chou: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_chou_star_time_yi),
                      hr_yin: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_yin_star_time_yi),
                      hr_mao: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_mao_star_time_yi),
                      hr_chen: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_chen_star_time_yi),
                      hr_si: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_si_star_time_yi),
                    },
                    {
                      title: "Avoid",
                      hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_ji,
                      hr_chou: today_ts?.data?.ts.hour_chou_star_time_ji,
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_ji,
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_ji,
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_ji,
                      hr_si: today_ts?.data?.ts.hour_si_star_time_ji,
                    },
                    {
                      title: "",
                      hr_zhi: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_zhi_star_time_ji),
                      hr_chou: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_chou_star_time_ji),
                      hr_yin: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_yin_star_time_ji),
                      hr_mao: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_mao_star_time_ji),
                      hr_chen: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_chen_star_time_ji),
                      hr_si: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_si_star_time_ji),
                    },
                  ]
                }
              }
            />
          }
        </Card>
        <Card title='Hour Forecast' loading={today_ts?.loading} mobileDisable = { true }>
          { (today_ts?.data && user?.data) && 
              <Table
                mobileDisable = { true }
                center={true}
                data={ 
                  {
                    header: [
                      { name: 'title', title: '', sort: false },
                      { name: 'hr_wu', title: '11:00', sort: false },
                      { name: 'hr_wei', title: '13:00', sort: false },
                      { name: 'hr_shen', title: '15:00', sort: false },
                      { name: 'hr_you', title: '17:00', sort: false },
                      { name: 'hr_xu', title: '19:00', sort: false },
                      { name: 'hr_hai', title: '21:00', sort: false },
                    ],
                    body:  [
                      {
                        title: "Hour",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_actual + '\n'
                                + data.reference_element_english[today_ts?.data?.ts.hour_wu_star_actual[0]] + " " 
                                + data.reference_animal_english[today_ts?.data?.ts.hour_wu_star_actual[1]] + "\n"  
                                + data.reference_element_icon[today_ts?.data?.ts.hour_wu_star_actual[0]] 
                                + data.reference_animal_icon[today_ts?.data?.ts.hour_wu_star_actual[1]],
                        hr_wei: today_ts?.data?.ts.hour_wei_star_actual + '\n'
                                + data.reference_element_english[today_ts?.data?.ts.hour_wei_star_actual[0]] + " " 
                                + data.reference_animal_english[today_ts?.data?.ts.hour_wei_star_actual[1]] + "\n"  
                                + data.reference_element_icon[today_ts?.data?.ts.hour_wei_star_actual[0]] 
                                + data.reference_animal_icon[today_ts?.data?.ts.hour_wei_star_actual[1]],
                        hr_shen: today_ts?.data?.ts.hour_shen_star_actual + '\n'
                                + data.reference_element_english[today_ts?.data?.ts.hour_shen_star_actual[0]] + " " 
                                + data.reference_animal_english[today_ts?.data?.ts.hour_shen_star_actual[1]] + "\n"  
                                + data.reference_element_icon[today_ts?.data?.ts.hour_shen_star_actual[0]] 
                                + data.reference_animal_icon[today_ts?.data?.ts.hour_shen_star_actual[1]],
                        hr_you: today_ts?.data?.ts.hour_you_star_actual + '\n'
                                + data.reference_element_english[today_ts?.data?.ts.hour_you_star_actual[0]] + " " 
                                + data.reference_animal_english[today_ts?.data?.ts.hour_you_star_actual[1]] + "\n"  
                                + data.reference_element_icon[today_ts?.data?.ts.hour_you_star_actual[0]] 
                                + data.reference_animal_icon[today_ts?.data?.ts.hour_you_star_actual[1]],
                        hr_xu: today_ts?.data?.ts.hour_xu_star_actual + '\n'
                                + data.reference_element_english[today_ts?.data?.ts.hour_xu_star_actual[0]] + " " 
                                + data.reference_animal_english[today_ts?.data?.ts.hour_xu_star_actual[1]] + "\n"  
                                + data.reference_element_icon[today_ts?.data?.ts.hour_xu_star_actual[0]] 
                                + data.reference_animal_icon[today_ts?.data?.ts.hour_xu_star_actual[1]],
                        hr_hai: today_ts?.data?.ts.hour_hai_star_actual + '\n'
                                + data.reference_element_english[today_ts?.data?.ts.hour_hai_star_actual[0]] + " " 
                                + data.reference_animal_english[today_ts?.data?.ts.hour_hai_star_actual[1]] + "\n"  
                                + data.reference_element_icon[today_ts?.data?.ts.hour_hai_star_actual[0]] 
                                + data.reference_animal_icon[today_ts?.data?.ts.hour_hai_star_actual[1]],
                      },
                      {
                        title: "Star",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_stars,
                        hr_wei: today_ts?.data?.ts.hour_wei_star_stars,
                        hr_shen: today_ts?.data?.ts.hour_shen_star_stars,
                        hr_you: today_ts?.data?.ts.hour_you_star_stars,
                        hr_xu: today_ts?.data?.ts.hour_xu_star_stars,
                        hr_hai: today_ts?.data?.ts.hour_hai_star_stars
                      },
                      {
                        title: "Raising Nobleman",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_wu_star_raising_nobleman],
                        hr_wei: today_ts?.data?.ts.hour_wei_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_wei_star_raising_nobleman],
                        hr_shen: today_ts?.data?.ts.hour_shen_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_shen_star_raising_nobleman],
                        hr_you: today_ts?.data?.ts.hour_you_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_you_star_raising_nobleman],
                        hr_xu: today_ts?.data?.ts.hour_xu_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_xu_star_raising_nobleman],
                        hr_hai: today_ts?.data?.ts.hour_hai_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_hai_star_raising_nobleman]
                      },
                      {
                        title: "Other Information",
                        hr_wu: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['11:00'].map(e => e.title).join(',\n'):'',
                        hr_wei: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['13:00'].map(e => e.title).join(',\n'):'',
                        hr_shen: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['15:00'].map(e => e.title).join(',\n'):'',
                        hr_you: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['17:00'].map(e => e.title).join(',\n'):'',
                        hr_xu: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['19:00'].map(e => e.title).join(',\n'):'',
                        hr_hai: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['21:00'].map(e => e.title).join(',\n'):''
                      },
                      {
                        title: "Yellow / Black Belt",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_wu_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_wu_star_time_tian_shen],
                        hr_wei: today_ts?.data?.ts.hour_wei_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_wei_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_wei_star_time_tian_shen],
                        hr_shen: today_ts?.data?.ts.hour_shen_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_shen_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_shen_star_time_tian_shen],
                        hr_you: today_ts?.data?.ts.hour_you_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_you_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_you_star_time_tian_shen],
                        hr_xu: today_ts?.data?.ts.hour_xu_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_xu_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_xu_star_time_tian_shen],
                        hr_hai: today_ts?.data?.ts.hour_hai_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_hai_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_hai_star_time_tian_shen]
                      },
                      {
                        title: "",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_wu_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_wu_star_time_tian_shen_type],
                        hr_wei: today_ts?.data?.ts.hour_wei_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_wei_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_wei_star_time_tian_shen_type],
                        hr_shen: today_ts?.data?.ts.hour_shen_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_shen_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_shen_star_time_tian_shen_type],
                        hr_you: today_ts?.data?.ts.hour_you_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_you_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_you_star_time_tian_shen_type],
                        hr_xu: today_ts?.data?.ts.hour_xu_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_xu_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_xu_star_time_tian_shen_type],
                        hr_hai: today_ts?.data?.ts.hour_hai_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_hai_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_hai_star_time_tian_shen_type]
                      },
                      {
                        title: "",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_wu_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_wu_star_time_tian_shen_luck],
                        hr_wei: today_ts?.data?.ts.hour_wei_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_wei_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_wei_star_time_tian_shen_luck],
                        hr_shen: today_ts?.data?.ts.hour_shen_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_shen_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_shen_star_time_tian_shen_luck],
                        hr_you: today_ts?.data?.ts.hour_you_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_you_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_you_star_time_tian_shen_luck],
                        hr_xu: today_ts?.data?.ts.hour_xu_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_xu_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_xu_star_time_tian_shen_luck],
                        hr_hai: today_ts?.data?.ts.hour_hai_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_hai_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_hai_star_time_tian_shen_luck]
                      },
                      {
                        title: "NaYin",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_time_na_yin,
                        hr_wei: today_ts?.data?.ts.hour_wei_star_time_na_yin,
                        hr_shen: today_ts?.data?.ts.hour_shen_star_time_na_yin,
                        hr_you: today_ts?.data?.ts.hour_you_star_time_na_yin,
                        hr_xu: today_ts?.data?.ts.hour_xu_star_time_na_yin,
                        hr_hai: today_ts?.data?.ts.hour_hai_star_time_na_yin
                      },
                      {
                        title: "Clash",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_time_chong_gan + today_ts?.data?.ts.hour_wu_star_time_chong  + '\n' 
                        + data.reference_element_english[today_ts?.data?.ts.hour_wu_star_time_chong_gan] + " " 
                        + data.reference_animal_english[today_ts?.data?.ts.hour_wu_star_time_chong] + "\n"  
                        + data.reference_element_icon[today_ts?.data?.ts.hour_wu_star_time_chong_gan] 
                        + data.reference_animal_icon[today_ts?.data?.ts.hour_wu_star_time_chong],
                        hr_wei: today_ts?.data?.ts.hour_wei_star_time_chong_gan + today_ts?.data?.ts.hour_wei_star_time_chong  + '\n' 
                        + data.reference_element_english[today_ts?.data?.ts.hour_wei_star_time_chong_gan] + " " 
                        + data.reference_animal_english[today_ts?.data?.ts.hour_wei_star_time_chong] + "\n"  
                        + data.reference_element_icon[today_ts?.data?.ts.hour_wei_star_time_chong_gan] 
                        + data.reference_animal_icon[today_ts?.data?.ts.hour_wei_star_time_chong],
                        hr_shen: today_ts?.data?.ts.hour_shen_star_time_chong_gan + today_ts?.data?.ts.hour_shen_star_time_chong  + '\n' 
                        + data.reference_element_english[today_ts?.data?.ts.hour_shen_star_time_chong_gan] + " " 
                        + data.reference_animal_english[today_ts?.data?.ts.hour_shen_star_time_chong] + "\n"  
                        + data.reference_element_icon[today_ts?.data?.ts.hour_shen_star_time_chong_gan] 
                        + data.reference_animal_icon[today_ts?.data?.ts.hour_shen_star_time_chong],
                        hr_you: today_ts?.data?.ts.hour_you_star_time_chong_gan + today_ts?.data?.ts.hour_you_star_time_chong  + '\n' 
                        + data.reference_element_english[today_ts?.data?.ts.hour_you_star_time_chong_gan] + " " 
                        + data.reference_animal_english[today_ts?.data?.ts.hour_you_star_time_chong] + "\n"  
                        + data.reference_element_icon[today_ts?.data?.ts.hour_you_star_time_chong_gan] 
                        + data.reference_animal_icon[today_ts?.data?.ts.hour_you_star_time_chong],
                        hr_xu: today_ts?.data?.ts.hour_xu_star_time_chong_gan + today_ts?.data?.ts.hour_xu_star_time_chong  + '\n' 
                        + data.reference_element_english[today_ts?.data?.ts.hour_xu_star_time_chong_gan] + " " 
                        + data.reference_animal_english[today_ts?.data?.ts.hour_xu_star_time_chong] + "\n"  
                        + data.reference_element_icon[today_ts?.data?.ts.hour_xu_star_time_chong_gan] 
                        + data.reference_animal_icon[today_ts?.data?.ts.hour_xu_star_time_chong],
                        hr_hai: today_ts?.data?.ts.hour_hai_star_time_chong_gan + today_ts?.data?.ts.hour_hai_star_time_chong  + '\n' 
                        + data.reference_element_english[today_ts?.data?.ts.hour_hai_star_time_chong_gan] + " " 
                        + data.reference_animal_english[today_ts?.data?.ts.hour_hai_star_time_chong] + "\n"  
                        + data.reference_element_icon[today_ts?.data?.ts.hour_hai_star_time_chong_gan] 
                        + data.reference_animal_icon[today_ts?.data?.ts.hour_hai_star_time_chong]
                      },
                      {
                        title: "Halting (煞)",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_wu_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_wu_star_time_sha]],
                        hr_wei: today_ts?.data?.ts.hour_wei_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_wei_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_wei_star_time_sha]],
                        hr_shen: today_ts?.data?.ts.hour_shen_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_shen_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_shen_star_time_sha]],
                        hr_you: today_ts?.data?.ts.hour_you_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_you_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_you_star_time_sha]],
                        hr_xu: today_ts?.data?.ts.hour_xu_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_xu_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_xu_star_time_sha]],
                        hr_hai: today_ts?.data?.ts.hour_hai_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_hai_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_hai_star_time_sha]]
                      },
                      {
                        title: "Suits",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_time_yi,
                        hr_wei: today_ts?.data?.ts.hour_wei_star_time_yi,
                        hr_shen: today_ts?.data?.ts.hour_shen_star_time_yi,
                        hr_you: today_ts?.data?.ts.hour_you_star_time_yi,
                        hr_xu: today_ts?.data?.ts.hour_xu_star_time_yi,
                        hr_hai: today_ts?.data?.ts.hour_hai_star_time_yi
                      },
                      {
                        title: "",
                        hr_wu: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_wu_star_time_yi),
                        hr_wei: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_wei_star_time_yi),
                        hr_shen: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_shen_star_time_yi),
                        hr_you: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_you_star_time_yi),
                        hr_xu: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_xu_star_time_yi),
                        hr_hai: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_hai_star_time_yi)
                      },
                      {
                        title: "Avoid",
                        hr_wu: today_ts?.data?.ts.hour_wu_star_time_ji,
                        hr_wei: today_ts?.data?.ts.hour_wei_star_time_ji,
                        hr_shen: today_ts?.data?.ts.hour_shen_star_time_ji,
                        hr_you: today_ts?.data?.ts.hour_you_star_time_ji,
                        hr_xu: today_ts?.data?.ts.hour_xu_star_time_ji,
                        hr_hai: today_ts?.data?.ts.hour_hai_star_time_ji
                      },
                      {
                        title: "",
                        hr_wu: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_wu_star_time_ji),
                        hr_wei: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_wei_star_time_ji),
                        hr_shen: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_shen_star_time_ji),
                        hr_you: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_you_star_time_ji),
                        hr_xu: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_xu_star_time_ji),
                        hr_hai: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_hai_star_time_ji)
                      },
                    ]
                  }
                }
              />
            }
          </Card>

        <Card title='Hour Forecast' loading={today_ts?.loading} desktopDisable = { true }>
        { (today_ts?.data && user?.data) && 
          <Table
            desktopDisable = { true }
            center={true}
            data={ 
              {
                header: [
                  { name: 'title', title: '', sort: false },
                  { name: 'hr_zhi', title: '23:00', sort: false },
                  { name: 'hr_chou', title: '01:00', sort: false },
                ],
                body:  [
                  {
                    title: "Hour",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_actual + '\n' 
                            + data.reference_element_english[today_ts?.data?.ts.hour_zhi_star_actual[0]] + " " 
                            + data.reference_animal_english[today_ts?.data?.ts.hour_zhi_star_actual[1]] + "\n"  
                            + data.reference_element_icon[today_ts?.data?.ts.hour_zhi_star_actual[0]] 
                            + data.reference_animal_icon[today_ts?.data?.ts.hour_zhi_star_actual[1]],
                    hr_chou: today_ts?.data?.ts.hour_chou_star_actual + '\n'
                            + data.reference_element_english[today_ts?.data?.ts.hour_chou_star_actual[0]] + " " 
                            + data.reference_animal_english[today_ts?.data?.ts.hour_chou_star_actual[1]] + "\n"  
                            + data.reference_element_icon[today_ts?.data?.ts.hour_chou_star_actual[0]] 
                            + data.reference_animal_icon[today_ts?.data?.ts.hour_chou_star_actual[1]],
                  },
                  {
                    title: "Star",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_stars,
                    hr_chou: today_ts?.data?.ts.hour_chou_star_stars,
                  },
                  {
                    title: "Raising Nobleman",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_zhi_star_raising_nobleman],
                    hr_chou: today_ts?.data?.ts.hour_chou_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_chou_star_raising_nobleman],
                  },
                  {
                    title: "Other Information",
                    hr_zhi: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['23:00'].map(e => e.title).join(',\n'):'',
                    hr_chou: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['01:00'].map(e => e.title).join(',\n'):'',
                  },
                  {
                    title: "Yellow / Black Belt",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_zhi_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_zhi_star_time_tian_shen],
                    hr_chou: today_ts?.data?.ts.hour_chou_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_chou_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_chou_star_time_tian_shen],
                  },
                  {
                    title: "",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_zhi_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_zhi_star_time_tian_shen_type],
                    hr_chou: today_ts?.data?.ts.hour_chou_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_chou_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_chou_star_time_tian_shen_type],
                  },
                  {
                    title: "",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_zhi_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_zhi_star_time_tian_shen_luck],
                    hr_chou: today_ts?.data?.ts.hour_chou_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_chou_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_chou_star_time_tian_shen_luck],
                  },
                  {
                    title: "NaYin",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_na_yin,
                    hr_chou: today_ts?.data?.ts.hour_chou_star_time_na_yin,
                  },
                  {
                    title: "Clash",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_chong_gan + today_ts?.data?.ts.hour_zhi_star_time_chong  + '\n' 
                    + data.reference_element_english[today_ts?.data?.ts.hour_zhi_star_time_chong_gan] + " " 
                    + data.reference_animal_english[today_ts?.data?.ts.hour_zhi_star_time_chong] + "\n"  
                    + data.reference_element_icon[today_ts?.data?.ts.hour_zhi_star_time_chong_gan] 
                    + data.reference_animal_icon[today_ts?.data?.ts.hour_zhi_star_time_chong],
                    hr_chou: today_ts?.data?.ts.hour_chou_star_time_chong_gan + today_ts?.data?.ts.hour_chou_star_time_chong  + '\n' 
                    + data.reference_element_english[today_ts?.data?.ts.hour_chou_star_time_chong_gan] + " " 
                    + data.reference_animal_english[today_ts?.data?.ts.hour_chou_star_time_chong] + "\n"  
                    + data.reference_element_icon[today_ts?.data?.ts.hour_chou_star_time_chong_gan] 
                    + data.reference_animal_icon[today_ts?.data?.ts.hour_chou_star_time_chong],
                  },
                  {
                    title: "Halting (煞)",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_zhi_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_zhi_star_time_sha]],
                    hr_chou: today_ts?.data?.ts.hour_chou_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_chou_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_chou_star_time_sha]],
                  },
                  {
                    title: "Suits",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_yi,
                    hr_chou: today_ts?.data?.ts.hour_chou_star_time_yi,
                  },
                  {
                    title: "",
                    hr_zhi: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_zhi_star_time_yi),
                    hr_chou: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_chou_star_time_yi),
                  },
                  {
                    title: "Avoid",
                    hr_zhi: today_ts?.data?.ts.hour_zhi_star_time_ji,
                    hr_chou: today_ts?.data?.ts.hour_chou_star_time_ji,
                  },
                  {
                    title: "",
                    hr_zhi: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_zhi_star_time_ji),
                    hr_chou: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_chou_star_time_ji),
                  },
                ]
              }
            }
          />
        }
      </Card>
        <Card title='Hour Forecast' loading={today_ts?.loading} desktopDisable = { true }>
          { (today_ts?.data && user?.data) && 
            <Table
              desktopDisable = { true }
              center={true}
              data={ 
                {
                  header: [
                    { name: 'title', title: '', sort: false },
                    { name: 'hr_yin', title: '03:00', sort: false },
                    { name: 'hr_mao', title: '05:00', sort: false },
                  ],
                  body:  [
                    {
                      title: "Hour",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_yin_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_yin_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_yin_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_yin_star_actual[1]],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_mao_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_mao_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_mao_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_mao_star_actual[1]],
                    },
                    {
                      title: "Star",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_stars,
                      hr_mao: today_ts?.data?.ts.hour_mao_star_stars,
                    },
                    {
                      title: "Raising Nobleman",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_yin_star_raising_nobleman],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_mao_star_raising_nobleman],
                    },
                    {
                      title: "Other Information",
                      hr_yin: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['03:00'].map(e => e.title).join(',\n'):'',
                      hr_mao: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['05:00'].map(e => e.title).join(',\n'):'',
                    },
                    {
                      title: "Yellow / Black Belt",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_yin_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_yin_star_time_tian_shen],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_mao_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_mao_star_time_tian_shen],
                    },
                    {
                      title: "",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_yin_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_yin_star_time_tian_shen_type],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_mao_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_mao_star_time_tian_shen_type],
                    },
                    {
                      title: "",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_yin_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_yin_star_time_tian_shen_luck],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_mao_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_mao_star_time_tian_shen_luck],
                    },
                    {
                      title: "NaYin",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_na_yin,
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_na_yin,
                    },
                    {
                      title: "Clash",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_chong_gan + today_ts?.data?.ts.hour_yin_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_yin_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_yin_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_yin_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_yin_star_time_chong],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_chong_gan + today_ts?.data?.ts.hour_mao_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_mao_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_mao_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_mao_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_mao_star_time_chong],
                    },
                    {
                      title: "Halting (煞)",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_yin_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_yin_star_time_sha]],
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_mao_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_mao_star_time_sha]],
                    },
                    {
                      title: "Suits",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_yi,
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_yi,
                    },
                    {
                      title: "",
                      hr_yin: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_yin_star_time_yi),
                      hr_mao: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_mao_star_time_yi),
                    },
                    {
                      title: "Avoid",
                      hr_yin: today_ts?.data?.ts.hour_yin_star_time_ji,
                      hr_mao: today_ts?.data?.ts.hour_mao_star_time_ji,
                    },
                    {
                      title: "",
                      hr_yin: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_yin_star_time_ji),
                      hr_mao: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_mao_star_time_ji),
                    },
                  ]
                }
              }
            />
          }
        </Card>
        <Card title='Hour Forecast' loading={today_ts?.loading} desktopDisable = { true }>
          { (today_ts?.data && user?.data) && 
            <Table
              desktopDisable = { true }
              center={true}
              data={ 
                {
                  header: [
                    { name: 'title', title: '', sort: false },
                    { name: 'hr_chen', title: '07:00', sort: false },
                    { name: 'hr_si', title: '09:00', sort: false }
                  ],
                  body:  [
                    {
                      title: "Hour",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_chen_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_chen_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_chen_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_chen_star_actual[1]],
                      hr_si: today_ts?.data?.ts.hour_si_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_si_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_si_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_si_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_si_star_actual[1]],
                    },
                    {
                      title: "Star",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_stars,
                      hr_si: today_ts?.data?.ts.hour_si_star_stars,
                    },
                    {
                      title: "Raising Nobleman",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_chen_star_raising_nobleman],
                      hr_si: today_ts?.data?.ts.hour_si_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_si_star_raising_nobleman],
                    },
                    {
                      title: "Other Information",
                      hr_chen: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['07:00'].map(e => e.title).join(',\n'):'',
                      hr_si: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['09:00'].map(e => e.title).join(',\n'):'',
                    },
                    {
                      title: "Yellow / Black Belt",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_chen_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_chen_star_time_tian_shen],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_si_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_si_star_time_tian_shen],
                    },
                    {
                      title: "",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_chen_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_chen_star_time_tian_shen_type],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_si_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_si_star_time_tian_shen_type],
                    },
                    {
                      title: "",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_chen_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_chen_star_time_tian_shen_luck],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_si_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_si_star_time_tian_shen_luck],
                    },
                    {
                      title: "NaYin",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_na_yin,
                      hr_si: today_ts?.data?.ts.hour_si_star_time_na_yin,
                    },
                    {
                      title: "Clash",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_chong_gan + today_ts?.data?.ts.hour_chen_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_chen_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_chen_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_chen_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_chen_star_time_chong],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_chong_gan + today_ts?.data?.ts.hour_si_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_si_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_si_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_si_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_si_star_time_chong],
                    },
                    {
                      title: "Halting (煞)",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_chen_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_chen_star_time_sha]],
                      hr_si: today_ts?.data?.ts.hour_si_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_si_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_si_star_time_sha]],
                    },
                    {
                      title: "Suits",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_yi,
                      hr_si: today_ts?.data?.ts.hour_si_star_time_yi,
                    },
                    {
                      title: "",
                      hr_chen: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_chen_star_time_yi),
                      hr_si: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_si_star_time_yi),
                    },
                    {
                      title: "Avoid",
                      hr_chen: today_ts?.data?.ts.hour_chen_star_time_ji,
                      hr_si: today_ts?.data?.ts.hour_si_star_time_ji,
                    },
                    {
                      title: "",
                      hr_chen: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_chen_star_time_ji),
                      hr_si: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_si_star_time_ji),
                    },
                  ]
                }
              }
            />
          }
        </Card>

        <Card title='Hour Forecast' loading={today_ts?.loading} desktopDisable = { true }>
        { (today_ts?.data && user?.data) && 
            <Table
              desktopDisable = { true }
              center={true}
              data={ 
                {
                  header: [
                    { name: 'title', title: '', sort: false },
                    { name: 'hr_wu', title: '11:00', sort: false },
                    { name: 'hr_wei', title: '13:00', sort: false },
                  ],
                  body:  [
                    {
                      title: "Hour",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_wu_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_wu_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_wu_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_wu_star_actual[1]],
                      hr_wei: today_ts?.data?.ts.hour_wei_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_wei_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_wei_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_wei_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_wei_star_actual[1]],
                    },
                    {
                      title: "Star",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_stars,
                      hr_wei: today_ts?.data?.ts.hour_wei_star_stars,
                    },
                    {
                      title: "Raising Nobleman",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_wu_star_raising_nobleman],
                      hr_wei: today_ts?.data?.ts.hour_wei_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_wei_star_raising_nobleman],
                    },
                    {
                      title: "Other Information",
                      hr_wu: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['11:00'].map(e => e.title).join(',\n'):'',
                      hr_wei: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['13:00'].map(e => e.title).join(',\n'):'',
                    },
                    {
                      title: "Yellow / Black Belt",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_wu_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_wu_star_time_tian_shen],
                      hr_wei: today_ts?.data?.ts.hour_wei_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_wei_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_wei_star_time_tian_shen],
                    },
                    {
                      title: "",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_wu_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_wu_star_time_tian_shen_type],
                      hr_wei: today_ts?.data?.ts.hour_wei_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_wei_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_wei_star_time_tian_shen_type],
                    },
                    {
                      title: "",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_wu_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_wu_star_time_tian_shen_luck],
                      hr_wei: today_ts?.data?.ts.hour_wei_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_wei_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_wei_star_time_tian_shen_luck],
                    },
                    {
                      title: "NaYin",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_time_na_yin,
                      hr_wei: today_ts?.data?.ts.hour_wei_star_time_na_yin,
                    },
                    {
                      title: "Clash",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_time_chong_gan + today_ts?.data?.ts.hour_wu_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_wu_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_wu_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_wu_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_wu_star_time_chong],
                      hr_wei: today_ts?.data?.ts.hour_wei_star_time_chong_gan + today_ts?.data?.ts.hour_wei_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_wei_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_wei_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_wei_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_wei_star_time_chong],
                    },
                    {
                      title: "Halting (煞)",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_wu_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_wu_star_time_sha]],
                      hr_wei: today_ts?.data?.ts.hour_wei_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_wei_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_wei_star_time_sha]],
                    },
                    {
                      title: "Suits",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_time_yi,
                      hr_wei: today_ts?.data?.ts.hour_wei_star_time_yi,
                    },
                    {
                      title: "",
                      hr_wu: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_wu_star_time_yi),
                      hr_wei: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_wei_star_time_yi),
                    },
                    {
                      title: "Avoid",
                      hr_wu: today_ts?.data?.ts.hour_wu_star_time_ji,
                      hr_wei: today_ts?.data?.ts.hour_wei_star_time_ji,
                    },
                    {
                      title: "",
                      hr_wu: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_wu_star_time_ji),
                      hr_wei: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_wei_star_time_ji),
                    },
                  ]
                }
              }
            />
          }
        </Card>
        <Card title='Hour Forecast' loading={today_ts?.loading} desktopDisable = { true }>
        { (today_ts?.data && user?.data) && 
            <Table
              desktopDisable = { true }
              center={true}
              data={ 
                {
                  header: [
                    { name: 'title', title: '', sort: false },
                    { name: 'hr_shen', title: '15:00', sort: false },
                    { name: 'hr_you', title: '17:00', sort: false },
                  ],
                  body:  [
                    {
                      title: "Hour",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_shen_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_shen_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_shen_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_shen_star_actual[1]],
                      hr_you: today_ts?.data?.ts.hour_you_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_you_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_you_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_you_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_you_star_actual[1]],
                    },
                    {
                      title: "Star",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_stars,
                      hr_you: today_ts?.data?.ts.hour_you_star_stars,
                    },
                    {
                      title: "Raising Nobleman",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_shen_star_raising_nobleman],
                      hr_you: today_ts?.data?.ts.hour_you_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_you_star_raising_nobleman],
                    },
                    {
                      title: "Other Information",
                      hr_shen: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['15:00'].map(e => e.title).join(',\n'):'',
                      hr_you: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['17:00'].map(e => e.title).join(',\n'):'',
                    },
                    {
                      title: "Yellow / Black Belt",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_shen_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_shen_star_time_tian_shen],
                      hr_you: today_ts?.data?.ts.hour_you_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_you_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_you_star_time_tian_shen],
                    },
                    {
                      title: "",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_shen_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_shen_star_time_tian_shen_type],
                      hr_you: today_ts?.data?.ts.hour_you_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_you_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_you_star_time_tian_shen_type],
                    },
                    {
                      title: "",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_shen_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_shen_star_time_tian_shen_luck],
                      hr_you: today_ts?.data?.ts.hour_you_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_you_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_you_star_time_tian_shen_luck],
                    },
                    {
                      title: "NaYin",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_time_na_yin,
                      hr_you: today_ts?.data?.ts.hour_you_star_time_na_yin,
                    },
                    {
                      title: "Clash",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_time_chong_gan + today_ts?.data?.ts.hour_shen_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_shen_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_shen_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_shen_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_shen_star_time_chong],
                      hr_you: today_ts?.data?.ts.hour_you_star_time_chong_gan + today_ts?.data?.ts.hour_you_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_you_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_you_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_you_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_you_star_time_chong],
                    },
                    {
                      title: "Halting (煞)",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_shen_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_shen_star_time_sha]],
                      hr_you: today_ts?.data?.ts.hour_you_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_you_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_you_star_time_sha]],
                    },
                    {
                      title: "Suits",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_time_yi,
                      hr_you: today_ts?.data?.ts.hour_you_star_time_yi,
                    },
                    {
                      title: "",
                      hr_shen: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_shen_star_time_yi),
                      hr_you: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_you_star_time_yi),
                    },
                    {
                      title: "Avoid",
                      hr_shen: today_ts?.data?.ts.hour_shen_star_time_ji,
                      hr_you: today_ts?.data?.ts.hour_you_star_time_ji,
                    },
                    {
                      title: "",
                      hr_shen: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_shen_star_time_ji),
                      hr_you: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_you_star_time_ji),
                    },
                  ]
                }
              }
            />
          }
        </Card>
        <Card title='Hour Forecast' loading={today_ts?.loading} desktopDisable = { true }>
        { (today_ts?.data && user?.data) && 
            <Table
              desktopDisable = { true }
              center={true}
              data={ 
                {
                  header: [
                    { name: 'title', title: '', sort: false },
                    { name: 'hr_xu', title: '19:00', sort: false },
                    { name: 'hr_hai', title: '21:00', sort: false },
                  ],
                  body:  [
                    {
                      title: "Hour",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_xu_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_xu_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_xu_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_xu_star_actual[1]],
                      hr_hai: today_ts?.data?.ts.hour_hai_star_actual + '\n'
                              + data.reference_element_english[today_ts?.data?.ts.hour_hai_star_actual[0]] + " " 
                              + data.reference_animal_english[today_ts?.data?.ts.hour_hai_star_actual[1]] + "\n"  
                              + data.reference_element_icon[today_ts?.data?.ts.hour_hai_star_actual[0]] 
                              + data.reference_animal_icon[today_ts?.data?.ts.hour_hai_star_actual[1]],
                    },
                    {
                      title: "Star",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_stars,
                      hr_hai: today_ts?.data?.ts.hour_hai_star_stars
                    },
                    {
                      title: "Raising Nobleman",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_xu_star_raising_nobleman],
                      hr_hai: today_ts?.data?.ts.hour_hai_star_raising_nobleman + '\n' + data.reference_yinyang_english[today_ts?.data?.ts.hour_hai_star_raising_nobleman]
                    },
                    {
                      title: "Other Information",
                      hr_xu: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['19:00'].map(e => e.title).join(',\n'):'',
                      hr_hai: (today_ts?.data?.prohour.length>0)?today_ts?.data?.prohour['21:00'].map(e => e.title).join(',\n'):''
                    },
                    {
                      title: "Yellow / Black Belt",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_xu_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_xu_star_time_tian_shen],
                      hr_hai: today_ts?.data?.ts.hour_hai_star_time_tian_shen + '\n' + data.reference_yellow_belt_star_english[today_ts?.data?.ts.hour_hai_star_time_tian_shen] + '\n' + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.hour_hai_star_time_tian_shen]
                    },
                    {
                      title: "",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_xu_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_xu_star_time_tian_shen_type],
                      hr_hai: today_ts?.data?.ts.hour_hai_star_time_tian_shen_type + '\n' + data.reference_yellow_belt_english[today_ts?.data?.ts.hour_hai_star_time_tian_shen_type] + '\n' + data.reference_yellow_belt_icon[today_ts?.data?.ts.hour_hai_star_time_tian_shen_type]
                    },
                    {
                      title: "",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_xu_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_xu_star_time_tian_shen_luck],
                      hr_hai: today_ts?.data?.ts.hour_hai_star_time_tian_shen_luck + '\n' + data.reference_luck_english[today_ts?.data?.ts.hour_hai_star_time_tian_shen_luck] + '\n' + data.reference_luck_icon[today_ts?.data?.ts.hour_hai_star_time_tian_shen_luck]
                    },
                    {
                      title: "NaYin",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_time_na_yin,
                      hr_hai: today_ts?.data?.ts.hour_hai_star_time_na_yin
                    },
                    {
                      title: "Clash",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_time_chong_gan + today_ts?.data?.ts.hour_xu_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_xu_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_xu_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_xu_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_xu_star_time_chong],
                      hr_hai: today_ts?.data?.ts.hour_hai_star_time_chong_gan + today_ts?.data?.ts.hour_hai_star_time_chong  + '\n' 
                      + data.reference_element_english[today_ts?.data?.ts.hour_hai_star_time_chong_gan] + " " 
                      + data.reference_animal_english[today_ts?.data?.ts.hour_hai_star_time_chong] + "\n"  
                      + data.reference_element_icon[today_ts?.data?.ts.hour_hai_star_time_chong_gan] 
                      + data.reference_animal_icon[today_ts?.data?.ts.hour_hai_star_time_chong]
                    },
                    {
                      title: "Halting (煞)",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_xu_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_xu_star_time_sha]],
                      hr_hai: today_ts?.data?.ts.hour_hai_star_time_sha + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_hai_star_time_sha].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.hour_hai_star_time_sha]]
                    },
                    {
                      title: "Suits",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_time_yi,
                      hr_hai: today_ts?.data?.ts.hour_hai_star_time_yi
                    },
                    {
                      title: "",
                      hr_xu: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_xu_star_time_yi),
                      hr_hai: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_hai_star_time_yi)
                    },
                    {
                      title: "Avoid",
                      hr_xu: today_ts?.data?.ts.hour_xu_star_time_ji,
                      hr_hai: today_ts?.data?.ts.hour_hai_star_time_ji
                    },
                    {
                      title: "",
                      hr_xu: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_xu_star_time_ji),
                      hr_hai: data.convertSuitsToEnglish(today_ts?.data?.ts.hour_hai_star_time_ji)
                    },
                  ]
                }
              }
            />
          }
        </Card>

      </Grid>
      <Grid cols="2">
        <Card title='NaYin 5 Direction' loading={today_ts?.loading}>
        { (today_ts?.data && user?.data) && 
            <Table
              data={ 
                {
                  header: [
                    { name: 'life', title: '生门', sort: false },
                    { name: 'death', title: '死门', sort: false },
                    { name: 'fiveghost', title: '五鬼', sort: false },
                    { name: 'whitetiger', title: '白虎', sort: false }
                  ],
                  body:  [
                    {
                      life: today_ts?.data?.ts.nan_yin_wu_xing_sheng_men + " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.nan_yin_wu_xing_sheng_men].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.nan_yin_wu_xing_sheng_men]],
                      death: today_ts?.data?.ts.nan_yin_wu_xing_si_men+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.nan_yin_wu_xing_si_men].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.nan_yin_wu_xing_si_men]],
                      fiveghost: today_ts?.data?.ts.nan_yin_wu_xing_wu_gui+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.nan_yin_wu_xing_wu_gui].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.nan_yin_wu_xing_wu_gui]],
                      whitetiger: today_ts?.data?.ts.nan_yin_wu_xing_bai_hu+ " " + data.reference_direction_chinese_to_index[today_ts?.data?.ts.nan_yin_wu_xing_bai_hu].toUpperCase() + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.nan_yin_wu_xing_bai_hu]]
                    },
                  ]
                }
              }
            />
          }
        </Card>
        <Card title='Solar Terms' loading={today_ts?.loading}>
        { (today_ts?.data && user?.data) && 
            <Table
              data={ 
                {
                  header: [
                    { name: 'current', title: 'Current', sort: false },
                    { name: 'next', title: 'Next', sort: false },
                  ],
                  body:  [
                    {
                      life: today_ts?.data?.ts.jie_qi_current + " " + data.reference_24_solar_terms_english[today_ts?.data?.ts.jie_qi_current],
                      death: today_ts?.data?.ts.next_jie_qi+ " " + data.reference_24_solar_terms_english[today_ts?.data?.ts.next_jie_qi],
                    },
                  ]
                }
              }
            />
          }
        </Card>
        <Card title='Lunar Position' loading={today_ts?.loading}>
          <div>{today_ts?.data?.ts.yue_xiang + data.reference_lunar_phase_icon[today_ts?.data?.ts.yue_xiang]}</div>
          <div>{data.reference_lunar_phase_english[today_ts?.data?.ts.yue_xiang]}</div>
        </Card>
        <Card title='Tai Position' loading={today_ts?.loading}>
          <div>{"日: "+today_ts?.data?.ts.day_position_tai}</div>
          <div>{"月: "+today_ts?.data?.ts.month_position_tai}</div>
        </Card>
        { (today_ts?.data && user?.data) && 
          <Card title='4 Palaces & 4 Heavenly Animal' loading={today_ts?.loading}>
            <div>{today_ts?.data?.ts.four_gong + " " + (data.reference_direction_chinese_to_index[today_ts?.data?.ts.four_gong].toUpperCase()) + " " + data.reference_direction_icon[data.reference_direction_chinese_to_index[today_ts?.data?.ts.four_gong]]}</div>
            <div>{today_ts?.data?.ts.four_shou + " " + data.reference_yellow_belt_star_english[today_ts?.data?.ts.four_shou] + " " + data.reference_yellow_belt_star_icon[today_ts?.data?.ts.four_shou]}</div>
          </Card>
        }
        <Card title='彭祖百忌' loading={today_ts?.loading}>
          <div>{today_ts?.data?.ts.peng_zu_gan}</div>
          <div>{today_ts?.data?.ts.peng_zu_zhi}</div>
        </Card>
      </Grid>
      <Feedback />
    </Animate>
  );
}
