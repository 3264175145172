/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, HoverNav, Button, useAPI } from 'components/lib';
import ClassNames from 'classnames';
import Style from './app.module.scss';
import '../layout.scss'; // globals

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([ Style.app, 'with-sidebar' ]);
  const items_menu = [
    { label: 'Dashboard', icon: 'activity', link: '/dashboard' },
    { label: 'Account', icon: 'user', link: '/account', },
    { label: 'Tong Shu', icon: 'calendar', link: '/tongshu', },
    { label: 'Help', icon: 'help-circle', link: '/help' }
  ];

  const user = useAPI('/api/user');
  if(!user.loading && user.data)
  {
    if(user.data.internal_team)
    {
      items_menu.push( { label: '3VV Listing', icon: 'award', link: '/the3vvlist' } );
      items_menu.push( { label: 'QM Hour Chart', icon: 'star', link: '/theqmhourchart' } );
      items_menu.push( { label: 'QM Day Chart', icon: 'star', link: '/theqmdaychart' } );
      items_menu.push( { label: 'QM Mth Chart', icon: 'star', link: '/theqmmonthchart' } );
      items_menu.push( { label: 'QM Year Chart', icon: 'star', link: '/theqmyearchart' } );
    }
    if(user.data.reference_code)
    {
      items_menu.push( { label: 'Referral', icon: 'gift', link: '/referraldashboard' } );
    }
  }

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={items_menu}
      />

      <main className={ css }>

        <Header title={ props.title }>
          <HoverNav icon='user' label={ context.user.name } align='right' dark>
            <Button text='Account Settings' action={ e => window.location = '/account' }/>
            <Button text='Signout' action={ context.signout }/>
          </HoverNav>
        </Header>

        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
