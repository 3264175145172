/***
*
*   SIGN UP
*   Signup form for account owners
*
**********/

import React, { useContext } from 'react';
import { Animate, AuthContext, Row, Card, PaymentForm, Link, usePlans } from 'components/lib';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

export function Signup(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let ref = params.get('ref');

  const cookies = new Cookies();
  if(ref)
  {
    cookies.set('referral_code', ref, { path: '/' });  
  }

  const assigned_reference_code = cookies.get('referral_code');

  const context = useContext(AuthContext);
  const plans = usePlans();
  const data = plans?.data?.raw;

  const plan = window.location.hash.substring(1).split("?")[0];
  const displayPlan = [];
  let defaultPlan = null;

  if (!plans.data)
    return false;

  if(!plans.loading)
  {
    let i = 0;
    data?.plans?.map((p, index) => {
      if(p.id.toLowerCase() === plan)
      {
        defaultPlan = p.id;
      }
      if(!p.hidden)
      {
        displayPlan.push(plans.data.list[i]);
      }
      i++;
    });
   } 
 
  return(
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card loading={ false } restrictWidth center>
        { (plans?.data) &&
          <PaymentForm
            data={{
              name: {
                label: 'Full Name',
                value: '',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your full name'
              },
              email: {
                label: 'Email',
                value: '',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
              reference_code: {
                type: 'hidden',
                value: assigned_reference_code,
              },
              plan: {
                label: 'Plan',
                type: 'select',
                options: displayPlan,
                default: defaultPlan,
                required: true,
              },
              token: {
                label: 'Credit Card',
                type: 'creditcard',
                required: true,
              }
            }}
            url='/api/account'
            method='POST'
            buttonText='Create Account'
            callback={ context.signin }
          />
          }
          <span>Already registered? <Link url='/signin' text='Sign In' /></span>

        </Card>
      </Row>
    </Animate>
  );
}
