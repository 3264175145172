/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useContext, useState} from 'react';
import { Card, Stat, Table, Message, Grid, Animate, Feedback, useAPI, ViewContext, usePlans } from 'components/lib';
import moment from 'moment';
import { data } from '../../lib/data_reference';
import ReactGA from 'react-ga';

import ModalVideo from 'react-modal-video'

export function Dashboard(props){

  ReactGA.initialize('UA-51078254-5');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const progress = useAPI('/api/demo/progress');
  const context = useContext(ViewContext);

  const [isTutorial1Open, setTutorial1Open] = useState(false)
  const [isTutorial2Open, setTutorial2Open] = useState(false)
  const [isTutorial3Open, setTutorial3Open] = useState(false)
  const [isTutorial4Open, setTutorial4Open] = useState(false)
  const [isTutorial5Open, setTutorial5Open] = useState(false)
  const [isTutorial6Open, setTutorial6Open] = useState(false)
  const [isTutorial7Open, setTutorial7Open] = useState(false)

  // fetch  
  const current_set_date = moment().format('YYYYMMDD');
  const today_ts = useAPI(`/api/tongshu/get/${current_set_date}`);

  const user = useAPI('/api/user');
  const plans = usePlans();

  if(!user.loading)
  {
    if((user.data != null) && (user?.data?.dm == null))
    {
      window.location = '/welcome';
    }
  }

  function copyICal(data, callback){
    var copyText = document.getElementById("ical_url_copy");
    copyText.value = window.location.protocol + '//' + window.location.hostname + (window.location.port?':' + window.location.port:'') + data.url;
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    copyText.value = '';

    context.notification.show('iCal Link for ' + data.name + ' Copied!', 'success', true);
  }

  function numberText(number)
  {
    var last_digit = (number<21)?number:number % 10;
    return (last_digit===1)?(number+"st"):((last_digit===2)?(number+"nd"):((last_digit===3)?(number+"rd"):(number+"th")));
  }

  function getMenuData()
  {
    var current_plan = null;
    plans.data.raw.plans.forEach(p => {
      if(p.id === plans.data.raw.active)
      {
        current_plan = p;
      }
    });

    console.log(user);
    if(!current_plan && user.data.account_id === 'e8157e04-2093-42ed-9bac-ea9e96a01997')
    {
      current_plan = {'name': 'Pro Plan'};
    }

    var ical_menu = [];
    if(current_plan)
    {
      switch (current_plan.name) {
        case "Pro Plan":
          ical_menu.push({name:'Personalise Dates - Professional Dates', description: 'List of important daily structure and days related to you.', url:'/download/ical/personalpro/'+user.data.ical_id});
          ical_menu.push({name:'Personalise Dates - Professional Hours', description: 'List of important hourly structure.', url:'/download/ical/personalprohour/'+user.data.ical_id});
    
        case "Simple Plan":
          ical_menu.push({name:'Personalise Dates - Simplified', description: 'List of important daily just follow activities.', url:'/download/ical/personalsim/'+user.data.ical_id});

        case "Tong Shu Plan":
          ical_menu.push({name:'Personalise Tong Shu - Icon', description: 'Summarised Tong Shu in Emoticon. Details in the event.', url:'/download/ical/tongshuicon/'+user.data.ical_id});
          ical_menu.push({name:'Personalise Tong Shu - English', description: 'Summarised Tong Shu in English. Details in the event.', url:'/download/ical/tongshueng/'+user.data.ical_id}); 
          ical_menu.push({name:'Personalise Tong Shu - Chinese', description: 'Summarised Tong Shu in Chinese. Details in the event.', url:'/download/ical/tongshuchn/'+user.data.ical_id});
          ical_menu.push({name:'Personalise Tong Shu - QiMen Direction', description: 'Major 5 QiMen directions, for Nobility, Manifest, Wealth, Health and Relationship.', url:'/download/ical/qmdirection/'+user.data.ical_id});
          break;
      
        default:
          break;
      }  
    }

    return ical_menu;
  }

  return (
    <Animate type='pop'>
      <Message
        title='Welcome to Optimise My Day!'
        text='Feel free to add the iCal to your favourite Calendar Services. Check out the tutorials on how to add into the different platform and how to use the ical.'
        type='info'
      />
      <ModalVideo channel='youtube' autoplay="1" isOpen={isTutorial1Open} videoId="R89q_gX1Bh8" onClose={() => setTutorial1Open(false)} />
      <ModalVideo channel='youtube' autoplay="1" isOpen={isTutorial2Open} videoId="qcyw7t2zUQg" onClose={() => setTutorial2Open(false)} />
      <ModalVideo channel='youtube' autoplay="1" isOpen={isTutorial3Open} videoId="V4a35CgqOKg" onClose={() => setTutorial3Open(false)} />
      <ModalVideo channel='youtube' autoplay="1" isOpen={isTutorial4Open} videoId="-Ctkvy2UnXE" onClose={() => setTutorial4Open(false)} />
      <ModalVideo channel='youtube' autoplay="1" isOpen={isTutorial5Open} videoId="CAYFwNyo5s0" onClose={() => setTutorial5Open(false)} />
      <ModalVideo channel='youtube' autoplay="1" isOpen={isTutorial6Open} videoId="e68bzipFAg0" onClose={() => setTutorial6Open(false)} />
      <ModalVideo channel='youtube' autoplay="1" isOpen={isTutorial7Open} videoId="k4DgcakfzJU" onClose={() => setTutorial7Open(false)} />
      <Grid cols='3'>
        <Stat
          loading={ today_ts?.loading }
          value={ today_ts?.data?.ts.day + " " + data.reference_month[today_ts?.data?.ts.month] + " " + today_ts?.data?.ts.year + ", " + data.reference_week_day[today_ts?.data?.ts.day_no] + " (" + today_ts?.data?.ts.qi_zheng + ")" }
          label={ data.reference_element_english[today_ts?.data?.ts.year_gan] + " " + data.reference_animal_english[today_ts?.data?.ts.year_zhi] + " Year " + numberText(today_ts?.data?.ts.lmonth) + " Month " + numberText(today_ts?.data?.ts.lday) + " Day" }
          icon='calendar'
        />
        <Stat
          loading={ today_ts?.loading }
          value={
            today_ts?.data?.ts.day_gan + "" + today_ts?.data?.ts.day_zhi + " [" + data.reference_animal_clash[today_ts?.data?.ts.day_zhi] + "] " + 
            data.reference_element_icon[today_ts?.data?.ts.day_gan] + data.reference_animal_icon[today_ts?.data?.ts.day_zhi] + " [" + data.reference_animal_icon[data.reference_animal_clash[today_ts?.data?.ts.day_zhi]] + "]" }
          label={ data.reference_element_english[today_ts?.data?.ts.day_gan] + " " + data.reference_animal_english[today_ts?.data?.ts.day_zhi] + " Clash " + data.reference_animal_english[data.reference_animal_clash[today_ts?.data?.ts.day_zhi]] }
          icon='heart'
        />
        { (today_ts?.data && user?.data) && 
          <Stat
            loading={ user?.loading }
            value={ 
              data.reference_10_god_icon[data.reference_bz_10_god[user?.data?.dm][today_ts?.data?.ts.day_gan]] +
              data.reference_fy_icon[(today_ts?.data?.current_fan_yin==="反吟")?"FanYin":""] +
              data.reference_fy_icon[(today_ts?.data?.current_fu_yin==="伏吟")?"FuYin":""] +
              (((today_ts?.data?.ts.month_breaker)?"[M💔]":"") +((today_ts?.data?.ts.year_breaker)?"[Y💔]":"")) + 
              data.reference_day_officers_icon[today_ts?.data?.ts.zhi_xing] + 
              data.reference_luck_icon[today_ts?.data?.ts.day_tian_shen_luck] + 
              data.reference_yellow_belt_star_icon[today_ts?.data?.ts.day_tian_shen] + " " +
              data.reference_28_constellations_icon[today_ts?.data?.ts.constellation_28_xiu]
            }
            label="Today Overview"
            icon='book-open'
            action={'/tongshu/'+today_ts?.data?.ts.date}
            actiontext='Details'
          />
        }
      </Grid>
      <Card title='Available Calendars' loading={progress.loading}>
      <input
        type='text'
        id='ical_url_copy'
        name='ical_url_copy'
        style={{color:'#ffffff'}}
      />
      { (user?.data && plans?.data) &&
        <Table
          data={ 
            {
              header: [
                { name: 'name', title: 'Name', sort: false },
                { name: 'description', title: 'Description', sort: false },
                { name: 'url', title: 'iCal URL', sort: false }
              ],
              body: getMenuData(),
            }
          }
          show = {['name', 'description']}
          actions={{ custom: [{ icon: 'copy', action: copyICal }] }}
        />
      }
      </Card>

      <Card name='tutorials' title='Tutorials'>
        <div>Link to "<a href='https://calendar.google.com/calendar/u/0/r/settings/addbyurl?tab=mc' target='_blank'>Add calendar From URL</a>" for Google Calendar.</div>
        <Card name='video tutorials' title='Video Tutorials'>
          <Grid cols='3'>
            <button className="btn-primary" onClick={()=> setTutorial1Open(true)}>How To Add iCal To iOS</button>
            <button className="btn-primary" onClick={()=> setTutorial2Open(true)}>How To Add iCal To Android</button>
            <button className="btn-primary" onClick={()=> setTutorial3Open(true)}>How To Add iCal To Google</button>
            <button className="btn-primary" onClick={()=> setTutorial4Open(true)}>How To Add iCal To Outlook Web</button>
            <button className="btn-primary" onClick={()=> setTutorial5Open(true)}>How to use Tong Shu?</button>
            <button className="btn-primary" onClick={()=> setTutorial6Open(true)}>How to use Simple iCal?</button>
            <button className="btn-primary" onClick={()=> setTutorial7Open(true)}>How to use Pro iCal?</button>
          </Grid>
        </Card>
        <Card name='icon tutorials' title='Icon Tutorials'>
          <Grid cols='4'>
            <Card name='10 God' title='10 God'>
              <Table
                data={ 
                  {
                    body:  [
                      { icon: '👥', value: 'F'},
                      { icon: '🔪', value: 'RW'},
                      { icon: '🍜', value: 'EG'},
                      { icon: '⛑', value: 'HO'},
                      { icon: '🎰', value: 'IW'},
                      { icon: '💵', value: 'DW'},
                      { icon: '7️⃣', value: '7K'},
                      { icon: '🎩', value: 'DO'},
                      { icon: '💎', value: 'IR'},
                      { icon: '🎓', value: 'DR'}
                    ]
                  }
                }
              />
            </Card>
            <Card name='10 God' title='10 Elements'>
              <Table
                data={ 
                  {
                    body:  [
                      { icon: '🌳', value: 'Jia Wood'},
                      { icon: '🌱', value: 'Yi Wood'},
                      { icon: '🔆', value: 'Bing Fire'},
                      { icon: '🕯', value: 'Ding Fire'},
                      { icon: '⛰', value: 'Wu Earth'},
                      { icon: '🏜', value: 'Ji Earth'},
                      { icon: '🪓', value: 'Geng Metal'},
                      { icon: '💍', value: 'Xin Metal'},
                      { icon: '🌊', value: 'Ren Water'},
                      { icon: '💧', value: 'Gui Water'},
                    ]
                  }
                }
              />
            </Card>
            <Card name='Day Officers' title='Day Officers'>
              <Table
                data={ 
                  {
                    body:  [
                      { icon: '🏗', value: 'Establish'},
                      { icon: '🧹', value: 'Remove'},
                      { icon: '🈵', value: 'Full'},
                      { icon: '⚖️', value: 'Balance'},
                      { icon: '🧘‍♀️', value: 'Stable'},
                      { icon: '🚀', value: 'Initiate'},
                      { icon: '🧨', value: 'Destruction'},
                      { icon: '🚨', value: 'Danger'},
                      { icon: '🎉', value: 'Sucess'},
                      { icon: '📥', value: 'Receive'},
                      { icon: '⭕️', value: 'Open'},
                      { icon: '🚫', value: 'Close'}
                    ]
                  }
                }
              />
            </Card>
            <Card name='10 God' title='12 Animals'>
              <Table
                data={ 
                  {
                    body:  [
                      { icon: '🐁', value: 'Rat'},
                      { icon: '🐂', value: 'Ox'},
                      { icon: '🐅', value: 'Tiger'},
                      { icon: '🐇', value: 'Rabbit'},
                      { icon: '🐉', value: 'Dragon'},
                      { icon: '🐍', value: 'Snake'},
                      { icon: '🐎', value: 'Horse'},
                      { icon: '🐑', value: 'Sheep'},
                      { icon: '🐒', value: 'Monkey'},
                      { icon: '🐓', value: 'Rooster'},
                      { icon: '🐕', value: 'Dog'},
                      { icon: '🐖', value: 'Pig'},
                    ]
                  }
                }
              />
            </Card>
            <Card name='10 God' title='Direction'>
              <Table
                data={ 
                  {
                    body:  [
                      { icon: '⬆️', value: 'North'},
                      { icon: '↗️', value: 'NorthEast'},
                      { icon: '➡️', value: 'East'},
                      { icon: '↘️', value: 'SouthEast'},
                      { icon: '⬇️', value: 'South'},
                      { icon: '↙️', value: 'SouthWest'},
                      { icon: '⬅️', value: 'West'},
                      { icon: '↖️', value: 'NorthWest'},
                      { icon: '⚫️', value: 'Center'},
                    ]
                  }
                }
              />
            </Card>
            <Card name='10 God' title='Misc'>
              <Table
                data={ 
                  {
                    body:  [
                      { icon: '🔂', value: 'Fu Yin'},
                      { icon: '🔀', value: 'Fan Yin'},
                      { icon: '🈳️', value: 'DE'},
                      { icon: '❌', value: 'Inauspicious'},
                      { icon: '✅', value: 'Auspicious'},
                      { icon: '⬛️', value: 'Black Belt'},
                      { icon: '🟨', value: 'Yellow Belt'},
                    ]
                  }
                }
              />
            </Card>
            <Card name='10 God' title='Black/Yellow Belt'>
              <Table
                data={ 
                  {
                    body:  [
                      { icon: '👨‍⚖️', value: 'Life Governor'},
                      { icon: '勾', value: 'Grappling Hook'},
                      { icon: '🐲', value: 'Green Dragon'},
                      { icon: '🌟', value: 'Bright Hall'},
                      { icon: '雀', value: 'Red Phoenix'},
                      { icon: '🔒', value: 'Golden Lock'},
                      { icon: '德', value: 'Heavenly Moral'},
                      { icon: '🐯', value: 'White Tiger'},
                      { icon: '玉', value: 'Jade Hall'},
                      { icon: '牢', value: 'Heavenly Jail'},
                      { icon: '🐢', value: 'Black Tortoise'},
                      { icon: '刑', value: 'Heavenly Punishment'},
                    ]
                  }
                }
              />
            </Card>
            <Card></Card>
            <Card name='10 God' title='28 Constellations'>
              <Table
                data={ 
                  {
                    body:  [
                      { icon: '🦄', value: 'Horn'},
                      { icon: '亢', value: 'Neck'},
                      { icon: '🥕', value: 'Root'},
                      { icon: '🏡', value: 'House'},
                      { icon: '❤', value: 'Heart'},
                      { icon: '尾', value: 'Tail'},
                      { icon: '🧺', value: 'Basket'},
                      { icon: '斗', value: 'Dipper'},
                      { icon: '🐮', value: 'Ox'},
                      { icon: '👩', value: 'Girl'},
                      { icon: '虚', value: 'Emptiness'},
                      { icon: '⚠️', value: 'Danger'},
                      { icon: '⛺️', value: 'Camp'},
                      { icon: '壁', value: 'Wall'},
                      { icon: '🦵', value: 'Legs'},
                      { icon: '娄', value: 'Bond'},
                      { icon: '胃', value: 'Stomach'},
                      { icon: '✨', value: 'Pleiades'},
                      { icon: '毕', value: 'Net'},
                      { icon: '觜', value: 'Beak'},
                      { icon: '参', value: 'Reference'},
                      { icon: '井', value: 'Well'},
                      { icon: '👻', value: 'Ghost'},
                      { icon: '柳', value: 'Willow'},
                      { icon: '⭐️', value: 'Star'},
                      { icon: '张', value: 'Extend'},
                      { icon: '🕊', value: 'Wings'},
                      { icon: '轸', value: 'Chariot'},
                    ]
                  }
                }
              />
            </Card>
            <Card name='10 God' title='28 Constellations Animals'>
              <Table
                data={ 
                  {
                    body:  [
                      { icon: '蛟', value: 'Water Dragon'},
                      { icon: '🐉', value: 'Dragon'},
                      { icon: '🦝', value: 'Raccoon'},
                      { icon: '🐇', value: 'Rabbit'},
                      { icon: '🦊', value: 'Fox'},
                      { icon: '🐅', value: 'Tiger'},
                      { icon: '🐆', value: 'Leopard'},
                      { icon: '犴', value: 'Moose'},
                      { icon: '🐑', value: 'Sheep'},
                      { icon: '獐', value: 'Roe'},
                      { icon: '🐎', value: 'Horse'},
                      { icon: '🦌', value: 'Deer'},
                      { icon: '🐍', value: 'Snake'},
                      { icon: '🐛', value: 'Worm'},
                      { icon: '🐺', value: 'Wolf'},
                      { icon: '🦮', value: 'Dog'},
                      { icon: '彘', value: 'Pheasant'},
                      { icon: '🐓', value: 'Chicken'},
                      { icon: '🦜', value: 'Bird'},
                      { icon: '🐒', value: 'Monkey'},
                      { icon: '🦧', value: 'Ape'},
                      { icon: '🦄', value: 'Unicorn'},
                      { icon: '🐂', value: 'Ox'},
                      { icon: '🦇', value: 'Bat'},
                      { icon: '🐀', value: 'Rat'},
                      { icon: '燕', value: 'Swallow'},
                      { icon: '🐖', value: 'Pig'},
                      { icon: '🦦', value: 'Otter'},
                    ]
                  }
                }
              />
            </Card>
          </Grid>
        </Card>
      </Card>

      <Feedback />

    </Animate>
  );
}
